<template>
  <span v-if="action">
    <VRecognitionAction
      v-bind:image="image"
      v-bind:task_id="action.task"
      v-bind:detection="detection"
    />
    <span
      v-bind:key="'switch-' + key"
      v-for="(branch_action, key, index) in this.action.cases"
    >
      <span v-if="isActiveBranch(branch_action)">
        <VFlowAction
          v-bind:action="getActiveBranch(branch_action)"
          v-bind:image="image"
          v-bind:detection="detection"
        />
      </span>
    </span>
    <span
      v-bind:key="'staled-switch-' + key"
      v-for="(branch_action, key, index) in this.stale_branches"
    >
      <span v-show="false">
        <VFlowAction
          v-bind:action="getActiveBranch(branch_action)"
          v-bind:image="image"
          v-bind:detection="detection"
        />
      </span>
    </span>
  </span>
</template>

<script>
// eslint-disable-next-line vue/no-unused-vars
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";
import VRecognitionAction from "@/components/image/flows/VRecognitionAction.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VSwitchAction",
  components: {
    VRecognitionAction,
    VFlowAction: () => import("@/components/image/flows/VFlowAction.vue")
  },
  props: {
    image: Object,
    detection: Object,
    action: Object
  },
  data() {
    return {
      active_branches: {},
      predict_branch: [],
      stale_branches: {}
    };
  },
  computed: {
    ...mapState(["tasks"]),
    entity_id: function() {
      if (this.detection) {
        return this.detection.id;
      }
      return this.image.id;
    }
  },
  filters: {},
  updated() {},
  methods: {
    activateBranch(label) {
      if (label in this.stale_branches) {
        delete this.stale_branches[label];
      }
      this.$forceUpdate();

      this.$nextTick(() => {
        this.active_branches[label] = true;
        this.$forceUpdate();
      });
    },
    deactivateBranch(label) {
      this.active_branches[label] = false;
      this.$forceUpdate();

      // call remove on all branch we are deactivating
      for (let branch of this.action.cases) {
        if (label in branch) {
          this.active_branches[label] = false;
          if (label in this.stale_branches) {
            console.log("already included");
          } else {
            this.stale_branches[label] = branch;
            this.$forceUpdate();
          }
          this.$nextTick(() => {
            this.$eventBus.$emit("call-remove-action-" + branch[label].id);
          });
        }
      }

      this.$forceUpdate();
    },
    getActiveBranch(branch) {
      let item = _.get(_.keys(branch), 0);
      return branch[item];
    },
    isActiveBranch(branch) {
      let item = _.get(_.keys(branch), 0);
      if (item in this.active_branches) {
        return this.active_branches[item];
      }
      return false;
    },
    predict() {
      this.$eventBus.$emit("switch-call-recognition-task-" + this.action.task);
    },
    remove() {
      this.$eventBus.$emit("switch-clear-recognition-task-" + this.action.task);
    },
    getTaskById(id) {
      let task = this.tasks.find(element => element.id == id);
      return task.name;
    }
  },
  mounted: function() {},
  created() {
    for (let index in this.action.cases) {
      this.active_branches[_.get(_.keys(this.action.cases[index]), 0)] = false;
    }

    // when this action is called we are going to call predict action on switch recognition task
    this.$eventBus.$on("call-remove-action-" + this.action.id, this.remove);

    // activate branch if added label (not after predict)
    this.$eventBus.$on(
      "switch-add-label-image-task-" + this.entity_id + "-" + this.action.task,
      this.activateBranch
    );

    // if some branch was deactivated then remove all sub labels
    this.$eventBus.$on(
      "switch-remove-label-image-task-" +
        this.entity_id +
        "-" +
        this.action.task,
      this.deactivateBranch
    );
  },
  beforeDestroy() {
    this.$eventBus.$off("call-remove-action-" + this.action.id);

    this.$eventBus.$off(
      "switch-add-label-image-task-" + this.entity_id + "-" + this.action.task
    );
    this.$eventBus.$off(
      "switch-remove-label-image-task-" +
        this.entity_id +
        "-" +
        this.action.task
    );
  }
};
</script>

<style></style>
