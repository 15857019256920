import { render, staticRenderFns } from "./VSmallCard.vue?vue&type=template&id=7aa0bb20&scoped=true&"
import script from "./VSmallCard.vue?vue&type=script&lang=js&"
export * from "./VSmallCard.vue?vue&type=script&lang=js&"
import style0 from "./VSmallCard.vue?vue&type=style&index=0&id=7aa0bb20&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7aa0bb20",
  null
  
)

export default component.exports