<template>
  <b-card footer-bg-variant="white">
    <b-card-title>
      <b-row>
        <b-col>{{ title }}</b-col>
        <b-col v-bind:style="{ color: color }">
          <span class="float-right">
            <i :class="icon"></i>
          </span>
        </b-col>
      </b-row>
    </b-card-title>
    <b-card-sub-title>{{ sub_title }}</b-card-sub-title>

    <b-card-text></b-card-text>
    <div>
      <b-row>
        <b-col>
          <router-link v-if="link" class="router-nocolor" :to="link">
            <b-button variant="outline-primary">Create</b-button>
          </router-link>
          <a v-if="link_app" href="https://app.ximilar.com" target="_blank">
            <b-button variant="outline-success">Go To APP</b-button>
          </a>
        </b-col>
      </b-row>
    </div>
    <em slot="footer" v-if="footer">
      <b-list-group class="d-flex justify-content-around" horizontal>
        <b-list-group-item
          class="border-0 m-0 icon-hover"
          v-b-tooltip.hover
          :title="text_1"
        >
          <i class="fal fa-cube fa-lg"></i>
          {{ number_1 }}
        </b-list-group-item>
        <b-list-group-item
          class="border-0 icon-hover"
          v-b-tooltip.hover
          :title="text_2"
        >
          <i class="fal fa-tag fa-lg"></i>
          {{ number_2 }}
        </b-list-group-item>
        <b-list-group-item
          class="border-0 icon-hover"
          v-b-tooltip.hover
          :title="text_3"
        >
          <i class="fal fa-tasks-alt fa-lg"></i>
          {{ number_3 }}
        </b-list-group-item>
      </b-list-group>
    </em>
  </b-card>
</template>

<script>
export default {
  name: "VSmallCard",
  components: {},
  props: {
    title: String,
    sub_title: String,
    color: String,
    number_1: String,
    number_2: String,
    number_3: String,
    text_1: String,
    text_2: String,
    text_3: String,
    icon: String,
    link: String,
    link_app: String,
    footer: Boolean
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
.card-footer {
  padding: 0rem 0.5rem !important;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.icon-hover:hover {
  color: #007bff;
}
</style>
