/* eslint-disable no-console */

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import { DataLoadedEnum, ResourceEnum } from "./constants";

let API_URL = process.env.VUE_APP_API_URL;
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    resources: {
      [ResourceEnum.RECOGNITION]: null,
      [ResourceEnum.DETECTION]: null,
      [ResourceEnum.SEGMENTATION]: null,
      [ResourceEnum.ANNOTATE]: null,
      [ResourceEnum.FLOWS]: null,
      [ResourceEnum.SIMILARITY]: null,
      [ResourceEnum.PRODUCT]: null
    },
    workspace: localStorage.getItem("workspace") || "",
    default_workspace: localStorage.getItem("default_workspace") || "",
    data_loaded: DataLoadedEnum.NOT_LOADED,
    access: localStorage.getItem("access") || "",
    refresh: localStorage.getItem("refresh") || "",
    user_id: localStorage.getItem("user_id") || "",
    user: JSON.parse(localStorage.getItem("xuser")) || {},
    api_token: "",
    last_action: localStorage.getItem("last_action") || "ITEMS", // last used action in image toolbar
    last_image: null, // for autload objects job
    last_job: null, // for autload objects job
    last_detection_action:
      localStorage.getItem("last_detection_action") || "JOBS",
    last_image_flow: localStorage.getItem("last_image_flow") || "", // last used flow in image (no job)
    last_detection_flow: localStorage.getItem("last_detection_flow") || "",
    last_detection_label: localStorage.getItem("last_detection_label") || "",
    last_detection_task: localStorage.getItem("last_detection_task") || "",
    workspaces: [],
    hide_canvas_items: false,
    tasks: [], // recognition task
    tasks_d: {},
    labels: [],
    detection_labels: [],
    detection_tasks: [],
    segmentation_labels: [],
    segmentation_tasks: [],
    similarity_types: [],
    similarity_tasks: [],
    product_collections: [],
    flows: [],
    flows_json: [],
    job_item_queue: [], // previous items
    top_task: null,
    sidebar_collapsed: true
  },
  mutations: {
    TOGGLE_SIDEBAR(state) {
      state.sidebar_collapsed = !state.sidebar_collapsed;
    },
    TOGGLE_HIDE_CANVAS_ITEMS(state) {
      state.hide_canvas_items = !state.hide_canvas_items;
    },
    DATA_LOADED(state, new_state) {
      state.data_loaded = new_state;
    },
    SET_WORKSPACE(state, workspace) {
      state.workspace = workspace;
    },
    SET_DEF_WORKSPACE(state, workspace) {
      state.default_workspace = workspace;
    },
    SET_LAST_ACTION(state, action) {
      state.last_action = action;
    },
    SET_LAST_IMAGE(state, image_id) {
      state.last_image = image_id;
    },
    SET_LAST_JOB(state, job_id) {
      state.last_job = job_id;
    },
    SET_LAST_DETECTION_ACTION(state, action) {
      state.last_detection_action = action;
    },
    SET_LAST_IMAGE_FLOW(state, flow) {
      state.last_image_flow = flow;
    },
    SET_LAST_DETECTION_FLOW(state, flow) {
      state.last_detection_flow = flow;
    },
    SET_LAST_DETECTION_LABEL(state, label) {
      state.last_detection_label = label;
    },
    SET_LAST_DETECTION_TASK(state, task) {
      state.last_detection_task = task;
    },
    SET_RESOURCE(state, resource) {
      state.resources[resource.key] = resource.value;
    },
    SET_API_TOKEN(state, api_token) {
      state.api_token = api_token;
    },
    CLEAN_WORKSPACE(state) {
      state.workspaces = [];
      state.tasks = [];
      state.labels = [];
      state.detection_labels = [];
      state.detection_tasks = [];
      state.similarity_types = [];
      state.similarity_tasks = [];
      state.product_collections = [];
      state.segmentation_labels = [];
      state.segmentation_tasks = [];
      state.flows = [];
      state.flows_json = [];
      state.job_item_queue = [];
      state.top_task = null;

      state.last_detection_label = null;
      state.last_detection_task = null;
      state.last_detection_flow = null;
      state.last_image_flow = null;
      state.last_image = null;
      state.last_job = null;

      state.last_action = "ITEMS";
      state.last_detection_action = "JOBS";

      for (let resource in state.resources) {
        state.resources[resource] = null;
      }
    },
    LOGOUT(state) {
      state.status = "";
      state.access = "";
      state.user_id = "";
      state.workspace = "";
      state.default_workspace = "";
      state.user = {};
    },
    AUTH_SUCCESS(state, data) {
      state.status = "success";
      state.access = data.access;
      state.refresh = data.refresh;
    },
    USER_DETAILS(state, data) {
      state.user = data.user_details;
      state.user_id = data.user_details.id;
    },
    AUTH_ERROR(state) {
      state.status = "error";
      state.access = "";
      state.refresh = "";
    },
    ADD_WORKSPACE(state, workspace) {
      state.workspaces.push(workspace);
    },
    ADD_TASK(state, task) {
      state.tasks.push(task);
      state.tasks_d[task.id] = task;
    },
    CLEAR_JOB_ITEMS(state) {
      state.job_item_queue = [];
    },
    ADD_JOB_ITEM(state, id) {
      // for getting back to previous job item
      if (!state.job_item_queue.includes(id)) {
        // keep only ten items
        if (state.job_item_queue.length == 15) {
          state.job_item_queue.shift();
        }
        state.job_item_queue.push(id);
      }
    },
    ADD_FLOW(state, flow) {
      state.flows.push(flow);
    },
    ADD_LABEL(state, label) {
      state.labels.push(label);
    },
    ADD_SEGMENTATION_LABEL(state, label) {
      state.segmentation_labels.push(label);
    },
    ADD_SIMILARITY_TYPE(state, type) {
      state.similarity_types.push(type);
    },
    ADD_SIMILARITY_TASK(state, task) {
      state.similarity_tasks.push(task);
    },
    ADD_PRODUCT_COLLECTION(state, collection) {
      state.product_collections.push(collection);
    },
    ADD_SEGMENTATION_TASK(state, task) {
      state.segmentation_tasks.push(task);
    },
    ADD_DETECTION_LABEL(state, label) {
      state.detection_labels.push(label);
    },
    ADD_DETECTION_TASK(state, task) {
      state.detection_tasks.push(task);
    },
    CLEAR_DETECTION_TASKS(state) {
      state.detection_tasks = [];
    },
    CLEAR_DETECTION_LABELS(state) {
      state.detection_labels = [];
    },
    CLEAR_SEGMENTATION_TASKS(state) {
      state.segmentation_tasks = [];
    },
    CLEAR_SEGMENTATION_LABELS(state) {
      state.segmentation_labels = [];
    },
    CLEAR_SIMILARITY_TYPES(state) {
      state.similarity_types = [];
    },
    CLEAR_SIMILARITY_TASKS(state) {
      state.similarity_tasks = [];
    },
    CLEAR_PRODUCT_COLLECTIONS(state) {
      state.product_collections = [];
    },
    SET_TASKS(state, { detection_label, tasks }) {
      // load tasks from store which have already labels
      let to_add = [];
      for (let j = 0; j < tasks.length; j++) {
        for (let k = 0; k < state.tasks.length; k++) {
          if (tasks[j].id == state.tasks[k].id) {
            to_add.push(state.tasks[k]);
          }
        }
      }

      let flabel = state.detection_labels.find(function(element) {
        return element.id == detection_label.id;
      });
      flabel.recognition_tasks = to_add;
    },
    SET_LABELS(state, { task, labels }) {
      let ftask = state.tasks.find(function(element) {
        return element.id == task.id;
      });
      ftask.labels = labels;
    },
    SET_FLOW_JSON(state, { flow_id, json_data }) {
      let flow = state.flows.find(function(element) {
        return element.id == flow_id;
      });
      flow["json_data"] = json_data;
    },
    ADD_LABEL_TO_TASK(state, { task, label }) {
      let i = state.tasks.indexOf(task);
      state.tasks[i].labels.push(label);
    },
    UPDATE_DETECTION_LABEL(state, label) {
      let index = state.detection_labels.findIndex(function(element) {
        return element.id == label.id;
      });
      state.detection_labels[index]["name"] = label.name;
      state.detection_labels[index]["color"] = label.color;
    },
    UPDATE_SIMILARITY_TYPE(state, label) {
      let index = state.similarity_types.findIndex(function(element) {
        return element.id == label.id;
      });
      state.similarity_types[index]["name"] = label.name;
    },
    UPDATE_SIMILARITY_TASK(state, task) {
      let index = state.similarity_tasks.findIndex(function(element) {
        return element.id == task.id;
      });
      state.similarity_tasks[index]["name"] = task.name;
    },
    UPDATE_DETECTION_TASK(state, task) {
      let index = state.detection_tasks.findIndex(function(element) {
        return element.id == task.id;
      });
      state.detection_tasks[index]["name"] = task.name;
    },
    UPDATE_SEGMENTATION_LABEL(state, label) {
      let index = state.segmentation_labels.findIndex(function(element) {
        return element.id == label.id;
      });
      state.segmentation_labels[index]["name"] = label.name;
      state.segmentation_labels[index]["color"] = label.color;
    },
    UPDATE_SEGMENTATION_TASK(state, task) {
      let index = state.segmentation_tasks.findIndex(function(element) {
        return element.id == task.id;
      });
      state.segmentation_tasks[index]["name"] = task.name;
    },
    REMOVE_LABEL_FROM_TASK(state, { task, label }) {
      let i = state.tasks.indexOf(task);
      let k = state.tasks[i].labels.indexOf(label);
      state.tasks[i].labels.splice(k, 1);
    },
    ADD_TASK_TO_DETECTION_LABEL(state, { task, label }) {
      let k = state.detection_labels.indexOf(label);
      state.detection_labels[k].recognition_tasks.push(task);
    },
    REMOVE_TASK_FROM_DETECTION_LABEL(state, { task, label }) {
      let k = state.detection_labels.indexOf(label);
      let i = state.detection_labels[k].recognition_tasks.indexOf(task);
      state.detection_labels[k].recognition_tasks.splice(i, 1);
    },
    ADD_LABEL_TO_DETECTION_TASK(state, { task, label }) {
      let k = state.detection_tasks.indexOf(task);
      state.detection_tasks[k].detection_labels.push(label);
    },
    REMOVE_LABEL_FROM_DETECTION_TASK(state, { task, label }) {
      let k = state.detection_tasks.indexOf(task);
      let i = state.detection_tasks[k].detection_labels.indexOf(label);
      state.detection_tasks[k].detection_labels.splice(i, 1);
    },
    ADD_LABEL_TO_SEGMENTATION_TASK(state, { task, label }) {
      let k = state.segmentation_tasks.indexOf(task);
      state.segmentation_tasks[k].segmentation_labels.push(label);
    },
    REMOVE_LABEL_FROM_SEGMENTATION_TASK(state, { task, label }) {
      let k = state.segmentation_tasks.indexOf(task);
      let i = state.segmentation_tasks[k].segmentation_labels.indexOf(label);
      state.segmentation_tasks[k].segmentation_labels.splice(i, 1);
    },
    ADD_LABEL_TO_SIMILARITY_TASK(state, { task, label }) {
      let k = state.similarity_tasks.findIndex(function(element) {
        return element.id == task.id;
      });
      let i = state.similarity_types.findIndex(function(element) {
        return element.id == label.id;
      });
      state.similarity_tasks[k].types.push(label);
    },
    REMOVE_LABEL_FROM_SIMILARITY_TASK(state, { task, label }) {
      let k = state.similarity_tasks.findIndex(function(element) {
        return element.id == task.id;
      });
      let i = state.similarity_tasks[k].types.findIndex(function(element) {
        return element.id == label.id;
      });
      state.similarity_tasks[k].types.splice(i, 1);
    }
  },
  getters: {
    isLoggedIn: state => !!state.access && !!state.refresh,
    authStatus: state => state.status,
    getLastImageFlow: state => state.last_image_flow,
    getLastDetectionFlow: state => state.last_detection_flow,
    getLastDetectionLabel: state => state.last_detection_label,
    getLastDetectionTask: state => state.last_detection_task,
    getLastAction: state => state.last_action,
    getLastImage: state => state.last_image,
    getLastJob: state => state.last_job,
    getLastDetectionAction: state => state.last_detection_action,
    getTasks: state => state.tasks,
    isItemInJob: state => id => {
      let index = state.job_item_queue.findIndex(image => image == id);
      if (index == -1) {
        return false;
      }
      return true;
    },
    getNextJobItem: state => id => {
      let index = state.job_item_queue.findIndex(image => image == id);
      if (index >= 0) {
        return state.job_item_queue[index + 1];
      }
      return null;
    },
    getPreviousJobItem: state => id => {
      let index = state.job_item_queue.findIndex(image => image == id);
      if (index > 0) {
        return state.job_item_queue[index - 1];
      } else if (index == 0) {
        return null;
      } else {
        return state.job_item_queue[state.job_item_queue.length - 1];
      }
    },
    getTaskById: state => id => {
      return state.tasks_d[id];
    },
    getLabelById: state => id => {
      return state.labels.find(element => element.id === id);
    },
    canDownloadModel: state => {
      if ("can_download_model" in state.user) {
        return state.user.can_download_model;
      }
      return false;
    },
    getLabels: state => state.labels,
    getDetectionLabels: state => state.detection_labels,
    getDetectionTasks: state => state.detection_tasks,
    getWorkspaces: state => state.workspaces,
    getActiveWorkspace: state => {
      if (state.workspace) {
        return state.workspaces.find(
          workspace => workspace.id === state.workspace
        );
      } else {
        // use default workspace
        return state.workspaces.find(
          workspace => workspace.id === state.default_workspace
        );
      }
    },
    checkResource: state => resource_name => {
      return state.resources[resource_name];
    },
    getActiveWorkspaceFlow: state => {
      let workspace = state.workspaces.find(
        workspace => workspace.id === state.workspace
      );
      if (workspace) {
        if (
          "meta_data" in workspace &&
          workspace.meta_data &&
          "flow" in workspace.meta_data &&
          workspace.meta_data["flow"]
        ) {
          return workspace.meta_data["flow"];
        }
      }
      return false;
    },
    isActiveWorkspaceEvaluation: state => {
      let workspace = state.workspaces.find(
        workspace => workspace.id === state.workspace
      );
      if (
        "meta_data" in workspace &&
        workspace.meta_data &&
        "annotate" in workspace.meta_data &&
        workspace.meta_data["evaluation"] == true
      ) {
        return true;
      }
      return false;
    }
  },
  actions: {
    login({ commit }, user) {
      /*
       * Login to the Ximilar APP and store JWT to cookie, axios and vuex storage.
       * https://www.pusher.com/tutorials/authentication-vue-vuex/
       */
      return new Promise((resolve, reject) => {
        console.log("Log in to " + API_URL + "/account/v2/login/");
        axios({
          url: API_URL + "/account/v2/login/",
          timeout: 25000,
          data: {
            username: user.email,
            email: user.email,
            password: user.password
          },
          method: "POST"
        })
          .then(resp => {
            localStorage.setItem("access", resp.data.access);
            localStorage.setItem("refresh", resp.data.refresh);

            axios.defaults.headers.common["Authorization"] =
              "Bearer " + resp.data.access;
            commit("AUTH_SUCCESS", {
              access: resp.data.access,
              refresh: resp.data.refresh
            });
            resolve(resp);
          })
          .catch(error => {
            console.log(error);
            localStorage.removeItem("access");
            localStorage.removeItem("refresh");
            reject(error);
          });
      });
    },
    async logout(context) {
      /*
       * Logout from Ximilar APP by removing token/user from system.
       */
      context.commit("DATA_LOADED", DataLoadedEnum.NOT_LOADED);

      let result = await axios
        .get(API_URL + "/account/v2/logout")
        .then(response => console.log(response))
        .catch(error => console.log(error));

      context.commit("CLEAN_WORKSPACE");
      context.commit("LOGOUT");

      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("workspace");
      localStorage.removeItem("default_workspace");
      localStorage.removeItem("user_id");
      localStorage.removeItem("xuser");
      delete axios.defaults.headers.common["Authorization"];
    },
    async get_user_details(context) {
      return new Promise((resolve, reject) => {
        axios
          .get(API_URL + "/account/v2/details/")
          .then(response => {
            localStorage.setItem("user_id", response.data.id);
            localStorage.setItem(
              "default_workspace",
              response.data.default_workspace
            );
            localStorage.setItem("xuser", JSON.stringify(response.data));

            context.commit("USER_DETAILS", { user_details: response.data });
            context.commit(
              "SET_DEF_WORKSPACE",
              response.data.default_workspace
            );
            resolve(response);
          })
          .catch(error => {
            // probably error with authentication;
            console.log(error);
            context.dispatch("logout");
            reject(error);
          });
      });
    },
    async get_api_token(context) {
      return new Promise((resolve, reject) => {
        axios.get(API_URL + "/account/v2/token/").then(
          response => {
            context.commit("SET_API_TOKEN", response.data["token"]);
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    toggleSidebar({ commit }) {
      commit("TOGGLE_SIDEBAR");
    },
    toggleHideCanvasItems({ commit }) {
      commit("TOGGLE_HIDE_CANVAS_ITEMS");
    },
    async init_load_data(context) {
      /**
       * Initial loading of data when app started.
       */
      if (context.state.data_loaded != DataLoadedEnum.NOT_LOADED) {
        return;
      }

      context.commit("CLEAN_WORKSPACE");
      context.commit("DATA_LOADED", DataLoadedEnum.LOADING);

      await context.dispatch("get_user_details");

      // Load all the necessary data
      await context.dispatch("get_api_token");
      await context.dispatch("get_data_page", {
        url: API_URL + "/account/v2/workspace/",
        page: "",
        param: "",
        commit: "ADD_WORKSPACE",
        callback: null
      });

      // Check the access rights (resources) to services
      await context.dispatch("check_resource", {});

      await context.dispatch("get_data_page", {
        url: API_URL + "/recognition/v2/label/",
        page: "",
        param: "&page_size=100",
        commit: "ADD_LABEL",
        callback: null
      });
      await context.dispatch("get_data_page", {
        url: API_URL + "/recognition/v2/task/",
        page: "",
        param: "",
        commit: "ADD_TASK"
      });
      await context.dispatch("get_task_labels");

      if (context.getters.checkResource("custom-object-detection")) {
        await context.dispatch("get_data_page", {
          url: API_URL + "/detection/v2/label/",
          page: "",
          param: "",
          commit: "ADD_DETECTION_LABEL"
        });
        await context.dispatch("get_detection_label_tasks");
        await context.dispatch("get_data_page", {
          url: API_URL + "/detection/v2/task/",
          page: "",
          param: "",
          commit: "ADD_DETECTION_TASK",
          callback: null
        });
      }

      if (context.getters.checkResource("custom-segmentation")) {
        await context.dispatch("get_data_page", {
          url: API_URL + "/segmentation/v2/label/",
          page: "",
          param: "",
          commit: "ADD_SEGMENTATION_LABEL"
        });
        await context.dispatch("get_data_page", {
          url: API_URL + "/segmentation/v2/task/",
          page: "",
          param: "",
          commit: "ADD_SEGMENTATION_TASK",
          callback: null
        });
      }

      if (context.getters.checkResource("flows")) {
        await context.dispatch("get_data_page", {
          url: API_URL + "/flows/v2/flow/",
          page: "",
          param: "",
          commit: "ADD_FLOW"
        });

        await context.dispatch("get_flow_json", {
          id: context.getters.getActiveWorkspaceFlow
        });
      }

      if (context.getters.checkResource("custom-similarity")) {
        await context.dispatch("get_data_page", {
          url: API_URL + "/similarity/training/v2/type/",
          page: "",
          param: "",
          commit: "ADD_SIMILARITY_TYPE"
        });

        await context.dispatch("get_data_page", {
          url: API_URL + "/similarity/training/v2/task/",
          page: "",
          param: "",
          commit: "ADD_SIMILARITY_TASK"
        });
      }

      if (context.getters.checkResource("products-management")) {
        await context.dispatch("get_data_page", {
          url: API_URL + "/product/v2/collection/",
          page: "",
          param: "",
          commit: "ADD_PRODUCT_COLLECTION"
        });
      }

      context.commit("DATA_LOADED", DataLoadedEnum.LOADED);
      console.log("loading data finished");
    },
    async check_resource(context, { service_name }) {
      return new Promise((resolve, reject) => {
        let ws = context.getters.getActiveWorkspace;
        axios.get(API_URL + "/account/v2/resource?workspace=" + ws.id).then(
          response => {
            if ("results" in response.data) {
              for (let index in response.data["results"]) {
                let service = response.data["results"][index];
                context.commit("SET_RESOURCE", {
                  key: service["service"]["slug"],
                  value: true
                });
              }
            }
            resolve(true);
          },
          error => {
            resolve(false);
          }
        );
      });
    },
    async get_data_page(context, { url, page, param, commit, callback }) {
      console.log(
        "Request to: " +
          url +
          "?workspace=" +
          context.state.workspace +
          param +
          page
      );

      let response = await axios.get(
        url + "?workspace=" + context.state.workspace + param + page
      );

      let items = [];
      if (response.data.hasOwnProperty("results")) {
        items = response.data.results;
      } else {
        // endpoint returns not paginated items
        items = response.data;
      }

      for (let i = 0; i < items.length; i++) {
        context.commit(commit, items[i]);
      }
      if (response.data.next) {
        let url_params = new URLSearchParams(response.data.next.split("?")[1]);
        let next_page = url_params.get("page");

        await context.dispatch("get_data_page", {
          url: url,
          page: "&page=" + next_page,
          param: param,
          commit: commit,
          callback: null
        });
      }
      if (callback) {
        callback();
      }
    },
    async get_task_labels(context) {
      let promises = [];
      context.state.tasks.forEach(function(task) {
        promises.push(
          axios.get(
            API_URL +
              "/recognition/v2/task/" +
              task.id +
              "?workspace=" +
              context.state.workspace
          )
        );
      });

      await Promise.all(promises).then(function(results) {
        results.forEach(function(response) {
          context.commit("SET_LABELS", {
            task: response.data,
            labels: response.data["labels"]
          });
        });
      });
    },
    async get_detection_label_tasks(context) {
      return new Promise((resolve, reject) => {
        for (var i = 0; i < context.state.detection_labels.length; i++) {
          console.log(
            "Request to:",
            API_URL +
              "/detection/v2/label/" +
              context.state.detection_labels[i].id
          );
          axios
            .get(
              API_URL +
                "/detection/v2/label/" +
                context.state.detection_labels[i].id +
                "?workspace=" +
                context.state.workspace
            )
            .then(
              response => {
                context.commit("SET_TASKS", {
                  detection_label: response.data,
                  tasks: response.data["recognition_tasks"]
                });
              },
              error => {
                console.log("error getting tasks");
                reject(error);
              }
            );
        }
        resolve(1);
      });
    },
    async get_flow_json(context, data) {
      if (data.id) {
        let result = await axios.get(
          API_URL + "/flows/v2/flow/" + data.id + "/json"
        );

        if (result.data)
          context.commit("SET_FLOW_JSON", {
            flow_id: data.id,
            json_data: result.data
          });
        else {
          context.commit("SET_FLOW_JSON", {
            flow_id: data.id,
            json_data: {}
          });
        }
      }
    },
    createPOST(context, data) {
      return new Promise((resolve, reject) => {
        axios.post(API_URL + data.endpoint, data.data).then(
          response => {
            context.commit(data.commit_clear);
            context.dispatch("get_data_page", {
              url: API_URL + data.endpoint,
              page: "",
              param: "",
              commit: data.commit_page,
              callback: null
            });
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    crudPost(context, data) {
      /**
       * Adding/Removing Labels, Tasks, ... to/from endpoints.
       */
      console.log("Request to:", data.url);
      return new Promise((resolve, reject) => {
        axios.post(data.url, data.data).then(
          response => {
            context.commit(data.mutation, {
              task: data.task,
              label: data.label
            });
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    deleteEntity(context, data) {
      /**
       * Deleting entity as Label, Task, ...
       */
      axios
        .delete(
          API_URL +
            data.endpoint +
            data.id +
            "?workspace=" +
            context.state.workspace
        )
        .then(
          response => {
            context.commit(data.commit_clear);
            context.dispatch("get_data_page", {
              url: API_URL + data.endpoint,
              page: "",
              param: "",
              commit: data.commit_page,
              callback: null
            });
          },
          error => {}
        );
    },
    create_detection_task(context, task) {
      context.dispatch("createPOST", {
        endpoint: "/detection/v2/task/",
        data: task,
        commit_clear: "CLEAR_DETECTION_TASKS",
        commit_page: "ADD_DETECTION_TASK"
      });
    },
    create_similarity_task(context, task) {
      context.dispatch("createPOST", {
        endpoint: "/similarity/training/v2/task/",
        data: task,
        commit_clear: "CLEAR_SIMILARITY_TASKS",
        commit_page: "ADD_SIMILARITY_TASK"
      });
    },
    create_similarity_label(context, task) {
      context.dispatch("createPOST", {
        endpoint: "/similarity/training/v2/type/",
        data: task,
        commit_clear: "CLEAR_SIMILARITY_TYPES",
        commit_page: "ADD_SIMILARITY_TYPE"
      });
    },
    create_detection_label(context, label) {
      context.dispatch("createPOST", {
        endpoint: "/detection/v2/label/",
        data: label,
        commit_clear: "CLEAR_DETECTION_LABELS",
        commit_page: "ADD_DETECTION_LABEL"
      });
    },
    delete_detection_task(context, task) {
      context.dispatch("deleteEntity", {
        endpoint: "/detection/v2/task/",
        id: task.id,
        commit_clear: "CLEAR_DETECTION_TASKS",
        commit_page: "ADD_DETECTION_TASK"
      });
    },
    delete_similarity_task(context, task) {
      context.dispatch("deleteEntity", {
        endpoint: "/similarity/training/v2/task/",
        id: task.id,
        commit_clear: "CLEAR_DETECTION_TASKS",
        commit_page: "ADD_DETECTION_TASK"
      });
    },
    delete_detection_label(context, label) {
      context.dispatch("deleteEntity", {
        endpoint: "/detection/v2/label/",
        id: label.id,
        commit_clear: "CLEAR_DETECTION_LABELS",
        commit_page: "ADD_DETECTION_LABEL"
      });
    },
    delete_similarity_label(context, label) {
      context.dispatch("deleteEntity", {
        endpoint: "/similarity/training/v2/type/",
        id: label.id,
        commit_clear: "CLEAR_SIMILARITY_TYPES",
        commit_page: "ADD_SIMILARITY_TYPE"
      });
    },
    create_segmentation_task(context, task) {
      context.dispatch("createPOST", {
        endpoint: "/segmentation/v2/task/",
        data: task,
        commit_clear: "CLEAR_SEGMENTATION_TASKS",
        commit_page: "ADD_SEGMENTATION_TASK"
      });
    },
    create_segmentation_label(context, label) {
      context.dispatch("createPOST", {
        endpoint: "/segmentation/v2/label/",
        data: label,
        commit_clear: "CLEAR_SEGMENTATION_LABELS",
        commit_page: "ADD_SEGMENTATION_LABEL"
      });
    },
    delete_segmentation_task(context, task) {
      context.dispatch("deleteEntity", {
        endpoint: "/segmentation/v2/task/",
        id: task.id,
        commit_clear: "CLEAR_SEGMENTATION_TASKS",
        commit_page: "ADD_SEGMENTATION_TASK"
      });
    },
    delete_segmentation_label(context, label) {
      context.dispatch("deleteEntity", {
        endpoint: "/segmentation/v2/label/",
        id: label.id,
        commit_clear: "CLEAR_SEGMENTATION_LABELS",
        commit_page: "ADD_SEGMENTATION_LABEL"
      });
    },
    update_detection_task(context, task) {
      context.commit("UPDATE_DETECTION_TASK", task);
    },
    update_detection_label(context, label) {
      context.commit("UPDATE_DETECTION_LABEL", label);
    },
    update_segmentation_task(context, task) {
      context.commit("UPDATE_SEGMENTATION_TASK", task);
    },
    update_similarity_label(context, task) {
      context.commit("UPDATE_SIMILARITY_TYPE", task);
    },
    update_similarity_task(context, task) {
      context.commit("UPDATE_SIMILARITY_TASK", task);
    },
    update_segmentation_label(context, label) {
      context.commit("UPDATE_SEGMENTATION_LABEL", label);
    },
    async update_last_image_flow(context, { flow }) {
      localStorage.setItem("last_image_flow", flow);
      context.commit("SET_LAST_IMAGE_FLOW", flow);
    },
    async update_last_detection_flow(context, { flow }) {
      localStorage.setItem("last_detection_flow", flow);
      context.commit("SET_LAST_DETECTION_FLOW", flow);
    },
    async update_last_detection_label(context, { label }) {
      localStorage.setItem("last_detection_label", label);
      context.commit("SET_LAST_DETECTION_LABEL", label);
    },
    async update_last_detection_task(context, { task }) {
      localStorage.setItem("last_detection_task", task);
      context.commit("SET_LAST_DETECTION_TASK", task);
    },
    async update_last_action(context, { action }) {
      localStorage.setItem("last_action", action);
      context.commit("SET_LAST_ACTION", action);
    },
    async update_last_image(context, { image, job }) {
      context.commit("SET_LAST_IMAGE", image);
      context.commit("SET_LAST_JOB", job);
    },
    async add_job_item(context, { item }) {
      context.commit("ADD_JOB_ITEM", item);
    },
    async clear_job_items(context) {
      context.commit("CLEAR_JOB_ITEMS");
    },
    async update_last_detection_action(context, { action }) {
      localStorage.setItem("last_detection_action", action);
      context.commit("SET_LAST_DETECTION_ACTION", action);
    },
    async changeWorkspace(context, { workspace }) {
      /**
       * When changing workspace, clean all tasks, labels, detections and load data again.
       */
      localStorage.setItem("workspace", workspace);

      context.commit("DATA_LOADED", DataLoadedEnum.NOT_LOADED);
      context.commit("SET_WORKSPACE", workspace);

      await context.dispatch("init_load_data");
    },
    async set_flow_json(context, { flow }) {
      context.commit("SET_FLOW_JSON", {
        flow_id: flow.id,
        json_data: flow.json_data
      });
    }
  }
});
