<template>
  <div>
    <div>
      <div>
        <b-navbar toggleable="xl" type="light" variant="light">
          <b-navbar-toggle target="images-collapse"></b-navbar-toggle>

          <b-collapse id="images-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item>
                <i class="fal fa-sliders-h fa-2x"></i>
              </b-nav-item>

              <b-nav-item>
                <v-select
                  style="width: 180px;"
                  class="mr-1"
                  :options="options_img"
                  v-model="entity"
                  :reduce="item => item.name"
                  placeholder="Select Filter"
                  label="name"
                >
                  <template slot="option" slot-scope="option">
                    <i :class="option.icon"></i>
                    {{ option.name }}
                  </template>
                </v-select>
              </b-nav-item>

              <b-nav-item>
                <v-select
                  style="width: 180px;"
                  class="mr-1"
                  :options="options"
                  v-model="verified"
                  :reduce="item => item.name"
                  placeholder="Select Filter"
                  label="name"
                >
                  <template slot="option" slot-scope="option">
                    <i :class="option.icon"></i>
                    {{ option.name }}
                  </template>
                </v-select>
              </b-nav-item>

              <b-nav-item>
                <b-form-group>
                  <b-form-select
                    v-model="option"
                    :options="options_group"
                  ></b-form-select>
                </b-form-group>
              </b-nav-item>

              <b-nav-item>
                <v-select
                  style="width: 180px;"
                  class="mr-1"
                  :options="options_real"
                  v-model="real"
                  :reduce="item => item.name"
                  placeholder="Select Filter"
                  label="name"
                >
                  <template slot="option" slot-scope="option">
                    <i :class="option.icon"></i>
                    {{ option.name }}
                  </template>
                </v-select>
              </b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto" v-if="job || task">
              <b-nav-form>
                <b-button
                  v-on:click="clearItems()"
                  class="mr-1"
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Clear Selected Items"
                >
                  <i class="fal fa-eraser fa-lg"></i>
                </b-button>
                <b-button
                  v-on:click="selectAll()"
                  class="mr-1"
                  variant="outline-warning"
                  v-b-tooltip.hover
                  title="Select All Items"
                >
                  <i class="fal fa-check-double fa-lg"></i>
                </b-button>
                <b-button
                  v-on:click="remove()"
                  class="mr-1"
                  variant="outline-danger"
                  v-b-tooltip.hover
                  title="Remove Items from ..."
                >
                  <i class="fal fa-minus fa-lg"></i>
                </b-button>
                <b-button
                  v-on:click="add()"
                  class="mr-1"
                  variant="outline-success"
                  v-b-tooltip.hover
                  title="Add Items to ..."
                >
                  <i class="fal fa-plus fa-lg"></i>
                </b-button>
              </b-nav-form>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

        <b-navbar
          toggleable="lg"
          class="border-bottom"
          type="light"
          variant="light"
        >
          <b-navbar-toggle target="images-collapse"></b-navbar-toggle>

          <b-collapse id="images-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item hidden="true">
                <i class="fal fa-sliders-h fa-2x"></i>
              </b-nav-item>

              <b-nav-item>
                <v-select
                  style="width: 250px;"
                  class="mr-1"
                  :options="labels"
                  v-model="selected_labels"
                  multiple
                  placeholder="Contains labels"
                  label="name"
                >
                  <template
                    v-b-tooltip.hover
                    :title="option.description"
                    slot="option"
                    slot-scope="option"
                  >
                    <span
                      style="width: 100%"
                      v-b-tooltip.hover
                      :id="'option-task-' + option.id"
                      :title="option.description"
                      >{{ option.name }}</span
                    >
                  </template>
                </v-select>
              </b-nav-item>
              <b-nav-item>
                <v-select
                  style="width: 250px;"
                  class="mr-1"
                  :options="detection_labels"
                  v-model="selected_detection_labels"
                  multiple
                  placeholder="Contains object"
                  label="name"
                ></v-select>
              </b-nav-item>
              <b-nav-item>
                <span style="margin-top: auto; margin-bottom: auto;">
                  <vuejsDatepicker
                    v-model="created"
                    :clear-button="clear_button"
                    placeholder="Select date"
                  />
                </span>
              </b-nav-item>
              <span style="margin-top: auto; margin-bottom: auto;">
                <b-form-checkbox
                  v-model="test"
                  name="check-button-job-task"
                  class="ml-3"
                  switch
                  >Test</b-form-checkbox
                >
              </span>
              <span style="margin-top: auto; margin-bottom: auto;">
                <b-form-checkbox
                  v-show="job || task"
                  v-model="present"
                  name="check-button-job-task"
                  class="ml-3"
                  switch
                  >In {{ message }}</b-form-checkbox
                >
              </span>
              <b-nav-item
                v-if="job || task"
                style="margin-top: auto; margin-bottom: auto;"
              >
                <span>
                  Selected:
                  <b v-if="!select_query">{{ selected_images.length }}</b>
                  <b v-else>{{ totalRows }}</b>
                </span>
              </b-nav-item>
              <b-nav-item>
                <div class="ml-3">
                  <b-button variant="outline-primary" v-on:click="loadImages()"
                    ><i class="fal fa-sync-alt"></i
                  ></b-button></div
              ></b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>

      <div class="container-fluid mt-3">
        <div class="d-flex justify-content-center mt-5" v-if="loading">
          <b-spinner label="Text Centered"></b-spinner>
          <span>Loading...</span>
        </div>

        <b-alert variant="info" show v-if="select_all && !loading">
          {{ images.length }} images are selected.
          <b v-on:click="selectQuery()" class="alert-heading">
            <a href="#"
              >Select all {{ totalRows }} images that match this search.</a
            >
          </b>
        </b-alert>

        <div class="card-deck" v-if="!loading">
          <div
            style="min-width: 140px; max-width: 140px;"
            class="card mb-4"
            v-for="image in images"
            v-bind:key="image.id"
          >
            <span v-if="entity == 'Images'">
              <VImage
                v-bind:image="image"
                @unselectImage="unselectImage"
                @selectImage="selectImage"
                v-bind:checkable="checkable"
              />
            </span>
            <span v-else>
              <VObject
                v-bind:object="image"
                @unselectImage="unselectImage"
                @selectImage="selectImage"
                v-bind:checkable="checkable"
              />
            </span>
          </div>
        </div>
        <b-row>
          <b-col>
            <b-pagination
              v-if="!loading"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              size="sm"
              label-page
              first-number
              last-number
              hide-goto-end-buttons
            />
          </b-col>
          <b-col>
            <b-form-input
              :state="validPage"
              class="ml-2 md"
              style="width: 100px;"
              v-model="currentPage"
              :type="'number'"
              >{{ currentPage }}</b-form-input
            >
          </b-col>
          <b-col>
            <VUpload
              v-bind:group_id="null"
              v-bind:key="'vupload-image'"
              class="ml-1"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-undef */

import axios from "axios";
import { mapState } from "vuex";
import VLoading from "@/components/VLoading.vue";
import VImage from "@/components/images/VImage.vue";
import VObject from "@/components/images/VObject.vue";
import { DataLoadedEnum } from "../../constants";
import VUpload from "@/components/image/VUpload.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VImages",
  components: {
    VImage,
    VObject,
    VUpload,
    vuejsDatepicker
  },
  props: {
    job: Object,
    task: Object
  },
  computed: {
    ...mapState([
      "tasks",
      "labels",
      "detection_labels",
      "workspace",
      "data_loaded",
      "labels"
    ]),
    validPage() {
      if (this.currentPage <= this.totalRows / this.perPage) {
        return null;
      }

      return false;
    },
    checkable: function() {
      if (this.job || this.task) {
        return true;
      }
      return false;
    }
  },
  data() {
    return {
      options: [
        { name: "All", icon: "fal fa-ellipsis-v-alt" },
        { name: "Verified", icon: "fal fa-clipboard-check" },
        { name: "Not Verified", icon: "fal fa-clipboard" }
      ],
      options_real: [
        { name: "All", icon: "fal fa-ellipsis-v-alt" },
        { name: "Product", icon: "fal fa-clipboard-check" },
        { name: "Real", icon: "fal fa-clipboard" }
      ],
      options_group: [
        { text: "-", value: null },
        { text: "In groups", value: false },
        { text: "Not in groups", value: true }
      ],
      options_img: [
        { name: "Images", icon: "fal fa-ellipsis-v-alt" },
        { name: "Objects", icon: "fal fa-clipboard-check" }
      ],
      option: null,
      entity: "Images",
      test: false,
      real: null,
      clear_button: true,
      message: "Task",
      present: false,
      verified: "All",
      created: null,
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      loading: true,
      images: [],
      select_query: false,
      select_all: false,
      selected_detection_labels: [],
      selected_labels: [],
      all_entities: [],
      selected_images: []
    };
  },
  methods: {
    async loadImages({ page = 1, type = null, clear = false } = {}) {
      if (clear) {
        console.log("clearing...");
        this.clearItems();
      }

      /**
       * Creating complex url for filtering images.
       */
      let verified = "";
      if (this.verified && this.verified != "All") {
        verified = this.verified == "Verified" ? "&verified=1" : "&verified=0";
      }

      let test = "";
      if (this.test) {
        test = "&test=true";
      }

      let real = "";
      if (this.real && this.real != "All") {
        if (this.real == "Real") {
          real = "&real=true";
        } else {
          real = "&real=false";
        }
      }

      let labels = this.selected_labels.length
        ? this.selected_labels
            .map(function(elemenet) {
              return "&labels=" + elemenet.id;
            })
            .join()
        : "";

      let detection_labels = this.selected_detection_labels.length
        ? this.selected_detection_labels
            .map(function(elemenet) {
              return "&detection_labels=" + elemenet.id;
            })
            .join()
        : "";

      let present = "";
      if (this.job) {
        present = "&imagejob=" + this.job.id;
        present += this.present ? "" : "&not_in=True";
      }

      if (this.task) {
        present = "&detection_task_neg=" + this.task.id;
        present += this.present ? "" : "&not_in=True";
      }

      let in_groups = "";
      if (this.option != null) {
        if (this.option) {
          in_groups = "&not_groups=true";
        } else {
          in_groups = "&not_groups=false";
        }
      }

      let created = "";
      if (this.created) {
        created =
          "&created_after=" +
          this.created.toISOString().split("T")[0] +
          "&created_before=" +
          this.created.toISOString().split("T")[0] +
          "T23:59:00.000Z";
      }
      this.loading = true;
      this.select_query = null;

      let base_endpoint = "/recognition/v2/training-image/?page_size=";
      if (this.entity == "Objects") {
        base_endpoint = "/detection/v2/object/?page_size=";
      }

      /**
       * Querying images.
       */
      const response = await axios
        .get(
          API_URL +
            base_endpoint +
            this.perPage +
            "&page=" +
            page +
            present +
            labels +
            created +
            in_groups +
            detection_labels +
            "&include_verifications=1" +
            verified +
            real +
            test +
            "&workspace=" +
            this.workspace
        )
        .catch(err => {
          this.images = [];
          this.loading = false;
        });

      console.log(
        API_URL +
          base_endpoint +
          this.perPage +
          "&page=" +
          page +
          present +
          labels +
          created +
          detection_labels +
          "&include_verifications=1" +
          verified +
          real +
          test +
          "&workspace=" +
          this.workspace
      );

      if (response == null) {
        return;
      }
      let next = response.data["next"];
      this.images = response.data["results"];
      this.totalRows = response.data["count"];

      /**
       * Getting images.
       */
      for (let i = 0; i < this.images.length; i++) {
        this.all_entities.push(this.images[i]);

        let id = this.findSelectedImage(this.images[i].id);

        if (type) {
          this.selectImage({ id: this.images[i]["id"] });
        } else if (id) {
          this.$set(this.images[i], "selected", true);
        } else {
          this.$set(this.images[i], "selected", false);
        }
      }

      /*
       * We are adding images to Job or Task, ...
       */
      if (type) {
        if (type == "ADD_IMAGES" && this.job) {
          await this.addToJob();
        } else if (this.job) {
          await this.removeFromJob();
        }

        if (type == "ADD_IMAGES" && this.task) {
          await this.addToTask();
        } else if (this.task) {
          await this.removeFromTask();
        }

        this.clearItems();

        console.log("Next page", next);
        if (next) {
          if (this.job || this.task) {
            this.selected_images = [];
            page = 0;
          }
          await this.loadImages({ page: (page = page + 1), type: type });
        } else {
          await this.loadImages({ page: (page = page + 1), type: null });
        }
      } else {
        this.loading = false;
      }
    },
    async add() {
      if (this.select_query) {
        await this.loadImages({ page: 1, type: "ADD_IMAGES" });
      } else {
        if (this.job) {
          this.addToJob();
        } else {
          this.addToTask();
        }
      }

      this.makeToast(
        "success",
        "Images were added to " + this.message + "!",
        "Ok"
      );
      this.refresh();
    },
    async remove() {
      if (this.select_query) {
        await this.loadImages({ page: 1, type: "REMOVE_IMAGES" });
      } else {
        if (this.job) {
          this.removeFromJob();
        } else {
          this.removeFromTask();
        }
      }

      this.makeToast(
        "danger",
        "Images were removed from " + this.message + "!",
        "Ok"
      );
      this.refresh();
    },
    makeToast(variant, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        toaster: "b-toaster-bottom-right",
        solid: true
      });
    },
    getImageId(entity) {
      if (this.entity == "Images") {
        return entity.id;
      } else {
        return entity.image.id;
      }
    },
    async addToTask() {
      let add_items = this.selected_images.map(this.getImageId);

      await axios
        .post(
          API_URL +
            "/detection/v2/task/" +
            this.task.id +
            "/add-images?workspace=" +
            this.workspace,
          { items: add_items }
        )
        .then(response => {}, error => {});
    },
    async removeFromTask() {
      let remove_items = this.selected_images.map(this.getImageId);

      await axios
        .post(
          API_URL +
            "/detection/v2/task/" +
            this.task.id +
            "/remove-images?workspace=" +
            this.workspace,
          { items: remove_items }
        )
        .then(response => {}, error => {});
    },
    async addToJob() {
      let add_items = this.selected_images.map(this.getImageId);

      console.log("Items", add_items);
      await axios
        .post(
          API_URL +
            "/annotate/v2/imagejob/" +
            this.job.id +
            "/add-items?workspace=" +
            this.workspace,
          { items: add_items }
        )
        .then(response => {}, error => {});
    },
    async removeFromJob() {
      let remove_items = this.selected_images.map(this.getImageId);

      await axios
        .post(
          API_URL +
            "/annotate/v2/imagejob/" +
            this.job.id +
            "/remove-items?workspace=" +
            this.workspace,
          { items: remove_items }
        )
        .then(response => {}, error => {});
    },
    selectQuery: function() {
      this.selectAll();
      this.select_query = true;
    },
    findSelectedImage: function(id) {
      let found = this.selected_images.find(function(element) {
        return this.getImageId(element) == id;
      }, this);
      return found;
    },
    findImage: function(id) {
      let found = this.images.find(function(element) {
        return element.id == id;
      }, this);
      return found;
    },
    selectAll: function() {
      this.images.forEach(element => {
        this.selectImage({ id: element.id });
        this.select_all = true;
      });
      this.select_all = true;
    },
    clearItems: function() {
      this.select_all = false;
      this.selected_images = [];

      this.images.forEach(element => {
        this.$set(element, "selected", false);
      });
    },
    selectImage: function(e) {
      this.select_all = false;
      this.select_query = null;

      let item = this.findSelectedImage(e.id);
      if (item) {
        return;
      }

      let image = this.findImage(e.id);
      this.$set(image, "selected", true);
      this.selected_images.push(image);
    },
    unselectImage: function(e) {
      this.select_all = false;
      this.select_query = null;

      let item = this.findSelectedImage(e.id);
      if (!item) {
        return;
      }

      let index = this.selected_images.indexOf(item);
      this.selected_images.splice(index, 1);
      let image = this.findImage(e.id);
      this.$set(image, "selected", false);
    },
    refresh: function(e) {
      if (this.job) {
        this.$emit("refresh");
      }
      this.loadImages({ page: this.currentPage });
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadImages();
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.images = [];
        this.loadImages({ clear: true });
      }
    },
    job() {
      if (this.job) {
        this.loadImages({ clear: true });
        this.message = "Job";
      }
    },
    task() {
      if (this.task) {
        this.loadImages({ clear: true });
        this.message = "Task";
      }
    },
    present() {
      console.log("change present");
      this.loadImages({ clear: true });
    },
    entity() {
      console.log("change entity");
      this.loadImages({ clear: true });
    },
    created() {
      console.log("change created");
      this.loadImages();
    },
    currentPage() {
      console.log("change page");
      this.loadImages({ page: this.currentPage });
    },
    selected_labels() {
      console.log("change labels");
      this.loadImages({ clear: true });
    },
    selected_detection_labels() {
      console.log("change detection labels");
      this.loadImages({ clear: true });
    },
    verified() {
      console.log("change verified labels");
      this.loadImages({ clear: true });
    },
    test() {
      console.log("change verified labels");
      this.loadImages({ clear: true });
    },
    option() {
      this.loadImages({ clear: true });
    },
    real() {
      this.loadImages({ clear: true });
    }
  }
};
</script>

<style scoped>
.fa-check-square {
  color: green;
}
</style>
