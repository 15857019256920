<template>
  <span v-if="action">
    <span v-if="detection">
      <span
        v-bind:key="branch_action[0]"
        v-for="branch_action in this.action.cases"
      >
        <span v-if="isActiveBranch(branch_action)">
          <VFlowAction
            v-bind:action="getActiveBranch(branch_action)"
            v-bind:image="image"
            v-bind:detection="detection"
          />
        </span>
      </span>
    </span>
    <span v-else>
      Please select detection object of type:
      <span v-for="label in this.action.labels" v-bind:key="label.name">
        <b>{{ label.name }}, </b>
      </span>
    </span>
  </span>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VDetectionSwitchAction",
  components: {
    VFlowAction: () => import("@/components/image/flows/VFlowAction.vue")
  },
  props: {
    image: Object,
    detection: Object,
    action: Object
  },
  data() {
    return {
      active_branches: {},
      predict_branch: []
    };
  },
  computed: {
    ...mapState([]),
    entity_id: function() {
      if (this.detection) {
        return this.detection.id;
      }
      return this.image.id;
    }
  },
  filters: {},
  updated() {},
  methods: {
    activateBranch(label) {
      // show activated branch
      if (label in this.active_branches) {
        this.active_branches[label] = true;
      }
      this.$forceUpdate();
    },
    deactivateBranch(label) {
      // call remove on all branch we are deactivating
      for (let branch of this.action.cases) {
        if (label in branch) {
          this.$eventBus.$emit("call-remove-action-" + branch[label].id);
        }
      }

      this.active_branches[label] = false;
      this.$forceUpdate();
    },
    getActiveBranch(branch) {
      let item = _.get(_.keys(branch), 0);
      return branch[item];
    },
    isActiveBranch(branch) {
      let item = _.get(_.keys(branch), 0);
      if (item in this.active_branches) {
        return this.active_branches[item];
      }
      return false;
    },
    predict() {
      this.$eventBus.$emit("switch-call-recognition-task-" + this.action.task);
    },
    remove() {
      this.$eventBus.$emit("switch-clear-recognition-task-" + this.action.task);
    }
  },
  mounted: function() {},
  created() {
    console.log("HAVING OBJECT", this.detection, this.action);

    for (let index in this.action.cases) {
      this.active_branches[_.get(_.keys(this.action.cases[index]), 0)] = false;
    }

    if (!this.detection) {
      return;
    }
    // this.active_branches = {};
    this.activateBranch(this.detection.id_label);

    this.$eventBus.$on("call-remove-action-" + this.action.id, this.remove);

    // activate branch if added label (not after predict)
    this.$eventBus.$on(
      "switch-add-label-image-task-" + this.entity_id + "-" + this.action.task,
      this.activateBranch
    );

    // if some branch was deactivated then remove all sub labels
    this.$eventBus.$on(
      "switch-remove-label-image-task-" +
        this.entity_id +
        "-" +
        this.action.task,
      this.deactivateBranch
    );
  },
  beforeDestroy() {
    this.$eventBus.$off("call-remove-action-" + this.action.id);
    this.$eventBus.$off(
      "switch-add-label-image-task-" + this.entity_id + "-" + this.action.task
    );
    this.$eventBus.$off(
      "switch-remove-label-image-task-" +
        this.entity_id +
        "-" +
        this.action.task
    );
  }
};
</script>

<style></style>
