<template>
  <span v-if="action">
    <span v-if="action.type == 'switch'">
      <VSwitchAction
        v-bind:image="image"
        v-bind:action="action"
        v-bind:detection="detection"
      />
    </span>
    <span v-if="action.type == 'list'">
      <VListAction
        v-bind:image="image"
        v-bind:action="action"
        v-bind:detection="detection"
      />
    </span>
    <span v-if="action.type == 'recognition'">
      <VRecognitionAction
        v-bind:image="image"
        v-bind:task_id="action.task"
        v-bind:action="action"
        v-bind:key="'recognition-action-flow-' + action.task"
        v-bind:detection="detection"
      />
    </span>
    <span v-if="action.type == 'detection_switch'">
      <VDetectionSwitchAction
        v-bind:image="image"
        v-bind:task_id="action.task"
        v-bind:action="action"
        v-bind:detection="detection"
      />
    </span>
  </span>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import VSwitchAction from "@/components/image/flows/VSwitchAction.vue";
import VListAction from "@/components/image/flows/VListAction.vue";
import VRecognitionAction from "@/components/image/flows/VRecognitionAction.vue";
import VDetectionSwitchAction from "@/components/image/flows/VDetectionSwitchAction.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VFlowAction",
  components: {
    VSwitchAction,
    VListAction,
    VRecognitionAction,
    VDetectionSwitchAction
  },
  props: {
    image: Object,
    detection: Object,
    action: Object
  },
  data() {
    return {};
  },
  computed: {},
  filters: {},
  methods: {},
  mounted: function() {}
};
</script>

<style></style>
