<template>
  <div class="container-fluid mt-3" style="height: 90%; overflow-y: auto;">
    <VDelete
      v-bind:title="'job'"
      v-bind:item_to_remove="item_to_remove"
      @hide="hideJobModal"
      @remove="removeJob"
    />

    <VJobCreate v-bind:job="selected_job" @refresh="refresh" />
    <b-button variant="outline-success" v-on:click="createNewJob()" class="mt-3"
      >Create New Job</b-button
    >

    <b-table
      class="mt-3"
      :outlined="outlined"
      :hover="hover"
      :items="jobs"
      :fields="fields"
      :busy="loading"
      sort-by.sync="name"
      sort-icon-left
      :fixed="fixed"
    >
      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>

      <template v-slot:cell(name)="data">
        {{ data.item.name }}
        <VClipCopy
          :from="'jobs'"
          v-bind:uuid="data.item.id"
          v-bind:name="'Job'"
        />
      </template>

      <template v-slot:cell(progress)="data">
        <b-progress
          :max="data.item.item_count"
          :variant="
            getProgressVariant(data.item.item_count, data.item.item_done_count)
          "
          show-progress
          animated
        >
          <b-progress-bar :value="data.item.item_done_count">
            <strong>
              {{ data.item.item_done_count }} /
              {{ data.item.item_count }}
            </strong>
          </b-progress-bar>
        </b-progress>
      </template>

      <template v-slot:cell(item_count)="data">
        <span>{{ data.item.item_count }}</span>
      </template>

      <template v-slot:cell(recognition_task)="data">
        <span v-if="data.item.type == 'image'">
          <span v-if="data.item.recognition_tasks.length == 1">{{
            getTaskById(data.item.recognition_tasks[0]).name
          }}</span>
          <span v-if="data.item.recognition_tasks.length > 1">multiple</span>
        </span>
      </template>

      <template v-slot:cell(flow)="data">
        <span v-if="data.item.type == 'image'">
          <span v-if="data.item.flow">{{
            getFlowById(data.item.flow).name
          }}</span>
        </span>
      </template>

      <template v-slot:cell(action)="data" class="col-sm-1">
        <b-button
          v-if="data.item.done"
          v-on:click="doneJob(data.item, data.item.done)"
          class="mr-1"
          variant="success"
          v-b-tooltip.hover
          title="Complete Job"
        >
          <i class="fal fa-check"></i>
        </b-button>
        <b-button
          v-if="!data.item.done"
          id="show-btn"
          @click="showCompleteJob(data.item, data.item.done)"
          class="mr-1"
          variant="outline-success"
          v-b-tooltip.hover
          title="Complete Job"
        >
          <i class="fal fa-check"></i>
        </b-button>

        <b-button
          v-on:click="updateJob(data.item)"
          class="mr-1"
          variant="outline-success"
          v-b-tooltip.hover
          title="Edit Job"
        >
          <i class="fal fa-edit"></i>
        </b-button>

        <!--<router-link v-bind:to="/imagejob/ + data.item.id">-->
        <b-button
          variant="outline-primary"
          v-b-tooltip.hover
          title="Start Work"
          v-on:click="start_work(data.item)"
        >
          <i class="fal fa-play"></i>
        </b-button>
        <!--</router-link>-->

        <b-button
          v-on:click="showRemoveJobModal(data.item)"
          class="mr-1 ml-1"
          variant="outline-danger"
          v-b-tooltip.hover
          title="Delete Job"
        >
          <i class="fal fa-trash-alt"></i>
        </b-button>
      </template>
    </b-table>
    <div>
      <div class="mt-4">
        <h4>Manage</h4>
        <v-select
          class="mt-3"
          style="width: 300px;"
          v-model="selected_job"
          :options="jobs"
          placeholder="Select job for adding images"
          label="name"
        ></v-select>

        <span v-if="selected_job">
          <span v-if="selected_job.type == 'image'">
            <div class="mt-4">
              <VImages
                v-bind:job="selected_job"
                @refresh="refresh"
                class="border"
              />
            </div>
          </span>
          <span v-else>
            <VGroups v-bind:job="selected_job" @refresh="refresh" />
          </span>
        </span>
      </div>
    </div>
    <b-modal title="Complete job ..." id="bv-modal-complete-job" hide-footer>
      <div class="d-block text-center">
        <h5>
          Are you sure you want to complete selected job? This will also verify
          all images within job.
        </h5>
      </div>
      <b-row>
        <b-col>
          <b-button
            class="mt-3"
            variant="outline-success"
            block
            @click="doneJob(selected_job, false)"
          >
            <i class="fal fa-check"></i> Yes
          </b-button>
        </b-col>
        <b-col>
          <b-button
            class="mt-3"
            variant="outline-warning"
            block
            @click="$bvModal.hide('bv-modal-complete-job')"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { DataLoadedEnum } from "../../constants";
import VTaskCreate from "@/components/create/VTaskCreate.vue";
import VLabelCreate from "@/components/create/VLabelCreate.vue";
import VJobCreate from "@/components/create/VJobCreate.vue";
import VImages from "@/components/images/VImages.vue";
import VDelete from "@/components/create/VDelete.vue";
import VGroups from "@/components/similarity/VGroups.vue";
import VClipCopy from "@/components/utils/VClipCopy.vue";

import axios from "axios";
let API_URL = process.env.VUE_APP_API_URL;

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const aname = a.name.toUpperCase();
  const bname = b.name.toUpperCase();

  let comparison = 0;
  if (aname > bname) {
    comparison = 1;
  } else if (aname < bname) {
    comparison = -1;
  }
  return comparison;
}

export default {
  name: "Tasks",
  components: {
    VJobCreate,
    VClipCopy,
    VImages,
    VDelete,
    VGroups
  },
  data() {
    return {
      item_to_remove: null,
      jobs: [],
      selected_job: null,
      outlined: true,
      fixed: true,
      hover: true,
      loading: true,
      fields: [
        { key: "name", sortable: true },
        { key: "progress", sortable: false },
        { key: "item_count", sortable: false },
        { key: "todo_number", sortable: false },
        { key: "type", sortable: false },
        { key: "flow", sortable: false },
        { key: "recognition_task", sortable: false },
        { key: "action", sortable: false }
      ]
    };
  },
  computed: {
    ...mapState([
      "detection_tasks",
      "flows",
      "tasks",
      "data_loaded",
      "workspace"
    ])
  },
  methods: {
    getProgressVariant(value, value2) {
      if (value != null && value2 != null) {
        if (value == value2) {
          return "success";
        }
      }
      return "primary";
    },
    copyToClipBoard: function(id) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    start_work(job) {
      this.$store.dispatch("clear_job_items");
      if (job.type == "image") {
        this.$store.dispatch("update_last_image", { image: null });
        this.$router.push("/imagejob/" + job.id);
      } else {
        this.$router.push("/similarityjob/" + job.id);
      }
    },
    getTaskById(id) {
      let task = this.tasks.find(function(element) {
        return element.id == id;
      });
      return task;
    },
    getFlowById(id) {
      let flow = this.flows.find(function(element) {
        return element.id == id;
      });
      return flow;
    },
    showRemoveJobModal(job) {
      this.item_to_remove = job;
    },
    hideJobModal() {
      this.item_to_remove = null;
    },
    createNewJob() {
      this.selected_job = null;
      this.$bvModal.show("image-job-create");
    },
    updateJob(item) {
      console.log("update job", item);
      this.selected_job = item;
      this.$bvModal.show("image-job-create");
    },
    showCompleteJob(item, true_value) {
      this.selected_job = item;
      this.$bvModal.show("bv-modal-complete-job");
    },
    doneJob(item, true_value) {
      axios
        .put(
          API_URL +
            "/annotate/v2/" +
            item.type +
            "job/" +
            item.id +
            "?workspace=" +
            this.workspace,
          { done: !true_value, workspace: this.workspace }
        )
        .then(
          response => {
            this.$bvModal.hide("bv-modal-complete-job");
            this.loadJobs();
          },
          error => {}
        );
    },
    removeJob(e) {
      let job = this.jobs.find(function(element) {
        return element.id == e.id;
      });

      axios
        .delete(
          API_URL +
            "/annotate/v2/" +
            job.type +
            "job/" +
            e.id +
            "?workspace=" +
            this.workspace
        )
        .then(
          response => {
            this.loadJobs();
          },
          error => {}
        );
    },
    async loadJobs() {
      this.jobs = []; // clean the storage
      await this.loadJobsType("image");
      await this.loadJobsType("similarity");
    },
    async loadJobsType(type) {
      let jobs = await axios.get(
        API_URL + "/annotate/v2/" + type + "job/?workspace=" + this.workspace
      );
      jobs = jobs.data["results"];
      jobs.sort(compare);

      for (let i = 0; i < jobs.length; i++) {
        await this.loadJob(jobs[i].id, type);
      }
      this.loading = false;
    },
    async loadJob(id, type) {
      return new Promise((resolve, reject) => {
        return axios.get(API_URL + "/annotate/v2/" + type + "job/" + id).then(
          response => {
            response.data["type"] = type;
            this.jobs.push(response.data);
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    },
    refresh(e) {
      this.loadJobs();
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loadJobs();
      }
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadJobs();
    }
  }
};
</script>

<style></style>
