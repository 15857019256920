import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import "highlight.js/styles/github.css";
import VueHighlightJS from "vue-highlightjs";

//import * as Sentry from "@sentry/browser";
//import * as Integrations from "@sentry/integrations";

Vue.component("v-select", vSelect);

Vue.use(BootstrapVue);
Vue.use(VueHighlightJS);

Vue.prototype.$eventBus = new Vue();
Vue.config.productionTip = false;

Vue.prototype.$forceCompute = function(
  computedName,
  forceUpdate /* default: true */
) {
  if (this._computedWatchers[computedName]) {
    this._computedWatchers[computedName].run();
    if (forceUpdate || typeof forceUpdate == "undefined") this.$forceUpdate();
  }
};

/* Configuration of Axios, setting JWT to every request if present */
// ! OLD WAY WITH JWT
// Vue.prototype.$http = axios;
// const token = localStorage.getItem("token");
// if (token) {
//   Vue.prototype.$http.defaults.headers.common["Authorization"] = "JWT " + token;
// }

// ! NEW WAY WITH TWO TOKENS
Vue.prototype.$http = axios;
let API_URL = process.env.VUE_APP_API_URL;
let accessToken = localStorage.getItem("access");
let refreshToken = localStorage.getItem("refresh");

if (accessToken) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + accessToken;
}

Vue.prototype.$http.interceptors.response.use(undefined, async function(error) {
  if (error.config && error.response && error.response.status === 401) {
    // If 401 by expired access cookie, we do a refresh request
    refreshToken = localStorage.getItem("refresh");
    return Vue.prototype.$http
      .post(API_URL + "/account/v2/jwt/refresh/", { refresh: refreshToken })
      .then(response => {
        accessToken = response.data.access;
        localStorage.setItem("access", accessToken);

        axios.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
        Vue.prototype.$http.defaults.headers.common["Authorization"] =
          "Bearer " + accessToken;
        error.config.headers["Authorization"] = "Bearer " + accessToken;
        return axios.request(error.config);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          // If 401 by expired refresh token, redirect to login page
          window.location = "/login";
        } else {
          throw error;
        }
      });
  }
  throw error;
});

/*Sentry.init({
  dsn: "https://d563777d3c4b4a2eb272b7d62dbce0e8@sentry.ximilar.com/26",
  integrations: [new Integrations.Vue({ Vue, attachProps: true })]
});*/

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
