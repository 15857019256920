<template>
  <div class="container-fluid mt-3" style="height: 90%; overflow-y: auto;">
    <!-- Tasks -->
    <VDelete
      v-bind:title="'task'"
      v-bind:item_to_remove="task_to_remove"
      @hide="hideTaskModal"
      @remove="removeTask"
    />
    <VTaskCreate v-bind:task="selected_task" type="segmentation" />
    <b-button v-on:click="createTask()" variant="outline-success" class="mt-3"
      >Create New Task</b-button
    >
    <b-table
      class="mt-3"
      :outlined="outlined"
      :hover="hover"
      :items="segmentation_tasks"
      :fields="fields_task"
      :busy="loading"
      caption-top
    >
      <template v-slot:table-caption
        >List of segmentation tasks</template
      >
      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>

      <template v-slot:cell(name)="data">
        {{ data.item.name }}
        <span
          v-b-popover.hover="'copy Task ID to clipboard'"
          v-on:click="copyToClipBoard(data.item.id)"
        >
          <i class="fal fa-copy"></i>
        </span>
      </template>

      <template v-slot:cell(action)="data">
        <b-button
          v-on:click="updateTask(data.item)"
          class="mr-1"
          variant="outline-success"
          v-b-tooltip.hover
          title="Edit Task"
        >
          <i class="fal fa-edit fa-lg"></i>
        </b-button>

        <b-button
          v-on:click="trainTask(data.item.id)"
          class="mr-1"
          variant="outline-success"
          v-b-tooltip.hover
          title="Train/Optimize Task"
        >
          <i class="fal fa-dumbbell fa-lg"></i>
        </b-button>

        <b-button
          v-on:click="showRemoveTaskModal(data.item)"
          class="mr-1"
          variant="outline-danger"
          v-b-tooltip.hover
          title="Remove Task"
        >
          <i class="fal fa-minus fa-lg"></i>
        </b-button>
      </template>
      <template slot="map" slot-scope="data">
        {{ data.item.map | parseScore }}
      </template>
    </b-table>

    <!-- Labels -->
    <VDelete
      v-bind:title="'label'"
      v-bind:item_to_remove="label_to_remove"
      @hide="hideLabelModal"
      @remove="removeLabel"
    />
    <VLabelCreate v-bind:label="selected_label" type="segmentation" />
    <b-button
      v-b-modal.detection-label-create
      variant="outline-success"
      class="mt-3"
      v-on:click="createLabel()"
      >Create New Label</b-button
    >

    <b-table
      class="mt-3"
      :outlined="outlined"
      :hover="hover"
      :items="segmentation_labels"
      :fields="fields_label"
      :busy="loading"
      caption-top
    >
      <template v-slot:table-caption
        >List of segmentation labels</template
      >
      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>

      <template v-slot:cell(name)="data">
        {{ data.item.name }}
        <span
          v-b-popover.hover="'copy Task ID to clipboard'"
          v-on:click="copyToClipBoard(data.item.id)"
        >
          <i class="fal fa-copy"></i>
        </span>
      </template>

      <template v-slot:cell(color)="data">
        <span :style="'color:' + data.item.color">
          <i
            :style="'--fa-primary-color: gray;'"
            class="fad fa-square-full fa-2x"
          ></i>
        </span>
      </template>

      <template v-slot:cell(action)="data">
        <b-button
          v-on:click="updateLabel(data.item)"
          class="mr-2"
          variant="outline-success"
          v-b-tooltip.hover
          title="Edit Label"
        >
          <i class="fal fa-edit fa-lg"></i>
        </b-button>

        <b-button
          v-on:click="showRemoveLabelModal(data.item)"
          class="mr-2"
          variant="outline-danger"
          v-b-tooltip.hover
          title="Remove Label"
        >
          <i class="fal fa-minus fa-lg"></i>
        </b-button>
      </template>
    </b-table>
    <div class="mt-4">
      <h4>Manage</h4>
      <b-row>
        <b-col>
          <VSegmentationTask />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import VLabelCreate from "@/components/create/VLabelCreate.vue";
import VSegmentationTask from "@/components/manage/VSegmentationTask.vue";
import VDelete from "@/components/create/VDelete.vue";
import VTaskCreate from "@/components/create/VTaskCreate.vue";

import { mapState } from "vuex";
import { DataLoadedEnum } from "../constants";
import axios from "axios";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "Detection",
  components: {
    VSegmentationTask,
    VLabelCreate,
    VTaskCreate,
    VDelete
  },
  data() {
    return {
      label_to_remove: null,
      task_to_remove: null,
      outlined: true,
      hover: true,
      loading: true,
      fields_label: ["name", "color", "action"],
      selected_label: null,
      fields_task: [
        "name",
        "segmentationLabelsCount",
        "last_train_status",
        "action"
      ],
      selected_task: null
    };
  },
  computed: {
    ...mapState([
      "segmentation_labels",
      "data_loaded",
      "segmentation_tasks",
      "workspace"
    ])
  },
  methods: {
    copyToClipBoard: function(id) {
      console.log("copyying to", id);
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    updateLabel(label) {
      this.selected_label = label;
      this.$bvModal.show("type-label-create");
    },
    createLabel() {
      this.selected_label = null;
      this.$bvModal.show("type-label-create");
    },
    showRemoveLabelModal(label) {
      this.label_to_remove = label;
    },
    hideLabelModal() {
      this.label_to_remove = null;
    },
    removeLabel(e) {
      this.$store.dispatch("delete_segmentation_label", { id: e.id });
    },
    updateTask(task) {
      this.selected_task = task;
      this.$bvModal.show("type-task-create");
    },
    createTask() {
      this.selected_task = null;
      this.$bvModal.show("type-task-create");
    },
    showRemoveTaskModal(task) {
      this.task_to_remove = task;
    },
    hideTaskModal() {
      this.task_to_remove = null;
    },
    removeTask(task) {
      this.$store.dispatch("delete_segmentation_task", { id: task.id });
    },
    trainTask(task_id) {
      axios
        .post(API_URL + "/segmentation/v2/task/" + task_id + "/train", {
          workspace: this.workspace
        })
        .then(
          response => {
            this.makeToast("success", "OK", "Model was add to train queue!");
          },
          error => {
            this.makeToast(
              "danger",
              "Unable to  train",
              error.response.data["detail"]
            );
          }
        );
    },
    makeToast(variant, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        toaster: "b-toaster-bottom-right",
        solid: true
      });
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loading = false;
      }
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loading = false;
    }
  },
  filters: {
    parseScore: function(value) {
      if (value) {
        return value.toFixed(3);
      }
      return value;
    }
  }
};
</script>

<style></style>
