<template>
  <span
    v-b-popover.hover="'copy ' + name + ' ID to clipboard'"
    v-on:click="copyToClipBoard(uuid)"
  >
    <i class="fal fa-copy"></i>
    <b-toast
      :id="'clip-toast-' + from + '-' + name + '-' + uuid"
      variant="success"
      solid
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <i class="fas fa-unicorn mr-2 mt-1"></i>
          <strong class="mr-auto">Copied!</strong>
          <small class="text-muted mr-2">A second ago</small>
        </div>
      </template>
      The id of {{ name }} was copied to clipboard! {{ uuid }}
    </b-toast>
  </span>
</template>

<script>
export default {
  name: "VClipCopy",
  computed: {},
  methods: {
    copyToClipBoard: function(id) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      this.makeToast("success", "Copied to clipboard! " + id);
    },
    makeToast(variant = null, content) {
      // this.$bvToast.toast(content, {
      //   title: `Message`,
      //   variant: variant,
      //   solid: true
      // });
      this.$bvToast.show(
        "clip-toast-" + this.from + "-" + this.name + "-" + this.uuid
      );
    }
  },
  data() {
    return {
      mainProps: {
        height: 30,
        width: 30
      }
    };
  },
  mounted: function() {},
  props: {
    from: String,
    name: String,
    uuid: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* rainbow animation */
@keyframes colorCycle {
  0% {
    color: rgb(35, 139, 230);
  }
  12.5% {
    color: rgb(16, 184, 133);
  }
  25% {
    color: rgb(64, 192, 87);
  }
  37.5% {
    color: rgb(252, 196, 23);
  }
  50% {
    color: rgb(255, 146, 43);
  }
  62.5% {
    color: rgb(250, 82, 82);
  }
  75% {
    color: rgb(240, 101, 149);
  }
  87.5% {
    color: rgb(132, 94, 246);
  }
  100% {
    color: rgb(35, 139, 230);
  }
}

.fa-unicorn {
  animation: colorCycle 5s infinite linear;
}
</style>
