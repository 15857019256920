<template>
  <div class="container-fluid mt-3">
    <div v-if="!loading">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Detection Tasks" active>
            <VDetectionTask />
          </b-tab>
          <b-tab title="Recognition Task">
            <VRecognitionTask />
          </b-tab>
          <b-tab title="All Entities">
            <VShowEntities
              title="All Detection Tasks"
              field="detection_tasks"
            />
            <VShowEntities
              title="All Detection Labels"
              field="detection_labels"
            />
            <VShowEntities title="All Recognition Tasks" field="tasks" />
            <VShowEntities title="All Labels/Tags" field="labels" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <VLoading v-bind:loading="loading" />
  </div>
</template>

<script>
import VDetectionTask from "@/components/manage/VDetectionTask.vue";
import VShowEntities from "@/components/manage/VShowEntities.vue";
import VRecognitionTask from "@/components/manage/VRecognitionTask.vue";
import VLoading from "@/components/VLoading.vue";

import { DataLoadedEnum } from "../constants";
import { mapState } from "vuex";

export default {
  name: "manage",
  components: {
    VDetectionTask,
    VShowEntities,
    VRecognitionTask,
    VLoading
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState(["data_loaded"])
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loading = false;
      }
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loading = false;
    }
  }
};
</script>

<style></style>
