<template>
  <div>
    <b-card class="mt-3">
      <VGroupCreate :key="'list-groups-1'" />
      <b-card-title>Search Similarity Groups</b-card-title>
      <b-card-sub-title
        >Find a group and filter them by name, type ...</b-card-sub-title
      >

      <b-card-text class="mt-2">
        <b-form-group label="Group options" label-cols-lg="2">
          <b-form-checkbox v-model="test" inline>Test</b-form-checkbox>
          <b-form-checkbox v-model="train" inline>Train</b-form-checkbox>
          <b-form-checkbox v-model="empty" inline>Empty</b-form-checkbox>
          <b-form-checkbox v-model="present" inline v-show="job"
            >In job</b-form-checkbox
          >
        </b-form-group>

        <b-form-group label-cols-lg="2" label="Verified">
          <v-select
            inline
            style="width: 180px;"
            class="mr-1"
            :options="options"
            v-model="verified"
            :reduce="item => item.name"
            placeholder="Select Filter"
            label="name"
          >
            <template slot="option" slot-scope="option">
              <i :class="option.icon"></i>
              {{ option.name }}
            </template>
          </v-select>
        </b-form-group>

        <b-form-group label-cols-lg="2" label="Count">
          <b-form-select
            v-model="count"
            :options="count_options"
          ></b-form-select>
        </b-form-group>

        <b-form-group label-cols-lg="2" label="Group name">
          <b-input-group>
            <b-form-input
              placeholder="Enter prefix of similarity group name"
              v-model="group_name"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="Similarity Type"
          label-for="table-style-variant"
          label-cols-lg="2"
        >
          <b-form-select
            v-model="similarity_type"
            value-field="id"
            text-field="name"
            :options="similarity_types"
            id="table-style-variant"
          >
            <template #first>
              <option value="">-- None --</option>
            </template>
          </b-form-select>
        </b-form-group>

        <b-row>
          <b-col cols="auto" class="mr-auto">
            <b-button-group>
              <b-button v-on:click="createGroup" variant="outline-success"
                >Create New Group</b-button
              >
              <b-button
                class="ml-1"
                v-on:click="loadGroups()"
                variant="outline-primary"
                v-b-tooltip.hover
                title="Refresh groups"
              >
                <i class="fal fa-sync-alt"></i>
              </b-button>
            </b-button-group>
          </b-col>
          <b-col cols="auto" class="">
            <!-- Right aligned nav items -->
            <span class="ml-auto" v-if="job">
              <b-button
                v-on:click="clearItems()"
                class="mr-1"
                variant="outline-warning"
                v-b-tooltip.hover
                title="Clear Selected Items"
              >
                <i class="fal fa-eraser fa-lg"></i>
              </b-button>
              <b-button
                v-on:click="selectAll()"
                class="mr-1"
                variant="outline-warning"
                v-b-tooltip.hover
                title="Select All Items"
              >
                <i class="fal fa-check-double fa-lg"></i>
              </b-button>
              <b-button
                v-on:click="remove()"
                :disabled="!present"
                class="mr-1"
                variant="outline-danger"
                v-b-tooltip.hover
                title="Remove Items from ..."
              >
                <i class="fal fa-minus fa-lg"></i>
              </b-button>
              <b-button
                v-on:click="add()"
                :disabled="present"
                class="mr-1"
                variant="outline-success"
                v-b-tooltip.hover
                title="Add Items to ..."
              >
                <i class="fal fa-plus fa-lg"></i>
              </b-button>
            </span>
          </b-col>
        </b-row>

        <b-alert variant="info" class="mt-2" show v-if="job && !loading">
          <b v-if="!select_query">{{ selected_groups.length }}</b
          ><b v-else>{{ totalRows }}</b> images are selected.
          <b v-on:click="selectQuery()" class="alert-heading">
            <a href="#"
              >Select all <b>{{ totalRows }}</b> groups that match this
              search.</a
            >
          </b>
        </b-alert>

        <b-table
          :items="groups"
          :fields="fields"
          class="mt-3"
          :busy="loading"
          outlined
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>

          <template v-slot:cell(name)="data">
            {{ data.item.name }}
            <VClipCopy
              :from="'vgroupt'"
              v-bind:uuid="data.item.id"
              v-bind:name="'Group'"
              v-bind:key="'group-' + data.item.id"
            />
          </template>

          <template v-slot:cell(type)="data">
            {{ getTypeName(data.item.type) }}
          </template>

          <template v-slot:cell(test_group)="data">
            <span
              v-if="!data.item.test_group"
              class="badge badge-warning badge-pill"
              >TRAIN</span
            >
            <span
              v-if="data.item.test_group"
              class="badge badge-info badge-pill"
              >TEST</span
            >
          </template>

          <template v-slot:cell(image)="data">
            <VZoomImage
              :id="data.item.id"
              :image="data.item.thumb_img_path"
              :thumb="data.item.thumb_img_path"
              :name="'Image'"
            />
          </template>

          <template v-slot:cell(action)="data">
            <router-link v-bind:to="/group/ + data.item.id" target="_blank">
              <b-button
                class="mr-1"
                variant="outline-primary"
                v-b-tooltip.hover
                title="Detail"
              >
                <i class="fal fa-search"></i>
              </b-button>
            </router-link>

            <span v-if="group_id">
              <b-button
                class="mr-1"
                variant="outline-success"
                v-b-tooltip.hover
                v-on:click="mergeGroup(data.item.id)"
                title="Merge group"
              >
                <i class="fas fa-plus"></i>
              </b-button>
            </span>

            <span v-if="image_id">
              <b-button
                class="mr-1"
                variant="outline-success"
                v-b-tooltip.hover
                v-on:click="addImage(data.item.id)"
                title="Add image to group"
              >
                <i class="fas fa-plus"></i>
              </b-button>
            </span>

            <span v-if="detection">
              <b-button
                class="mr-1"
                variant="outline-success"
                v-b-tooltip.hover
                v-on:click="addObject(data.item.id)"
                title="Add object to group"
              >
                <i class="fas fa-plus"></i>
              </b-button>
            </span>
            <span v-if="job">
              <b-button
                variant="success"
                v-show="data.item.selected"
                v-on:click="unselect(data.item)"
                ><i class="fal fa-check-square fa-lg"></i
              ></b-button>
              <b-button
                variant="outline-success"
                v-show="!data.item.selected"
                v-on:click="select(data.item)"
                ><i class="fal fa-square fa-lg"></i
              ></b-button>
            </span>
          </template>
        </b-table>

        <b-pagination
          v-if="!loading"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          size="sm"
          hide-goto-end-buttons
        />
        <h6>Number of items: {{ totalRows }}</h6>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-undef */

import axios from "axios";
import { mapState } from "vuex";
import VLoading from "@/components/VLoading.vue";
import VGroupCreate from "@/components/create/VGroupCreate.vue";
import { DataLoadedEnum } from "../../constants";
import VZoomImage from "@/components/utils/VZoomImage.vue";
import VClipCopy from "../utils/VClipCopy.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VGroups",
  components: {
    VGroupCreate,
    VZoomImage,
    VClipCopy
  },
  props: {
    image_id: String,
    detection: String,
    group_id: String,
    job: Object,
    task: Object
  },
  computed: {
    ...mapState(["similarity_types", "workspace", "data_loaded"])
  },
  data() {
    return {
      test: false,
      train: false,
      empty: false,
      present: false,
      options: [
        { name: "All", icon: "fal fa-ellipsis-v-alt" },
        { name: "Verified", icon: "fal fa-clipboard-check" },
        { name: "Not Verified", icon: "fal fa-clipboard" }
      ],
      count: null,
      count_options: [
        { text: "-", value: null },
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3+", value: "3" }
      ],
      verified: "All",
      group_name: "",
      similarity_type: null,
      fields: ["name", "type", "test_group", "image", "action"],
      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      loading: true,
      isBusy: false,
      groups: [],
      selected_groups: [],
      select_all: false,
      select_query: false,
      mainProps: {
        height: 40,
        width: 40
      }
    };
  },
  methods: {
    copyToClipBoard: function(id) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    async mergeGroup(group_id) {
      axios
        .post(
          API_URL +
            "/similarity/training/v2/group/" +
            this.group_id +
            "/merge-groups?workspace=" +
            this.workspace,
          { groups: [group_id] }
        )
        .then(
          response => {
            this.loadGroups({ page: this.currentPage });
            this.$eventBus.$emit("group-id-refresh-" + this.group_id, null);
          },
          error => {
            console.log("danger", error.response["data"]);
            this.$bvToast.toast(error.response["data"], {
              title: `Message`,
              variant: "warning",
              toaster: "b-toaster-bottom-right",
              solid: true
            });
          }
        );
    },
    async addImage(group_id) {
      axios
        .post(
          API_URL +
            "/similarity/training/v2/group/" +
            group_id +
            "/add-images?workspace=" +
            this.workspace,
          { images: [this.image_id] }
        )
        .then(
          response => {
            console.log("emiting", "image-group-id-refresh-" + this.image_id);
            this.$eventBus.$emit(
              "image-group-id-refresh-" + this.image_id,
              null
            );
          },
          error => {
            console.log("danger", error.response["data"]);
          }
        );
    },
    async addObject(group_id) {
      axios
        .post(
          API_URL +
            "/similarity/training/v2/group/" +
            group_id +
            "/add-objects?workspace=" +
            this.workspace,
          { objects: [this.detection.id] }
        )
        .then(
          response => {
            console.log(
              "emiting",
              "image-group-id-refresh-" + this.detection.id
            );
            this.$eventBus.$emit(
              "image-group-id-refresh-" + this.detection.id,
              null
            );
          },
          error => {
            console.log("danger", error.response["data"]);
          }
        );
    },
    createGroup() {
      this.$bvModal.show("similarity-group-create");
    },
    async loadGroups({ page = 1, type = null, clear = false } = {}) {
      this.loading = true;

      let test = "";
      let train = "";

      if (this.test) {
        test = "&test=true";
      } else {
        if (this.train) {
          train = "&test=false";
        }
      }

      let empty = "";
      if (this.empty) {
        empty = "&empty=true";
      }

      let stype = "";
      if (this.similarity_type) {
        stype = "&type=" + this.similarity_type;
      }

      let group_name = "";
      if (this.group_name) {
        group_name = "&search=" + this.group_name;
      }

      let present = "";
      if (this.job) {
        present = "&similarityjob=" + this.job.id;
        present += this.present ? "" : "&not_in=True";
      }

      let count = "";
      if (this.count) {
        count = "&count=" + this.count;
      }

      let verified = "";
      if (this.verified && this.verified != "All") {
        verified = this.verified == "Verified" ? "&verified=1" : "&verified=0";
      }

      let response = await axios.get(
        API_URL +
          "/similarity/training/v2/group/?page_size=" +
          this.perPage +
          "&page=" +
          page +
          test +
          train +
          empty +
          stype +
          count +
          group_name +
          present +
          verified +
          "&workspace=" +
          this.workspace
      );

      let next = response.data["next"];
      let groups = response.data["results"];
      this.totalRows = response.data["count"];

      for (let i = 0; i < groups.length; i++) {
        if (this.group_id && this.group_id == groups[i]["id"]) {
          groups[i]["_rowVariant"] = "success";
        }

        if (type) {
          this.selected_groups.push(groups[i]["id"]);
        } else if (this.selected_groups.includes(groups[i]["id"])) {
          groups[i]["selected"] = true;
        } else {
          groups[i]["selected"] = false;
        }
      }

      if (type) {
        if (type == "ADD_GROUPS" && this.job) {
          await this.crud("add");
        } else if (this.job) {
          await this.crud("remove");
        }

        this.clearItems();

        console.log("Next page", next, page);

        if (this.job || this.task) {
          this.selected_groups = [];
          page = 0;
        }

        if (next) {
          await this.loadGroups({ page: (page = page + 1), type: type });
        } else {
          await this.loadGroups({ page: (page = page + 1), type: null });
          console.log("loading false 1");
        }
      } else {
        console.log("loading false 2");
        this.groups = groups;
        this.isBusy = false;
        this.loading = false;
        this.refresh();
      }
    },
    async remove() {
      console.log("REMOVE");
      this.isBusy = true;
      if (this.select_query) {
        await this.loadGroups({ page: 1, type: "REMOVE_GROUPS" });
        console.log("FINISH Removing");
      } else {
        this.crud("remove");
        this.refresh();
        this.clearItems();
        this.loadGroups();
        console.log("FINISH REMOVING 2");
      }
    },
    async add() {
      console.log("ADD");
      this.isBusy = true;
      if (this.select_query) {
        await this.loadGroups({ page: 1, type: "ADD_GROUPS" });
        console.log("FINISH ADDING");
      } else {
        this.crud("add");
        this.refresh();
        this.clearItems();
        this.loadGroups();
        console.log("FINISH ADDING 2");
      }
    },
    async crud(type) {
      let add_items = this.selected_groups;
      console.log(type + " items", add_items);
      await axios
        .post(
          API_URL +
            "/annotate/v2/similarityjob/" +
            this.job.id +
            "/" +
            type +
            "-items?workspace=" +
            this.workspace,
          { items: add_items }
        )
        .then(response => {}, error => {});
    },
    selectQuery: function() {
      this.selectAll();
      this.select_query = true;
      this.select_all = true;
    },
    refresh: function(e) {
      if (this.job) {
        this.$emit("refresh");
      }
    },
    selectAll() {
      for (let i = 0; i < this.groups.length; i++) {
        this.select(this.groups[i]);
      }
      this.select_all = true;
    },
    clearItems() {
      for (let i = 0; i < this.groups.length; i++) {
        this.unselect(this.groups[i]);
      }

      this.select_all = false;
      this.select_query = false;
      this.selected_groups = [];
    },
    select(group) {
      let k = this.groups.findIndex(o => o.id === group["id"]);
      let ngroup = JSON.parse(JSON.stringify(group));
      ngroup["selected"] = true;

      this.$set(this.groups, k, ngroup);
      this.selected_groups.push(ngroup["id"]);
    },
    unselect(group) {
      let k = this.groups.findIndex(o => o.id === group["id"]);
      let ngroup = JSON.parse(JSON.stringify(group));
      ngroup["selected"] = false;
      this.$set(this.groups, k, ngroup);

      let index = this.selected_groups.indexOf(ngroup["id"]);
      this.selected_groups.splice(index, 1);
    },
    getTypeName(type) {
      let item = this.similarity_types.find(function(element) {
        return element.id == type;
      });
      if (item) {
        return item.name;
      } else {
        return "Unknown";
      }
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadGroups();
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.images = [];
        this.loadGroups();
      }
    },
    created() {
      this.loadGroups();
      this.clearItems();
    },
    currentPage() {
      this.loadGroups({ page: this.currentPage });
    }
  }
};
</script>

<style scoped>
.fa-check-square {
  color: green;
}
</style>
