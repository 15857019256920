<template>
  <b-modal :ref="title + '-modal'" hide-footer :title="'Remove ' + title">
    <div class="d-block text-center">
      <h5>
        Are you sure you want to delete selected {{ title }}
        <b class="text-danger" v-if="name">'{{ name }}'</b>?!
      </h5>
    </div>
    <b-row>
      <b-col>
        <b-button class="mt-3" variant="outline-danger" block @click="remove()">
          <i class="fal fa-trash-alt"></i> Remove
        </b-button>
      </b-col>
      <b-col>
        <b-button class="mt-3" variant="outline-warning" block @click="hide()"
          >Cancel</b-button
        >
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  props: {
    title: String,
    item_to_remove: Object
  },
  methods: {
    remove() {
      this.$emit("remove", { id: this.item_to_remove.id });
      this.$emit("hide");
      this.$refs[this.title + "-modal"].hide();
    },
    hide() {
      this.$refs[this.title + "-modal"].hide();
      this.$emit("hide");
    }
  },
  data() {
    return {
      name: ""
    };
  },
  watch: {
    item_to_remove: function() {
      if (this.item_to_remove) {
        this.$refs[this.title + "-modal"].show();
        if (this.item_to_remove.hasOwnProperty("name")) {
          this.name = this.item_to_remove.name;
        }
      }
    }
  }
};
</script>

<style></style>
