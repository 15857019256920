<template>
  <div class="container-fluid mt-3" style="height: 90%; overflow-y: auto;">
    <b-table
      class="mt-3"
      :outlined="outlined"
      :hover="hover"
      :items="evaluations"
      :fields="fields_task"
      :busy="loading"
      caption-top
    >
      <template v-slot:cell(id)="data">
        Evaluation
        <span
          v-b-popover.hover="'copy Task ID to clipboard'"
          v-on:click="copyToClipBoard(data.item.id)"
        >
          <i class="fal fa-copy"></i>
        </span>
      </template>

      <template v-slot:cell(action)="data">
        <b-button
          v-on:click="showEvaluation(data.item)"
          class="mr-2"
          variant="outline-primary"
          v-b-tooltip.hover
          title="Detail"
        >
          <i class="fal fa-eye"></i>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import { mapState } from "vuex";
import { DataLoadedEnum } from "../constants";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "evaluations",
  components: {},
  computed: {
    ...mapState(["workspace", "data_loaded"])
  },
  data() {
    return {
      loading: true,
      outlined: true,
      hover: true,
      evaluations: [],
      fields_task: ["id", "created", "action"]
    };
  },
  methods: {
    copyToClipBoard: function(id) {
      console.log("copyying to", id);
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    showEvaluation: function(item) {
      this.$router.push("/evaluation/" + item.id);
    },
    async loadEvaluations(label) {
      const response = await axios.get(
        API_URL + "/annotate/v2/evaluation?workspace=" + this.workspace
      );

      this.evaluations = response["data"]["results"];
      this.loading = false;
      console.log("response load evaluations", this.evaluations);
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loadEvaluations();
      }
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadEvaluations();
    }
  }
};
</script>

<style scoped>
.fa-check-square {
  color: green;
}
</style>
