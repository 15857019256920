<template>
  <span>
    <span v-if="task">
      <br />
      <span @mouseover="hover = true" @mouseleave="hover = false">
        <span
          v-if="task.type == 'multi_class'"
          :id="'tooltip-task-' + task.id"
          v-b-tooltip.hover
        >
          <i class="fal fa-folder-tree"></i>
          {{ task.name }}
          <b-tooltip delay="1000" :target="'tooltip-task-' + task.id"
            >Categorization: {{ task.description }}</b-tooltip
          >
        </span>

        <span
          v-if="task.type == 'multi_label'"
          :id="'tooltip-task-' + task.id"
          v-b-tooltip.hover
        >
          <i class="fal fa-tags"></i>
          {{ task.name }}
          <b-tooltip delay="1000" :target="'tooltip-task-' + task.id"
            >Tagging: {{ task.description }}</b-tooltip
          >
        </span>

        <span
          v-if="task.type == 'regression'"
          :id="'tooltip-task-' + task.id"
          v-b-tooltip.hover
        >
          <i class="fal fa-signal-alt"></i>
          {{ task.name }}
          <b-tooltip delay="1000" :target="'tooltip-task-' + task.id"
            >Regression: {{ task.description }}</b-tooltip
          >
        </span>

        <span
          :id="'tooltip-predict-' + task.id"
          v-b-tooltip.hover
          v-show="hover && predicting == 0"
          v-on:dblclick="recognize()"
        >
          <i class="fal fa-cloud-upload"></i>
          <b-tooltip delay="0" :target="'tooltip-predict-' + task.id"
            >Double Click to Predict</b-tooltip
          >
        </span>
        <span v-show="predicting == 1">
          <i class="fas fa-circle-notch fa-spin"></i>
        </span>
        <span v-show="predicting == 2" style="color: green;">
          <i class="fad fa-check"></i>
        </span>
      </span>

      <div>
        <span v-if="task.type != 'regression'">
          <span v-for="label in sortArrays(task.labels)" v-bind:key="label.id">
            <b-button
              :id="'tooltip-label-' + label.id"
              v-if="!label.present"
              variant="outline-success"
              size="sm"
              class="mr-1 mt-1"
              v-on:click="addTagImage(label)"
            >
              <i v-if="label.type == 'category'" class="fal fa-folder-tree"></i>
              <i v-else class="fal fa-tag"></i>
              {{ label.name }}
            </b-button>
            <b-button
              :id="'tooltip-label-' + label.id"
              v-else-if="label.present"
              :variant="label.type == 'value' ? 'info' : 'success'"
              size="sm"
              class="mr-1 mt-1"
              v-on:click="removeTagImage(label)"
            >
              <i v-if="label.type == 'category'" class="fal fa-folder-tree"></i>
              <i v-else class="fal fa-tag"></i>
              {{ label.name }}
            </b-button>
            <b-tooltip
              v-if="label.description"
              delay="1000"
              :target="'tooltip-label-' + label.id"
              triggers="hover"
            >
              {{ label.description }}
            </b-tooltip>
          </span>
          <span v-if="something_wrong">
            <b-button
              :id="'tooltip-label-wrong'"
              class="mr-1 mt-1"
              size="sm"
              variant="danger"
            >
              <i class="fal fa-exclamation-triangle"></i>
            </b-button>
            <b-tooltip
              delay="100"
              :target="'tooltip-label-wrong'"
              triggers="hover"
            >
              Please select only one categor (Categorization Task) or disable
              Negative-Tag (Tagging Task).
            </b-tooltip>
          </span>
        </span>
        <span v-else>
          <span v-for="label in sortArrays(task.labels)" v-bind:key="label.id">
            <div>
              <b-row
                ><b-col>
                  <b-button
                    :id="'tooltip-label-' + label.id"
                    v-if="!label.present"
                    variant="outline-success"
                    size="sm"
                    class="mr-1 mt-1"
                    v-on:click="addTagImage(label)"
                  >
                    {{ label.name }}
                  </b-button>
                  <b-button
                    :id="'tooltip-label-' + label.id"
                    v-if="label.present"
                    :variant="label.type == 'value' ? 'info' : 'success'"
                    size="sm"
                    class="mr-1 mt-1"
                    v-on:click="removeTagImage(label)"
                  >
                    {{ label.name }}
                  </b-button> </b-col
                ><b-col
                  ><b-form-input
                    class="float-right"
                    @change="changeValue($event, label)"
                    style="width: 100px"
                    v-if="label.present"
                    v-model="label.value"
                    placeholder="Value"
                  ></b-form-input></b-col
              ></b-row>
              <b-form-input
                v-if="label.present"
                id="range-1"
                v-model="label.value"
                type="range"
                @change="changeValue($event, label)"
                :step="getLabelById(label.id).step_value"
                :min="getLabelById(label.id).min_value"
                :max="getLabelById(label.id).max_value"
              ></b-form-input>
            </div>
          </span>
        </span>
      </div>
    </span>
  </span>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";

let API_URL = process.env.VUE_APP_API_URL;
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// let config = {
//   baseURL: "https://api.ximilar.com/",
//   timeout: 10000,
//   headers: {
//     Authorization: "Token " //where applicable
//   }
// };
// let axiosdev = axios.create(config);

export default {
  name: "VRecognitionAction",
  components: {},
  props: {
    action: Object,
    image: Object,
    detection: Object,
    task_id: String,
    present_labels: []
  },
  data() {
    return {
      task: null,
      predicting: 0,
      hover: false
    };
  },
  computed: {
    ...mapState(["tasks", "labels"]),
    ...mapGetters(["getTaskById", "getLabelById"]),
    endpoint: function() {
      if (this.detection) {
        return "/detection/v2/object/";
      }
      return "/recognition/v2/training-image/";
    },
    entity_id: function() {
      if (this.detection) {
        return this.detection.id;
      }
      return this.image.id;
    },
    something_wrong: function() {
      if (this.task.type == "multi_class") {
        let value = this.task.labels.reduce(this.presentTag, 0);
        if (value > 1) {
          return true;
        } else {
          return false;
        }
      } else if (this.task.type == "regression") {
        return false;
      } else {
        let value = this.task.labels.reduce(this.presentTag, 0);
        if (value > 1) {
          let tag = this.task.labels.find(element => element.negative_for_task);
          if (tag.present) {
            return true;
          }
        }
        return false;
      }
    }
  },
  filters: {},
  methods: {
    presentTag(total, value) {
      if (value.present) {
        return total + 1;
      }
      return total;
    },
    sortArrays(arrays) {
      return _.orderBy(arrays, [item => item.name.toLowerCase()], "asc");
    },
    create_data_req(label) {
      console.log("create data req", label);
      if (label.type == "value") {
        if (label.value != null) {
          return { label_id: label.id, value: label.value };
        } else {
          console.log("Adding label", this.getLabelById(label.id));
          return {
            label_id: label.id,
            value: this.getLabelById(label.id)["min_value"]
          };
        }
      } else {
        return { label_id: label.id };
      }
    },
    async changeValue(event, label) {
      console.log("Change ...", label);
      let result = await axios
        .post(
          API_URL + this.endpoint + this.entity_id + "/update-value",
          this.create_data_req(label)
        )
        .catch(error => error);

      if (this.detection) {
        // here must be image.id
        this.$eventBus.$emit(
          "change-labelvalue-detection-" + this.image.id,
          label
        );
      } else {
        this.$eventBus.$emit(
          "change-labelvalue-image-" + this.entity_id,
          label
        );
      }
    },
    async addTagImage(label, attempt = 0) {
      this.clearTags(label);

      if (!label.present) {
        let result = await axios
          .post(
            API_URL + this.endpoint + this.entity_id + "/add-label",
            this.create_data_req(label)
          )
          .catch(error => error);

        if (result["status"] != 200 && attempt < 2) {
          await sleep(500 * (attempt + 1));
          this.addTagImage(label, attempt + 1);
          return;
        } else if (result["status"] != 200) {
          return;
        }
        label.present = true;

        if (label.type == "value") {
          console.log("Adding value:", label);
          if (label.value == null) {
            label.value = this.getLabelById(label.id)["min_value"];
          }
        }

        this.$eventBus.$emit("change-recognition-label-" + label.id, {
          label: label.id,
          type: "add"
        });

        this.$eventBus.$emit(
          "switch-add-label-image-task-" + this.entity_id + "-" + this.task_id,
          label.id
        );

        // send it to VImage.vue or VDetection.vue
        if (this.detection) {
          // here must be image.id
          this.$eventBus.$emit("add-label-detection-" + this.image.id, label);
        } else {
          this.$eventBus.$emit("add-label-image-" + this.entity_id, label);
        }
      }
      this.$forceUpdate();
    },
    async removeTagImage(label, top = true, attempt = 0) {
      label.present = false;
      if (top) {
        this.clearTags(label);
      }

      let result = await axios
        .post(
          API_URL + this.endpoint + this.entity_id + "/remove-label",
          this.create_data_req(label)
        )
        .catch(error => error);

      if (result["status"] != 200 && attempt < 2) {
        await sleep(500 * (attempt + 1));
        this.removeTagImage(label, top, attempt + 1);
        return;
      } else if (result["status"] != 200) {
        return;
      }

      this.$eventBus.$emit("change-recognition-label-" + label.id, {
        label: label.id,
        type: "remove"
      });

      // turn of the branch in switch action
      this.$eventBus.$emit(
        "switch-remove-label-image-task-" + this.entity_id + "-" + this.task_id,
        label.id
      );

      if (this.detection) {
        // here must be image.id
        this.$eventBus.$emit("del-label-detection-" + this.image.id, label.id);
      } else {
        this.$eventBus.$emit("del-label-image-" + this.entity_id, label.id);
      }
      this.$forceUpdate();
    },
    clearTags: function(label) {
      if (this.task.type == "multi_class" || label.negative_for_task) {
        this.task.labels.forEach(element => {
          if (element.present) {
            this.removeTagImage(element, false, 0);
          }
        });
      } else if (this.task.type == "multi_label" && !label.negative_for_task) {
        this.task.labels.forEach(element => {
          if (element.present && element.negative_for_task) {
            this.removeTagImage(element, false, 0);
          }
        });
      }
    },
    clearAllTags: function() {
      this.task.labels.forEach(element => {
        if (element.present) {
          this.removeTagImage(element, false, 0);
        }
      });
    },
    testBestLabel: function() {
      var choiceIndex = Math.floor(Math.random() * this.task.labels.length);
      return this.task.labels[choiceIndex];
    },
    testLabels: function() {
      let labels = Object.assign([], this.task.labels);
      var choiceIndex = Math.floor(Math.random() * labels.length);
      labels[choiceIndex]["prob"] = 1.0;
      return labels;
    },
    remove() {
      console.log("clearing tags: ", this.task.name);
      this.clearAllTags();
    },
    async recognize() {
      console.log("recognizing", this.task.name);
      console.log("image", this.image);
      console.log("object", this.detection);

      let data = {
        records: [
          {
            _url: this.image.img_path
          }
        ],
        task_id: this.task_id
      };

      if (this.detection) {
        data["records"][0]["_objects"] = [
          { bound_box: this.detection.label_data.data }
        ];
        data["records"][0]["_extract_object"] = true;
      }

      this.predicting = 1;
      let result = null;
      this.clearAllTags();

      try {
        result = await axios.post(API_URL + "/recognition/v2/classify/", data);
        console.log("Complete:", result);
      } catch (error) {
        this.predicting = 0;
        return;
      }

      if (
        "records" in result["data"] &&
        "labels" in result["data"]["records"][0]
      ) {
        if (this.task.type == "regression") {
          let label_r = result["data"]["records"][0]["labels"][0];
          console.log("result:", label_r);
          let label_obj = this.task.labels.find(
            element => element.id == label_r["id"]
          );
          label_obj["value"] = label_r["value"];
          this.addTagImage(label_obj);
          label_obj["present"] = true;
          this.changeValue(null, label_obj);
          this.$forceUpdate();
        } else if (this.task.type == "multi_class") {
          let best_label = result["data"]["records"][0]["best_label"];
          //let found = this.testBestLabel();
          let found = this.task.labels.find(
            element => element.id == best_label.id
          );

          if (found) {
            this.addTagImage(found, false);
          }
        } else {
          let labels = result["data"]["records"][0]["labels"]; //this.testLabels();

          // todo negative???
          for (let label of labels) {
            if (label.prob > 0.5) {
              let found = this.task.labels.find(
                element => element.id == label.id
              );
              if (found) {
                this.addTagImage(found, false);
              }
            }
          }
        }
      }
      this.predicting = 2;
    },
    fill_image_tags: function(task, i) {
      for (let k = 0; k < this.image.labels.length; k++) {
        if (this.image.labels[k]["id"] == task.labels[i]["id"]) {
          task.labels[i]["present"] = true;
          if (task.labels[i]["type"] == "value") {
            task.labels[i]["value"] = this.image.labels[k]["value"];
            console.log(
              "ADDed value",
              task.labels[i]["value"],
              "to",
              i,
              "from",
              k
            );
          } else {
            task.labels[i]["value"] = null;
          }
          this.$eventBus.$emit(
            "switch-add-label-image-task-" +
              this.entity_id +
              "-" +
              this.task_id,
            this.image.labels[k]["id"]
          );
        }
      }
    },
    fill_detection_tags: function(task, i) {
      for (
        let k = 0;
        k < this.detection.label_data.recognition_labels.length;
        k++
      ) {
        if (
          this.detection.label_data.recognition_labels[k].id ==
          task.labels[i].id
        ) {
          if (
            this.detection.label_data.recognition_labels[k]["type"] == "value"
          ) {
            console.log(
              "Adding value:",
              this.detection.label_data.recognition_labels[k]["value"]
            );
            task.labels[i][
              "value"
            ] = this.detection.label_data.recognition_labels[k]["value"];
          } else {
            task.labels[i]["value"] = null;
          }

          task.labels[i]["present"] = true;
          this.$eventBus.$emit(
            "switch-add-label-image-task-" +
              this.entity_id +
              "-" +
              this.task_id,
            this.detection.label_data.recognition_labels[k].id
          );
        }
      }
    },
    removeTagFromPresent(label_id) {
      let found = this.task.labels.find(element => element.id == label_id);

      if (found) {
        found.present = false;
      }
      this.$forceUpdate();
    },
    labelChanged(data) {
      console.log("LABEL CHANGED", data.label);
      let found = this.task.labels.find(element => element.id == data.label);
      if (found) {
        if (data.type == "add") {
          found.present = true;
        } else if (data.type == "remove") {
          found.present = false;
        }
      }
      // ! its hack and nasty but we need to recompute this
      this.$forceCompute("something_wrong");
    }
  },
  mounted: function() {
    // fill the labels with present
    let task = Object.assign({}, this.getTaskById(this.task_id));

    for (let i = 0; i < task.labels.length; i++) {
      task.labels[i]["present"] = false;
      task.labels[i]["value"] = 0.0;

      console.log("PRESENT", task.labels[i]);
      if (this.detection) {
        this.fill_detection_tags(task, i);
      } else {
        this.fill_image_tags(task, i);
      }
      this.$eventBus.$on(
        "change-recognition-label-" + task.labels[i].id,
        this.labelChanged
      );
    }

    this.task = task;
    console.log("TASK", this.task);
    this.$eventBus.$on(
      "switch-clear-recognition-task-" + this.task_id,
      this.remove
    );

    // calling predict or remove
    if (this.action) {
      this.$eventBus.$on("call-remove-action-" + this.action.id, this.remove);
    }

    // this can be called from VPresentLabels.vue
    this.$eventBus.$on(
      "del-label-detection-present-" + this.image.id,
      this.removeTagFromPresent
    );
    this.$eventBus.$on(
      "del-label-image-present-" + this.image.id,
      this.removeTagFromPresent
    );
  },
  created() {},
  beforeDestroy() {
    for (let i = 0; i < this.task.labels.length; i++) {
      this.$eventBus.$off("change-recognition-label-" + this.task.labels[i].id);
    }
    this.$eventBus.$off("switch-clear-recognition-task-" + this.task_id);
    this.$eventBus.$off("del-label-detection-present-" + this.image.id);
    this.$eventBus.$off("del-label-image-present-" + this.image.id);

    if (this.action) {
      this.$eventBus.$off("call-remove-action-" + this.action.id);
    }
  }
};
</script>

<style></style>
