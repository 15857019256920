<template>
  <b-modal
    id="type-task-create"
    ref="modal"
    v-bind:title="title"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="Id"
        :state="nameState"
        label-for="name-input"
        v-if="id"
      >
        <b-form-input
          disabled
          id="input-live"
          v-model="id"
          aria-describedby="input-live-help input-live-feedback"
          placeholder=""
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Name" :state="nameState" label-for="name-input">
        <b-form-input
          id="input-live"
          v-model="name"
          :state="nameState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Enter name of task"
          trim
        ></b-form-input>

        <b-form-invalid-feedback id="input-live-feedback"
          >Enter at least 2 letters</b-form-invalid-feedback
        >

        <b-form-text
          v-if="valid_message"
          class="text-success"
          id="input-live-help"
        >
          <p class="text-success">{{ valid_message }}</p>
        </b-form-text>
        <b-form-text
          v-if="error_message"
          class="text-danger"
          id="input-live-help"
        >
          <p class="text-danger">{{ error_message }}</p>
        </b-form-text>
      </b-form-group>
      <b-form-group
        v-if="
          (type == 'detection' || type == 'similarity') &&
            task &&
            training_params
        "
        :label="'Image size: ' + image_size"
        :state="imageSize"
        label-for="name-input"
      >
        <b-form-input
          id="range-1"
          v-model="image_size"
          type="range"
          step="128"
          min="384"
          max="1024"
        ></b-form-input>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VTaskCreate",
  components: {},
  props: {
    task: Object,
    type: String
  },
  data() {
    return {
      id: "",
      name: "",
      title: "Create New Task",
      error_message: "",
      valid_message: "",
      training_params: null,
      image_size: 512,
      endpoint: {
        recognition: "recognition",
        detection: "detection",
        segmentation: "segmentation",
        similarity: "similarity/training"
      }
    };
  },
  computed: {
    ...mapState(["workspace"]),
    nameState() {
      if (this.name) {
        return this.name.length > 1 ? true : false;
      }
      return false;
    },
    imageSize() {
      if (this.image_size < 224) {
        return false;
      }
      return true;
    }
  },
  methods: {
    checkFormValidity() {
      return [this.nameState].every(item => item);
    },
    resetModal() {
      this.error_message = "";
      this.valid_message = "";
    },
    handleOk(bvModalEvt) {
      // prevent modal from closing
      bvModalEvt.preventDefault();

      // trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.valid_message = "";
      this.error_message = "";

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      if (this.task) {
        this.updateTask();
        return;
      }
      this.createTask();
    },
    createTask() {
      this.$store
        .dispatch("create_" + this.type + "_task", {
          name: this.name,
          workspace: this.workspace
        })
        .then(() => {
          this.valid_message = "Task was successfuly created!";
          this.error_message = "";
          this.$bvModal.hide("type-task-create");
        })
        .catch(err => {
          this.error_message = "Unable to create task!";
          this.valid_message = "";
        });
    },
    updateTask() {
      let data = { name: this.name };

      console.log(this.training_params);
      if (this.type == "detection" || this.type == "similarity") {
        this.training_params[this.type + "-model"]["input_size"] = parseInt(
          this.image_size
        );
        data["training_params"] = this.training_params;
      }

      axios
        .put(
          API_URL +
            "/" +
            this.endpoint[this.type] +
            "/v2/task/" +
            this.task.id +
            "?workspace=" +
            this.workspace,
          data
        )
        .then(
          response => {
            this.$store.dispatch("update_" + this.type + "_task", {
              name: this.name,
              id: this.task.id
            });
            this.valid_message = "Updated!";
          },
          error => {
            this.error_message = "Unable to update task!";
          }
        );
    },
    loadTask() {
      axios
        .get(
          API_URL +
            "/" +
            this.endpoint[this.type] +
            "/v2/task/" +
            this.task.id +
            "?workspace=" +
            this.workspace
        )
        .then(
          response => {
            console.log(this.training_params);
            this.training_params = response["data"]["training_params"];
            this.image_size = this.training_params[this.type + "-model"][
              "input_size"
            ];
          },
          error => {}
        );
    }
  },
  watch: {
    task() {
      this.error_message = "";
      this.valid_message = "";

      if (this.task) {
        this.loadTask();
        this.title = "Update " + this.type + " task";
        this.name = this.task.name;
        this.id = this.task.id;
      } else {
        this.title = "Create new " + this.type + " task";
        this.name = "";
        this.id = "";
      }
    }
  }
};
</script>

<style></style>
