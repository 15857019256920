<template>
  <div class="mt-3">
    <b-card>
      <div>
        <b-card-text>
          <b v-if="verified.length == 0">No verifications for this image!</b>
          <span v-else>
            <h4 v-if="verified.length != 0">Verifications</h4>

            <ul class="list-group mt-3">
              <li
                v-for="verify in verified"
                :key="verify.id"
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <span>
                  <i class="fal fa-user"></i>
                  {{ verify.user.username }}
                </span>
                <span class="badge badge-primary badge-pill">
                  {{ verify.created | parseDate }}
                </span>
                <span
                  class="badge badge-danger badge-pill"
                  v-on:click="removeVerify(verify)"
                  >Remove</span
                >
              </li>
            </ul>
          </span>
        </b-card-text>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "vverify",
  props: {
    verified: Array,
    image_workspace: String
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["user_id", "workspace"])
  },
  filters: {
    parseDate: function(value) {
      if (!value) return "";
      return value.substring(0, 10);
    }
  },
  methods: {
    removeVerify(verify) {
      axios
        .delete(
          API_URL +
            "/annotate/v2/verification/" +
            verify.id +
            "?workspace=" +
            this.image_workspace
        )
        .then(
          response => {
            this.$emit("refreshVerify");
          },
          error => {}
        );
    }
  },
  mounted: function() {}
};
</script>

<style></style>
