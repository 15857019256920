<template>
  <div class="container-fluid mt-3" style="height: 90%; overflow-y: auto;">
    <span v-if="label && !loading">
      <b-card>
        <b-card-title>
          <b-row>
            <b-col>Label {{ label.name }} </b-col>
          </b-row>
        </b-card-title>
        <b-card-sub-title>
          TYPE: {{ label.type }} ID: {{ label.id }}
          <div>
            <b-row
              ><b-column><div class="m-3">Filter:</div></b-column
              ><b-column
                ><v-select
                  style="width: 250px;"
                  class="m-1"
                  :options="labels"
                  v-model="selected_label"
                  placeholder="Image Contains labels"
                  label="name"
                >
                  <template
                    v-b-tooltip.hover
                    :title="option.description"
                    slot="option"
                    slot-scope="option"
                  >
                    <span
                      style="width: 100%"
                      v-b-tooltip.hover
                      :id="'option-task-' + option.id"
                      :title="option.description"
                      >{{ option.name }}</span
                    >
                  </template>
                </v-select></b-column
              >
            </b-row>
          </div>
        </b-card-sub-title>
        <b-card-text class="mt-2">
          <div class="mt-2" v-if="label.type == 'value'">
            <p></p>
            <chart class="mt-2" :options="top_bar" ref="bar" autoresize />
            <p></p>

            <b-form-group
              label="Bin size (default is label.step_value):"
              label-for="input-default"
              class="mb-0"
            >
              <b-form-input
                id="input-default"
                style="width:250px;"
                @change="loadHistogram"
                v-model="label.step_value"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-card-text>
      </b-card>
    </span>
    <span>
      <VLoading v-bind:loading="loading" />
    </span>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { DataLoadedEnum } from "../constants";
let API_URL = process.env.VUE_APP_API_URL;
import VLoading from "@/components/VLoading.vue";

import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";

//this.$route.params.id
export default {
  name: "label",
  components: {
    chart: ECharts,
    VLoading
  },
  computed: {
    ...mapState(["data_loaded", "workspace", "labels"])
  },
  data() {
    return {
      label: null,
      loading: true,
      histogram: null,
      selected_label: null,
      top_bar: {
        title: {
          text: "Histogram of values",
          subtext: "Distribution of values (y) per object and image (x, count)"
        },

        theme: "olivia-green",
        legend: {},
        tooltip: {},
        xAxis: { type: "category", data: [] },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        series: [
          {
            name: "Objects",
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series"
            },
            itemStyle: {
              color: "#007bff"
            },

            data: []
          },
          {
            name: "Images",
            itemStyle: {
              color: "#FF8400"
            },
            type: "bar",
            stack: "Ad",
            emphasis: {
              focus: "series"
            },
            data: []
          }
        ]
      }
    };
  },
  methods: {
    async loadLabel() {
      console.log("loading abel....");
      let response = await axios
        .get(API_URL + "/recognition/v2/label/" + this.$route.params.id)
        .catch(error => {
          this.loading = false;
          this.error = true;
        });
      console.log(response.data);
      this.label = response.data;

      if (this.workspace != response.data["workspace"]) {
        await this.$store.dispatch("changeWorkspace", {
          workspace: response.data["workspace"]
        });
      }

      if (this.label.type == "value") {
        this.loadHistogram();
      }
      this.loading = false;
    },
    async loadHistogram() {
      this.loading = true;
      let data = { bin_size: this.label.step_value };

      if (this.selected_label) {
        data["label_id"] = this.selected_label.id;
      }

      let response = await axios
        .post(
          API_URL +
            "/recognition/v2/label/" +
            this.$route.params.id +
            "/get-histogram",
          data
        )
        .catch(error => {
          this.loading = false;
          this.error = true;
        });
      this.histogram = response.data;
      console.log(this.histogram);

      this.top_bar.xAxis["data"] = [];
      for (let k = 0; k < this.histogram["bins"].length; k += 1) {
        this.top_bar.xAxis["data"].push(this.histogram["bins"][k]);
      }

      this.top_bar.series[0]["data"] = [];
      this.top_bar.series[1]["data"] = [];

      for (let k = 0; k < this.histogram["bins"].length; k += 1) {
        this.top_bar.series[0]["data"].push(
          this.histogram["counts_objects"][k]
        );
        this.top_bar.series[1]["data"].push(this.histogram["counts_images"][k]);
      }
      this.loading = false;
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loadLabel();
      }
    },
    selected_label() {
      console.log("change label");
      this.loadHistogram();
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadLabel();
    }
  }
};
</script>

<style></style>
