<template>
  <div v-if="object">
    <router-link v-bind:to="/image/ + object.image.id">
      <img
        class="card-img-top rounded"
        style="height: 150px;"
        v-bind:src="object.thumb"
        alt="Card image cap"
      />
    </router-link>

    <div
      class="card-img-overlay rounded-top"
      style="height: 35%; background: rgba(0,0,0,0.3);"
    >
      <span
        v-if="checkable"
        v-on:click="selectImage()"
        class="float-right mt-0"
      >
        <span v-show="object.selected" class="icon-green">
          <i class="fal fa-check-square fa-lg"></i>
        </span>
        <span v-show="!object.selected" class="icon-green">
          <i class="fal fa-square fa-lg"></i>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //selected: false
    };
  },
  props: {
    object: Object,
    checkable: Boolean
  },
  computed: {},
  methods: {
    selectImage: function() {
      if (!this.object.selected) {
        console.log("selectImage");
        this.$emit("selectImage", { id: this.object.id });
      } else {
        console.log("unselectImage");
        this.$emit("unselectImage", { id: this.object.id });
      }
    }
  }
};
</script>

<style scoped>
.icon-green {
  color: #5cb85c;
}
.icon-white {
  color: white;
}
</style>
