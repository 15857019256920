<template>
  <div class="container-fluid mt-3" style="height: 90%; ">
    <h4>All top labels</h4>
    <chart :options="top_bar" ref="bar" theme="ovilia-green" autoresize />

    <b-form-select
      v-model="selected"
      variant="outline-primary"
      text="Pick top label"
      class="m-2"
      @change="selected_top_label"
    >
      <!-- This slot appears above the options from 'options' prop -->
      <template v-slot:first>
        <option :value="null" disabled>-- Please select top label --</option>
      </template>

      <!-- These options will appear after the ones from 'options' prop -->
      <option
        v-for="top_label in sortedLabels"
        v-bind:key="top_label.id"
        :value="top_label.id"
        >{{ top_label.name }}</option
      >
    </b-form-select>

    <chart
      v-show="selected"
      :options="sub_bar"
      ref="bar"
      theme="ovilia-green"
      autoresize
    />
  </div>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import _ from "lodash";
import { mapState } from "vuex";
import { DataLoadedEnum } from "../constants";

import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/map";
import "echarts/lib/chart/radar";
import "echarts/lib/chart/scatter";
import "echarts/lib/chart/effectScatter";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/polar";
import "echarts/lib/component/geo";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/visualMap";
import "echarts/lib/component/dataset";
import "echarts/map/js/world";

let API_URL = process.env.VUE_APP_API_URL;
let data = [];

for (let i = 0; i <= 360; i++) {
  let t = (i / 180) * Math.PI;
  let r = Math.sin(2 * t) * Math.cos(2 * t);
  data.push([r, i]);
}

function randomize() {
  return [0, 0, 0].map(v => {
    return Math.round(300 + Math.random() * 700) / 10;
  });
}

export default {
  name: "evaluation",
  components: {
    chart: ECharts
  },
  computed: {
    ...mapState(["workspace", "data_loaded"]),
    sortedLabels: function() {
      if (this.evaluation != null) {
        return _.orderBy(this.evaluation.result.top_labels, "name");
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      loading: true,
      outlined: true,
      hover: true,
      evaluation: null,
      fields_task: ["id", "created", "action"],
      selected: null,
      top_bar: {
        legend: {
          selected: { Accuracy: true, "Haming Loss": true, Count: false }
        },
        tooltip: {},
        dataset: {
          // Provide data.
          source: [["Label", "Accuracy", "Haming Loss", "Count"]]
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: { type: "category" },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [{ type: "bar" }, { type: "bar" }, { type: "bar" }]
      },
      sub_bar: {
        legend: { selected: { Precision: true, Recall: true, Count: false } },
        tooltip: {},
        dataset: {
          // Provide data.
          source: [["Label", "Accuracy", "Haming Loss", "Count"]]
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: { type: "category" },
        // Declare Y axis, which is a value axis.
        yAxis: {},
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [{ type: "bar" }, { type: "bar" }, { type: "bar" }]
      }
    };
  },
  methods: {
    selected_top_label() {
      let item = this.evaluation["result"]["top_labels"].find(
        element => element.id == this.selected
      );
      this.sub_bar.dataset["source"] = [
        ["Label", "Precision", "Recall", "Count"]
      ];
      item["labels"].forEach(element => {
        this.sub_bar.dataset["source"].push([
          element.name,
          element.precision,
          element.recall,
          element.count
        ]);
      });
    },
    async loadEvaluation(label) {
      const response = await axios.get(
        API_URL +
          "/annotate/v2/evaluation/" +
          this.$route.params.id +
          "?workspace=" +
          this.workspace
      );

      this.evaluation = response["data"];

      this.sortedLabels.forEach(element => {
        this.top_bar.dataset["source"].push([
          element.name,
          element.accuracy,
          element.hamming_loss,
          element.count
        ]);
      });
      this.loading = false;
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loadEvaluation();
      }
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadEvaluation();
    }
  }
};
</script>

<style scoped>
.fa-check-square {
  color: green;
}
.echarts {
  width: 100%;
  height: 40%;
}
</style>
