<template>
  <div>
    <VImage v-bind:image_id="$route.params.id" v-bind:key="$route.params.id" />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import VImage from "@/components/image/VImage.vue";

//this.$route.params.id
export default {
  name: "image",
  components: {
    VImage
  },
  data() {
    return {};
  },
  methods: {},
  mounted: function() {}
};
</script>

<style></style>
