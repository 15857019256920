<template>
  <div>
    <b-card
      title="Jobs"
      :sub-title="
        'Current Annotation Jobs for Workspace \'' + actualWorkspaceName + '\''
      "
    >
      <b-card-text>
        <span v-if="jobs.length">
          <div class="mt-3" v-for="job in jobs" v-bind:key="job.id">
            <b-row>
              <b-col>{{ job.name }}</b-col>
              <b-col>
                <!--<router-link v-bind:to="/imagejob/ + job.id">-->
                <b-button
                  class="float-right"
                  variant="outline-primary"
                  v-on:click="start_work(job)"
                  size="sm"
                  >Start Work</b-button
                >
                <!--</router-link>-->
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col>
                <b-progress
                  :max="job.item_count"
                  :variant="
                    getProgressVariant(job.item_count, job.item_done_count)
                  "
                  show-progress
                  animated
                >
                  <b-progress-bar :value="job.item_done_count">
                    <strong
                      >{{ job.item_done_count }} / {{ job.item_count }}</strong
                    >
                  </b-progress-bar>
                </b-progress>
              </b-col>
            </b-row>
          </div>
        </span>
        <span v-else>
          <b>No jobs for you! Enjoy free time!</b>
        </span>
      </b-card-text>
      <div class="mt-3">
        <b-row style="color: black;">
          <b-col>
            <h4>{{ jobs.length }}</h4>
          </b-col>
          <b-col>
            <span class="float-right">
              <i class="fal fa-shovel-snow fa-2x"></i>
            </span>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

let API_URL = process.env.VUE_APP_API_URL;

function compare(a, b) {
  // Use toUpperCase() to ignore character casing
  const aname = a.name.toUpperCase();
  const bname = b.name.toUpperCase();

  let comparison = 0;
  if (aname > bname) {
    comparison = 1;
  } else if (aname < bname) {
    comparison = -1;
  }
  return comparison;
}

export default {
  name: "VJobCard",
  components: {},
  props: {},
  data() {
    return {
      counter: 45,
      max: 100,
      jobs: []
    };
  },
  computed: {
    ...mapState(["data_loaded", "workspace", "workspaces"]),
    actualWorkspaceName: function() {
      let workspace = this.workspaces.find(
        function(element) {
          return element.id == this.workspace;
        }.bind(this)
      );

      if (workspace) {
        return workspace.name;
      } else {
        return "";
      }
    }
  },
  methods: {
    start_work(job) {
      this.$store.dispatch("clear_job_items");
      if (job.type == "image") {
        this.$store.dispatch("update_last_image", { image: null });
        this.$router.push("/imagejob/" + job.id);
      } else {
        this.$router.push("/similarityjob/" + job.id);
      }
    },
    getProgressVariant(value, value2) {
      if (value != null && value2 != null) {
        if (value == value2) {
          return "success";
        }
      }
      return "primary";
    },
    async loadJobs() {
      this.jobs = []; // clean the storage
      await this.loadJobsType("image");
      await this.loadJobsType("similarity");
    },
    async loadJobsType(type) {
      let jobs = await axios.get(
        API_URL + "/annotate/v2/" + type + "job/?workspace=" + this.workspace
      );
      jobs = jobs.data["results"];
      jobs.sort(compare);

      for (let i = 0; i < jobs.length; i++) {
        await this.loadJob(jobs[i].id, type);
      }
      this.loading = false;
    },
    async loadJob(id, type) {
      return new Promise((resolve, reject) => {
        return axios.get(API_URL + "/annotate/v2/" + type + "job/" + id).then(
          response => {
            response.data["type"] = type;
            this.jobs.push(response.data);
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
      });
    }
  },
  mounted: function() {
    this.loadJobs();
  },
  watch: {
    workspace() {
      this.loadJobs();
    }
  }
};
</script>

<style></style>
