<template>
  <div id="application">
    <div id="wrapper" class="d-flex" v-if="isLoggedIn" style="height:100vh">
      <!-- SIDEBAR -->
      <VSideBar />

      <!-- PAGE CONTENT -->
      <div id="page-content-wrapper">
        <!-- HEADER -->
        <VHeader />

        <!-- CONTENT -->
        <div style="height: 100%">
          <VNoResource />
          <router-view :key="$route.fullPath" />
        </div>
      </div>
    </div>

    <!-- LOGIN PAGE -->
    <div v-if="!isLoggedIn">
      <router-view />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import VHeader from "@/components/VHeader.vue";
import VSideBar from "@/components/VSideBar.vue";
import VNoResource from "@/components/VNoResource.vue";

export default {
  name: "home",
  components: {
    VHeader,
    VSideBar,
    VNoResource
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    }
  }
};
</script>

<style>
#application {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  overflow-x: hidden;
  overflow-y: hidden;
}

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: 0rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
}
</style>
