<template>
  <div class="row">
    <div class="mx-auto">
      <span v-show="loading">
        <i class="fal fa-spinner-third fa-spin fa-3x"></i>
      </span>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    loading: Boolean
  }
};
</script>

<style></style>
