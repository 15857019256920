<template>
  <div class="container-fluid mt-3" style="height:100%;">
    <VLoading v-bind:loading="loading" />
    <VTaskCreate />
    <div
      v-if="!loading"
      style="height: 90%; overflow-y: auto; overflow-x: hidden;"
    >
      <b-row>
        <b-col>
          <VSmallCard
            v-if="getCheckResource(ResourceEnum.RECOGNITION)"
            title="Recognition"
            sub_title="Recognize categories and tags on images."
            color="Dodgerblue"
            icon="fal fa-file-image fa-2x"
            :number_1="total['verified'] + ' / ' + total['images']"
            :number_2="'' + labels.length"
            :number_3="'' + tasks.length"
            :text_1="'images (verified vs non verified)'"
            :text_2="'labels'"
            :text_3="'tasks'"
            :link_app="'https://app.ximilar.com'"
            :footer="true"
          />
          <VJobCard class="mt-3" />
          <VApiTokenCard class="mt-3" />
        </b-col>
        <b-col>
          <VSmallCard
            v-if="getCheckResource(ResourceEnum.DETECTION)"
            title="Detection"
            sub_title="Detect objects with bounding boxes on images."
            color="MEDIUMSEAGREEN"
            icon="fal fa-draw-square fa-2x"
            :number_1="'' + total['objects']"
            :number_2="'' + detection_labels.length"
            :number_3="'' + detection_tasks.length"
            :text_1="'objects'"
            :text_2="'labels'"
            :text_3="'tasks'"
            :link="'/detection'"
            :footer="true"
          />
          <VSmallCard
            class="mt-3"
            v-if="getCheckResource(ResourceEnum.SIMILARITY)"
            title="SIMILARITY"
            sub_title="Create image similarity model."
            color="Mediumslateblue"
            icon="fal fa-photo-video fa-2x"
            :number_1="'' + total['groups']"
            :number_2="'' + similarity_types.length"
            :number_3="'' + similarity_tasks.length"
            :text_1="'groups'"
            :text_2="'types'"
            :text_3="'tasks'"
            :link="'/groups'"
            :footer="true"
          />
          <VSmallCard
            class="mt-3"
            v-if="getCheckResource(ResourceEnum.SEGMENTATION)"
            title="Segmentation"
            sub_title="Segment masks on images."
            color="Crimson"
            icon="fal fa-draw-polygon fa-2x"
            :number_1="'' + total['polygons']"
            :number_2="'' + segmentation_labels.length"
            :number_3="'' + segmentation_tasks.length"
            :text_1="'polygons'"
            :text_2="'labels'"
            :text_3="'tasks'"
            :link="'/segmentation'"
            :footer="true"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import VLoading from "@/components/VLoading.vue";
import VTaskCreate from "@/components/create/VTaskCreate.vue";
import VSmallCard from "@/components/dashboard/VSmallCard.vue";
import VApiTokenCard from "@/components/dashboard/VApiTokenCard.vue";
import VJobCard from "@/components/dashboard/VJobCard.vue";
import { DataLoadedEnum, ResourceEnum } from "../constants";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "dashboard",
  components: {
    VLoading,
    VTaskCreate,
    VSmallCard,
    VApiTokenCard,
    VJobCard
  },
  computed: {
    ...mapState([
      "tasks",
      "labels",
      "detection_labels",
      "detection_tasks",
      "segmentation_labels",
      "segmentation_tasks",
      "similarity_types",
      "similarity_tasks",
      "workspace",
      "data_loaded",
      "api_token"
    ]),
    ...mapGetters(["getActiveWorkspace", "checkResource"])
  },
  data() {
    return {
      ResourceEnum: ResourceEnum,
      not_verified: false,
      loading: true,
      total: { verified: 0, images: 0, objects: 0, polygons: 0, groups: 0 }
    };
  },
  methods: {
    getCheckResource(resource) {
      return this.checkResource(resource);
    },
    async loadImagesCount(verified) {
      let verification = verified ? "&verified=0" : "";
      return new Promise((resolve, reject) => {
        axios
          .get(
            API_URL +
              "/recognition/v2/training-image/?workspace=" +
              this.workspace +
              verification
          )
          .then(
            response => {
              this.images = response.data["results"];
              if (verified) {
                this.total["verified"] =
                  this.total["images"] - response.data["count"];
              } else {
                this.total["images"] = response.data["count"];
                this.loadImagesCount(true);
              }
              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    },
    loadObjectsCount: function(url_suffix, key, service) {
      if (!this.getCheckResource(service)) {
        return;
      }

      axios.get(API_URL + url_suffix + this.workspace).then(
        response => {
          this.total[key] = response.data["count"];
        },
        error => {}
      );
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadImagesCount(false);
      this.loadObjectsCount(
        "/detection/v2/object/?workspace=",
        "objects",
        ResourceEnum.DETECTION
      );
      this.loadObjectsCount(
        "/segmentation/v2/polygon/?workspace=",
        "polygons",
        ResourceEnum.SEGMENTATION
      );
      this.loadObjectsCount(
        "/similarity/training/v2/group/?workspace=",
        "groups",
        ResourceEnum.SIMILARITY
      );
      this.loading = false;
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loadImagesCount(false);
        this.loadObjectsCount(
          "/detection/v2/object/?workspace=",
          "objects",
          ResourceEnum.DETECTION
        );
        this.loadObjectsCount(
          "/segmentation/v2/polygon/?workspace=",
          "polygons",
          ResourceEnum.SEGMENTATION
        );
        this.loadObjectsCount(
          "/similarity/training/v2/group/?workspace=",
          "groups",
          ResourceEnum.SIMILARITY
        );
        this.loading = false;
      } else {
        this.loading = true;
      }
    }
  }
};
</script>

<style scoped>
.fa-check-square {
  color: green;
}
</style>
