<template>
  <div>
    <VGroupCreate
      :key="'create-group-1'"
      v-if="group"
      v-bind:group="group"
      v-bind:component_id="'create-group-1'"
    />
    <VLoading v-bind:loading="loading" />
    <VJobToolbar v-if="job" v-bind:job="job" class="mb-3" />
    <div v-if="group">
      <b-row>
        <b-col>
          <b-card>
            <b-card-title>
              <b-row>
                <b-col
                  >Similarity group
                  <VClipCopy
                    :from="'vgroupm'"
                    v-bind:uuid="group.id"
                    v-bind:name="'Group'"
                    v-bind:key="'group-' + group.id"
                  />
                </b-col>
                <b-col>
                  <span class="float-right">
                    <span>
                      <span v-show="verified.length == 0">
                        <b-button
                          class="m-1"
                          v-on:click="verifyGroup()"
                          variant="outline-success"
                          v-b-tooltip.hover
                          title="Verify Group"
                        >
                          Verify Group
                        </b-button>
                      </span>
                      <span v-show="verified.length > 0">
                        <b-button
                          class="m-1"
                          v-on:click="unverifyGroup()"
                          variant="success"
                          v-b-tooltip.hover
                          title="Verified"
                        >
                          Verified
                        </b-button>
                      </span>
                    </span>

                    <b-button
                      class="m-1"
                      v-on:click="saveGroup()"
                      variant="outline-success"
                      v-b-tooltip.hover
                      title="Save group"
                    >
                      <i class="fal fa-save"></i>
                    </b-button>

                    <b-button
                      class="m-1"
                      v-on:click="loadGroup()"
                      variant="outline-primary"
                      v-b-tooltip.hover
                      title="Refresh group"
                    >
                      <i class="fal fa-sync-alt"></i>
                    </b-button>

                    <b-button
                      class="m-1"
                      v-on:click="removeGroup()"
                      variant="outline-danger"
                      v-b-tooltip.hover
                      title="Move"
                    >
                      <i class="fal fa-trash-alt"></i>
                    </b-button>

                    <b-button
                      v-if="move_items == 0"
                      variant="outline-warning"
                      v-on:click="set_move(1)"
                      v-b-tooltip.hover
                      title="Move items"
                    >
                      <i class="fal fa-layer-group"></i>
                    </b-button>

                    <b-button
                      v-if="move_items == 1"
                      variant="warning"
                      v-on:click="set_move(2)"
                      v-b-tooltip.hover
                      title="Create new group and move items"
                    >
                      <i class="fal fa-layer-group"></i>
                    </b-button>

                    <VUpload
                      class="ml-1"
                      v-bind:group_id="group_id"
                      v-bind:key="'group-' + group_id"
                    />
                  </span>
                </b-col>
              </b-row>
            </b-card-title>
            <b-card-sub-title> TYPE: {{ group.type.name }} </b-card-sub-title>

            <b-card-text class="mt-2">
              Type:
              <b-form-select
                v-model="group.type.id"
                value-field="id"
                text-field="name"
                :options="similarity_types"
                @change="onTypeChange($event)"
              ></b-form-select>
              Name:
              <b-form-input v-model="group.name"></b-form-input> Description:
              <b-form-input v-model="group.description"></b-form-input>
            </b-card-text>
            <b-card-text>
              <span
                v-on:click="changeTestFlag('mark-test')"
                v-if="!group.test_group"
                value-field="item"
                text-field="name"
                class="badge badge-warning badge-pill"
                >TRAIN GROUP</span
              >
              <span
                v-on:click="changeTestFlag('unmark-test')"
                v-if="group.test_group"
                class="badge badge-info badge-pill"
                >TEST GROUP</span
              >
            </b-card-text>
          </b-card>
          <span v-if="group">
            <b-card
              class="mt-3"
              v-if="
                group.groups.length == 0 &&
                  group.images.length == 0 &&
                  group.objects.length == 0
              "
            >
              <b-card-text>
                <b>This group is empty!</b>
              </b-card-text>
            </b-card>
            <b-card class="mt-3" v-if="group.groups.length > 0">
              <b-card-title>Groups ({{ group.groups.length }})</b-card-title>
              <b-card-sub-title
                >This group '<b>{{ group.name }}</b
                >' contains following groups:</b-card-sub-title
              >
              <b-card-text>
                <b-table
                  v-if="group.groups.length > 0"
                  :items="group.groups"
                  :fields="group_fields"
                  class="mt-3"
                  outlined
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template v-slot:cell(name)="data">
                    {{ data.item.name }}
                    <VClipCopy
                      :from="'vgroupd'"
                      v-bind:uuid="data.item.id"
                      v-bind:name="'Group'"
                      v-bind:key="'group-' + data.item.id"
                    />
                  </template>

                  <template v-slot:cell(type)="data">
                    {{ getTypeName(data.item.type) }}
                  </template>

                  <template v-slot:cell(test_group)="data">
                    <span
                      v-if="!data.item.test_group"
                      class="badge badge-warning badge-pill"
                      >TRAIN</span
                    >
                    <span
                      v-if="data.item.test_group"
                      class="badge badge-info badge-pill"
                      >TEST</span
                    >
                  </template>

                  <template v-slot:cell(action)="data">
                    <router-link
                      v-bind:to="/group/ + data.item.id"
                      target="_blank"
                    >
                      <b-button
                        class="mr-1"
                        variant="outline-primary"
                        v-b-tooltip.hover
                        title="Detail"
                      >
                        <i class="fal fa-search"></i>
                      </b-button>
                    </router-link>

                    <b-button
                      class="mr-1"
                      variant="outline-danger"
                      v-b-tooltip.hover
                      v-on:click="removeFromGroup(data.item.id, 'groups')"
                      title="Remove group from this group"
                    >
                      <i class="fal fa-trash"></i>
                    </b-button>
                  </template>

                  <template v-slot:cell(select)="data">
                    <b-button
                      variant="success"
                      v-show="data.item.selected"
                      v-on:click="unselect(data.item)"
                      ><i class="fal fa-check-square fa-lg"></i
                    ></b-button>
                    <b-button
                      variant="outline-success"
                      v-show="!data.item.selected"
                      v-on:click="select(data.item)"
                      ><i class="fal fa-square fa-lg"></i
                    ></b-button>
                  </template>
                </b-table>
              </b-card-text>
            </b-card>
          </span>

          <span v-if="group">
            <b-card class="mt-3">
              <b-card-title>Thumbnails</b-card-title>
              <!-- if no images or objects in group -->
              <b-card-text
                v-if="group.images.length == 0 || group.objects.length == 0"
              >
                <b>This group has no images!</b>
              </b-card-text>
              <carousel
                :per-page="5"
                :navigate-to="someLocalProperty"
                :mouse-drag="true"
                :scroll-per-page="true"
              >
                <slide
                  v-for="img in group.images"
                  v-bind:key="'thumb-' + img.id"
                >
                  <router-link v-bind:to="/image/ + img.id" target="_blank">
                    <b-img
                      :id="id"
                      style="height:120px; width: 120px;"
                      rounded
                      :src="img.thumb_img_path"
                    ></b-img>
                  </router-link>
                </slide>
                <slide
                  v-for="obj in group.objects"
                  v-bind:key="'thumb-' + obj.id"
                >
                  <router-link
                    v-bind:to="/image/ + obj.image.id"
                    target="_blank"
                  >
                    <b-img
                      :id="id"
                      style="height:120px; width: 120px;"
                      rounded
                      :src="obj.thumb"
                    ></b-img>
                  </router-link>
                </slide>
              </carousel>
            </b-card>
            <b-card class="mt-3" v-if="group.images.length > 0">
              <b-card-title>Images ({{ group.images.length }})</b-card-title>
              <b-card-sub-title
                >This group '<b>{{ group.name }}</b
                >' contains following images:</b-card-sub-title
              >
              <b-card-text>
                <b-table
                  v-if="group.images.length > 0"
                  :items="group.images"
                  :fields="image_fields"
                  class="mt-3"
                  outlined
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template v-slot:cell(image)="data">
                    <VZoomImage
                      :id="data.item.id"
                      :image="data.item.img_path"
                      :thumb="data.item.thumb_img_path"
                      :name="'Image'"
                    />
                  </template>

                  <template v-slot:cell(test_image)="data">
                    <span
                      v-if="!data.item.test_image"
                      class="badge badge-warning badge-pill"
                      v-on:click="changeFlag('mark-test', data.item.id)"
                      >TRAIN</span
                    >
                    <span
                      v-if="data.item.test_image"
                      class="badge badge-info badge-pill"
                      v-on:click="changeFlag('unmark-test', data.item.id)"
                      >TEST</span
                    >
                  </template>

                  <template v-slot:cell(real)="data">
                    <span
                      v-if="data.item.real"
                      v-on:click="changeFlag('unmark-real', data.item.id)"
                      class="badge badge-warning badge-pill"
                      >REAL</span
                    >
                    <span
                      v-if="!data.item.real"
                      v-on:click="changeFlag('mark-real', data.item.id)"
                      class="badge badge-info badge-pill"
                      >PRODUCT</span
                    >
                  </template>

                  <template v-slot:cell(action)="data">
                    <router-link
                      v-bind:to="/image/ + data.item.id"
                      target="_blank"
                    >
                      <b-button
                        class="mr-1"
                        variant="outline-primary"
                        v-b-tooltip.hover
                        title="Detail"
                      >
                        <i class="fal fa-search"></i>
                      </b-button>
                    </router-link>

                    <b-button
                      class="mr-1"
                      variant="outline-danger"
                      v-b-tooltip.hover
                      v-on:click="removeFromGroup(data.item.id, 'images')"
                      title="Remove image from this group"
                    >
                      <i class="fal fa-trash"></i>
                    </b-button>
                  </template>

                  <template v-slot:cell(select)="data" v-if="move_items == 1">
                    <b-button
                      variant="success"
                      v-show="data.item.selected"
                      v-on:click="unselect(data.item)"
                      ><i class="fal fa-check-square fa-lg"></i
                    ></b-button>
                    <b-button
                      variant="outline-success"
                      v-show="!data.item.selected"
                      v-on:click="select(data.item)"
                      ><i class="fal fa-square fa-lg"></i
                    ></b-button>
                  </template>
                </b-table>
              </b-card-text>
            </b-card>

            <b-card class="mt-3" v-if="group.objects.length > 0">
              <b-card-title>Objects ({{ group.objects.length }})</b-card-title>
              <b-card-sub-title
                >This group '<b>{{ group.name }}</b
                >' contains following detection objects:</b-card-sub-title
              >
              <b-card-text>
                <b-table
                  v-if="group.objects.length > 0"
                  :items="group.objects"
                  :fields="image_fields"
                  class="mt-3"
                  outlined
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template v-slot:cell(image)="data">
                    <VZoomImage
                      :id="data.item.id"
                      :image="data.item.thumb"
                      :thumb="data.item.thumb"
                      :name="'Object'"
                    />
                  </template>

                  <template v-slot:cell(test_image)="data">
                    <span
                      v-if="!data.item.image.test_image"
                      class="badge badge-warning badge-pill"
                      v-on:click="changeFlag('mark-test', data.item.image.id)"
                      >TRAIN</span
                    >
                    <span
                      v-if="data.item.image.test_image"
                      class="badge badge-info badge-pill"
                      v-on:click="changeFlag('unmark-test', data.item.image.id)"
                      >TEST</span
                    >
                  </template>

                  <template v-slot:cell(real)="data">
                    <span
                      v-if="data.item.image.real"
                      class="badge badge-warning badge-pill"
                      v-on:click="changeFlag('unmark-real', data.item.image.id)"
                      >REAL</span
                    >
                    <span
                      v-if="!data.item.image.real"
                      class="badge badge-info badge-pill"
                      v-on:click="changeFlag('mark-real', data.item.image.id)"
                      >PRODUCT</span
                    >
                  </template>

                  <template v-slot:cell(action)="data">
                    <router-link
                      v-bind:to="/image/ + data.item.image.id"
                      target="_blank"
                    >
                      <b-button
                        class="mr-1"
                        variant="outline-primary"
                        v-b-tooltip.hover
                        title="Detail"
                      >
                        <i class="fal fa-search"></i>
                      </b-button>
                    </router-link>

                    <b-button
                      class="mr-1"
                      variant="outline-danger"
                      v-b-tooltip.hover
                      v-on:click="removeFromGroup(data.item.id, 'objects')"
                      title="Remove image from this group"
                    >
                      <i class="fal fa-trash"></i>
                    </b-button>
                  </template>

                  <template v-slot:cell(select)="data" v-if="move_items == 1">
                    <b-button
                      variant="success"
                      v-show="data.item.selected"
                      v-on:click="unselect(data.item)"
                      ><i class="fal fa-check-square fa-lg"></i
                    ></b-button>
                    <b-button
                      variant="outline-success"
                      v-show="!data.item.selected"
                      v-on:click="select(data.item)"
                      ><i class="fal fa-square fa-lg"></i
                    ></b-button>
                  </template>
                </b-table>
              </b-card-text>
            </b-card>
            <b-card class="mt-3">
              <b-card-text>
                <b v-if="groups.length == 0">No group contains this group!</b>
                <span v-else>
                  <h4 v-if="groups.length != 0">
                    Groups that contain this group
                  </h4>

                  <b-table
                    :items="groups"
                    :fields="group_fields"
                    class="mt-3"
                    outlined
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(name)="data">
                      {{ data.item.name }}

                      <VClipCopy
                        :from="'vgroupg'"
                        v-bind:uuid="data.item.id"
                        v-bind:name="'Group'"
                        v-bind:key="'group-' + data.item.id"
                      />
                    </template>

                    <template v-slot:cell(type)="data">
                      {{ getTypeName(data.item.type) }}
                    </template>

                    <template v-slot:cell(test_group)="data">
                      <span
                        v-if="!data.item.test_group"
                        class="badge badge-warning badge-pill"
                        >TRAIN</span
                      >
                      <span
                        v-if="data.item.test_group"
                        class="badge badge-info badge-pill"
                        >TEST</span
                      >
                    </template>

                    <template v-slot:cell(action)="data">
                      <router-link
                        v-bind:to="/group/ + data.item.id"
                        target="_blank"
                      >
                        <b-button
                          class="mr-1"
                          variant="outline-primary"
                          v-b-tooltip.hover
                          title="Detail"
                        >
                          <i class="fal fa-search"></i>
                        </b-button>
                      </router-link>
                    </template>

                    <template v-slot:cell(select)="data">
                      <b-button
                        variant="success"
                        v-show="data.item.selected"
                        v-on:click="unselect(data.item)"
                        ><i class="fal fa-check-square fa-lg"></i
                      ></b-button>
                      <b-button
                        variant="outline-success"
                        v-show="!data.item.selected"
                        v-on:click="select(data.item)"
                        ><i class="fal fa-square fa-lg"></i
                      ></b-button>
                    </template>
                  </b-table>
                </span>
                <b-alert variant="success" class="mt-3" v-if="msg_remove" show
                  >Successfuly removed!</b-alert
                >
              </b-card-text>
            </b-card>
          </span>
        </b-col>
        <b-col>
          <b-tabs content-class="mt-3">
            <b-tab title="Meta">
              <JSONEditor
                style="height: 200px;"
                :options="options"
                ref="editor"
                :onChange="onChange"
                :json="group.meta_data"
              />
            </b-tab>
            <b-tab title="Image"
              ><p>
                <VImageGroup
                  v-bind:text="'Add image to the group'"
                  v-bind:placeholder="'image'"
                  v-bind:field="'images'"
                  v-bind:operation="'add'"
                  v-bind:image="null"
                  v-bind:group="group"
                /></p
            ></b-tab>
            <b-tab title="Object"
              ><p>
                <VImageGroup
                  class="mt-3"
                  v-bind:text="'Add object to the group'"
                  v-bind:field="'objects'"
                  v-bind:operation="'add'"
                  v-bind:placeholder="'object'"
                  v-bind:image="null"
                  v-bind:detection_object="null"
                  v-bind:group="group"
                /></p
            ></b-tab>
            <b-tab title="Group"
              ><p>
                <VImageGroup
                  class="mt-3"
                  v-bind:text="'Add group to the group'"
                  v-bind:field="'groups'"
                  v-bind:operation="'add'"
                  v-bind:placeholder="'group'"
                  v-bind:image="null"
                  v-bind:group="group"
                /></p
            ></b-tab>
            <b-tab title="Merge"
              ><p>
                <VImageGroup
                  class="mt-3"
                  v-bind:text="'Merge group to the group'"
                  v-bind:field="'groups'"
                  v-bind:operation="'merge'"
                  v-bind:placeholder="'group'"
                  v-bind:image="null"
                  v-bind:group="group"
                /></p
            ></b-tab>
          </b-tabs>

          <VGroups class="mt-3" v-bind:group_id="group_id" />
        </b-col>
      </b-row>
    </div>
    <div v-if="error">
      <b-container class="bv-example-row mt-3">
        <b-row>
          <b-col>
            <b-card class="container">
              <b-card-body>
                <b>Group not found!</b>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { DataLoadedEnum } from "../../constants";
import VImageGroup from "@/components/image/VImageGroup.vue";
import VLoading from "@/components/VLoading.vue";
import VGroups from "@/components/similarity/VGroups.vue";
import VJobToolbar from "@/components/image/VJobToolbar.vue";
import JSONEditor from "vue2-jsoneditor";
import VGroupCreate from "@/components/create/VGroupCreate.vue";
import VUpload from "@/components/image/VUpload.vue";
import VZoomImage from "@/components/utils/VZoomImage.vue";
import VClipCopy from "../utils/VClipCopy.vue";
import { Carousel, Slide } from "vue-carousel";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VGroup",
  components: {
    Carousel,
    Slide,
    JSONEditor,
    VJobToolbar,
    VGroupCreate,
    VImageGroup,
    VLoading,
    VUpload,
    VClipCopy,
    VGroups,
    VZoomImage
  },
  data() {
    return {
      group: null,
      groups: [],
      loading: true,
      group_fields: ["name", "type", "test_group", "action"],
      image_fields: ["image", "test_image", "real", "action"],
      error: false,
      move_items: 0,
      msg_remove: false,
      verified: [],
      options: {
        mode: "tree",
        modes: ["text", "tree", "view"],
        search: true,
        colorPicker: true,
        history: true
      }
    };
  },
  props: {
    job: Object,
    group_id: String
  },
  computed: {
    ...mapState(["data_loaded", "similarity_types", "user_id", "workspace"])
  },
  methods: {
    copyToClipBoard: function(id) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    async onTypeChange(event) {
      console.log("Change stype:", event);
      let response = await axios
        .put(API_URL + "/similarity/training/v2/group/" + this.group_id, {
          type: event
        })
        .catch(error => {
          this.loading = false;
          this.error = true;
        });

      await this.loadGroup();
    },
    getTypeName(type) {
      let item = this.similarity_types.find(function(element) {
        return element.id == type;
      });
      if (item) {
        return item.name;
      } else {
        return "Unknown";
      }
    },
    async changeFlag(flag, item_id) {
      let data = {};
      data["images"] = [item_id];
      data[flag] = true;

      let response = await axios
        .post(
          API_URL +
            "/recognition/v2/training-image/update?workspace=" +
            this.workspace,
          data
        )
        .catch(error => {
          this.loading = false;
          this.error = true;
        });

      await this.loadGroup();
    },
    async select(item) {
      item.selected = true;
    },
    async unselect(item) {
      item.selected = false;
    },
    async unverifyGroup() {
      if (this.verified.length > 0) {
        for (let k = 0; k < this.verified.length; k++) {
          await axios
            .delete(
              API_URL +
                "/annotate/v2/similarity-verification/" +
                this.verified[k].id +
                "?workspace=" +
                this.group.type.workspace
            )
            .then(response => {}, error => {});
        }
        this.verified = [];
      }
    },
    set_move(level) {
      this.move_items = level;
      if (level == 0) {
        this.image_fields = this.image_fields.filter(item => item !== "select");
        this.group_fields = this.group_fields.filter(item => item !== "select");
      } else if (level == 1) {
        this.image_fields.push("select");
        this.group_fields.push("select");
      } else {
        this.$bvModal.show("create-group-1");
      }
    },
    async verifyGroup() {
      await axios
        .post(API_URL + "/annotate/v2/similarity-verification/", {
          user: this.user_id,
          group_id: this.group_id
        })
        .then(
          response => {
            if (!this.job) {
              this.loadVerify();
            }
          },
          error => {}
        );
    },
    async loadVerify() {
      let response_v = await axios
        .get(
          API_URL +
            "/annotate/v2/similarity-verification/?group=" +
            this.group_id +
            "&workspace=" +
            this.workspace
        )
        .catch(error => {
          this.loading = false;
          this.error = true;
        });

      if (response_v.data.results.length > 0) {
        this.verified = response_v.data.results;
      }
    },
    async removeGroup() {
      let response = await axios
        .delete(API_URL + "/similarity/training/v2/group/" + this.group.id)
        .catch(error => {
          this.loading = false;
          this.error = true;
        });
      this.group = null;
      this.error = true;
    },
    async saveGroup() {
      let response = await axios
        .put(API_URL + "/similarity/training/v2/group/" + this.group_id, {
          name: this.group.name,
          description: this.group.description,
          type: this.group.type.id
        })
        .catch(error => {
          this.loading = false;
          this.error = true;
        });
    },
    async loadGroup() {
      console.log("LoadGroup...");
      let response = await axios
        .get(API_URL + "/similarity/training/v2/group/" + this.group_id)
        .catch(error => {
          this.loading = false;
          this.error = true;
        });

      await this.loadVerify();
      await this.loadGroups();

      // switch workspace
      if (this.workspace != response.data["type"]["workspace"]) {
        await this.$store.dispatch("changeWorkspace", {
          workspace: response.data["type"]["workspace"]
        });
      }

      response.data["objects"] = response.data["detection_objects"];
      this.fillSelected(response.data, "objects");
      this.fillSelected(response.data, "images");
      this.fillSelected(response.data, "groups");

      if (response) {
        this.group = response.data;
        this.loading = false;
      } else {
        this.group = null;
        this.error = true;
        this.loading = false;
      }
    },
    fillSelected(group, field) {
      let items = group[field];
      for (let index = 0; index < items.length; index++) {
        group[field][index]["selected"] = false;
      }
    },
    async loadGroups() {
      this.groups = [];

      return new Promise((resolve, reject) => {
        axios
          .get(
            API_URL +
              "/similarity/training/v2/group/?workspace=" +
              this.workspace +
              "&group=" +
              this.group_id
          )
          .then(
            response => {
              this.groups = response.data["results"];
              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    },
    onChange(newJson) {
      axios
        .patch(API_URL + "/similarity/training/v2/group/" + this.group.id, {
          meta_data: newJson
        })
        .then(function(response) {}, function(error) {});
      this.group.meta_data = newJson;
    },
    async changeTestFlag(flag) {
      let data = {};
      data["groups"] = [this.group.id];
      data[flag] = true;

      let response = await axios
        .post(API_URL + "/similarity/training/v2/group/update", data)
        .catch(error => {
          this.loading = false;
          this.error = true;
        });
      this.loadGroup();
    },
    moveToNewGroup(group_id) {
      if (group_id == null) {
        this.set_move(0);
        return;
      }

      let fields = ["images", "objects", "groups"];

      for (let fieldi = 0; fieldi < fields.length; fieldi++) {
        let field = fields[fieldi];
        for (let index = 0; index < this.group[field].length; index++) {
          if (this.group[field][index]["selected"]) {
            this.addEntityToGroup(
              group_id,
              this.group[field][index].id,
              field,
              "add"
            );
            this.addEntityToGroup(
              this.group_id,
              this.group[field][index].id,
              field,
              "remove"
            );
          }
        }
      }
      this.set_move(0);
      this.loadGroup();
    },
    async addEntityToGroup(group_id, entity_id, field, operation) {
      let data = {};
      data[field] = [entity_id];

      await axios
        .post(
          API_URL +
            "/similarity/training/v2/group/" +
            group_id +
            "/" +
            operation +
            "-" +
            field +
            "?workspace=" +
            this.workspace,
          data
        )
        .then(response => {}, error => {});
    },
    async removeFromGroup(entity_id, field) {
      this.msg_remove = false;
      let data = {};
      data[field] = [entity_id];
      axios
        .post(
          API_URL +
            "/similarity/training/v2/group/" +
            this.group_id +
            "/remove-" +
            field,
          // "?workspace=" +
          // this.workspace,
          data
        )
        .then(
          response => {
            this.msg_remove = true;
            this.loadGroup();
          },
          error => {}
        );
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loadGroup();
      }
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadGroup();
    }
  },
  created() {
    this.$eventBus.$on("move-group-" + this.group_id, this.moveToNewGroup);
    this.$eventBus.$on("group-id-refresh-" + this.group_id, this.loadGroup);
  },
  beforeDestroy() {
    this.$eventBus.$on("move-group-" + this.group_id);
    this.$eventBus.$off("group-id-refresh-" + this.group_id);
  }
};
</script>

<style></style>
