<template>
  <b-navbar
    id="header-bar"
    toggleable="md"
    type="light"
    class="border-bottom py-md-0"
    variant="white"
    style="height: 55px;"
  >
    <b-button
      v-show="!sidebar_collapsed"
      variant="secondary"
      id="menu-toggle"
      style="#343a40"
      v-on:click="toggleSidebar"
    >
      <i class="fad fa-layer-group logo golden-white blue"></i>
    </b-button>

    <b-navbar-toggle target="header-collapse"></b-navbar-toggle>

    <b-collapse id="header-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item href="#" class>
          <h4 style="margin-top: auto; margin-bottom: auto;">
            {{ $route.name }}
          </h4>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item right v-if="activeWorkspace">
          <b-dropdown
            variant="link"
            class="secondary"
            boundary="viewport"
            right
            toggle-class="text-decoration-none"
          >
            <template slot="button-content">
              <i class="fal fa-desktop-alt fa-lg"></i>
              {{ this.activeWorkspace.name }}
            </template>
            <b-dropdown-item
              ><i class="fal fa-play-circle"></i>
              {{ this.activeWorkspace.name }}</b-dropdown-item
            >
            <b-dropdown-divider></b-dropdown-divider>

            <div style="height: 250px; overflow-y: auto;">
              <b-dropdown-item
                v-for="workspace in workspaces"
                v-bind:key="workspace.id"
                v-on:click="changeWorkspace(workspace)"
                >{{ workspace.name }}</b-dropdown-item
              >
            </div>
          </b-dropdown>
        </b-nav-item>

        <b-nav-item right>
          <b-dropdown
            variant="link"
            class="secondary"
            right
            toggle-class="text-decoration-none"
            no-caret
          >
            <template slot="button-content">
              <i class="fal fa-user-circle fa-lg"></i>
            </template>
            <b-dropdown-item v-on:click="logout"
              ><i class="fal fa-sign-out-alt"></i> Logout</b-dropdown-item
            >
          </b-dropdown>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapState } from "vuex";
import { DataLoadedEnum } from "../constants";

export default {
  name: "VHeader",
  data() {
    return {
      activeWorkspace: ""
    };
  },
  methods: {
    toggleSidebar: function() {
      this.$store.dispatch("toggleSidebar");
    },
    logout: function() {
      this.$store.dispatch("logout");
      this.$router.push("Login");
    },
    changeWorkspace: function(workspace) {
      this.$store.dispatch("changeWorkspace", { workspace: workspace["id"] });
      if (this.$route.path !== "/") {
        console.log("redirecting ...");
        this.$router.push("/");
      }
    }
  },
  computed: {
    ...mapState(["workspaces", "sidebar_collapsed", "data_loaded"]),
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    }
  },
  props: {
    msg: String
  },
  mounted: function() {
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.activeWorkspace = this.$store.getters.getActiveWorkspace;
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.activeWorkspace = this.$store.getters.getActiveWorkspace;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 767px) {
  .pb-md-0,
  .py-md-0 {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
}
.golden {
  --fa-secondary-opacity: 1;
  --fa-primary-color: gold;
  --fa-secondary-color: #007bff;
}

.sidebar-color {
  background-color: #031b4d;
}

.blue {
  --fa-secondary-opacity: 1;
  --fa-primary-color: white;
  --fa-secondary-color: #007bff;
}

.btn-secondary {
  background-color: #343a40 !important;
}
</style>
