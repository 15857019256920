<template>
  <div>
    <b-card v-if="sub_tasks" no-body>
      <b-card-header header-bg-variant="white">
        <b-row>
          <b-col><b>Recognition Tasks</b></b-col>
          <b-col v-if="!detection"
            ><span class="float-right"
              ><b-badge variant="warning">IMAGE</b-badge></span
            ></b-col
          >
        </b-row>
      </b-card-header>

      <b-card-body>
        <span>
          <VPresentLabels
            v-bind:key="'present-labels-' + entity_id"
            v-bind:detection="detection"
            v-bind:image="image"
          />
        </span>
        <span v-if="sub_tasks.length > 0">
          <span
            class="mt-3"
            v-bind:key="task.id"
            v-for="task in sortArrays(sub_tasks)"
          >
            <VRecognitionAction
              v-bind:key="'recognition-card-action-' + task.id"
              v-bind:image="image"
              v-bind:task_id="task.id"
              v-bind:detection="detection"
            />
          </span>
        </span>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { DataLoadedEnum } from "../../constants";
import VLoading from "@/components/VLoading.vue";
import VRecognitionAction from "@/components/image/flows/VRecognitionAction.vue";
import VPresentLabels from "@/components/image/VPresentLabels.vue";

import axios from "axios";
import _ from "lodash";

let API_URL = process.env.VUE_APP_API_URL;
export default {
  props: {
    image: Object,
    detection: Object,
    job: Object
  },
  data() {
    return {
      loading: true,
      meta: false
    };
  },
  components: {
    VRecognitionAction,
    VPresentLabels
  },
  computed: {
    ...mapState(["workspace", "tasks", "labels", "data_loaded"]),
    ...mapGetters(["getActiveWorkspace"]),
    sub_tasks() {
      let job = this.job;
      let result = [];
      let tasks = JSON.parse(JSON.stringify(this.tasks));

      for (let index in tasks) {
        let sub_task = tasks[index];

        if (
          job &&
          "recognition_tasks" in job &&
          job["recognition_tasks"].length > 0
        ) {
          if (job["recognition_tasks"].includes(sub_task.id)) {
            result.push(sub_task);
          }
        } else {
          result.push(sub_task);
        }
      }
      return result;
    },
    entity_id: function() {
      if (this.detection) {
        return this.detection.id;
      }
      return this.image.id;
    }
  },
  methods: {
    sortArrays(arrays) {
      return _.orderBy(arrays, [item => item.name.toLowerCase()], "asc");
    },
    recognize() {
      for (let task of this.sub_tasks) {
        this.$eventBus.$emit("switch-call-recognition-task-" + task.id);
      }
    }
  },
  mounted: function() {},
  watch: {
    data_loaded() {}
  }
};
</script>

<style></style>
