<template>
  <div class="container-fluid mt-3" style="height: 90%; overflow-y: auto;">
    <!-- Tasks -->
    <VDelete
      v-bind:title="'task'"
      v-bind:item_to_remove="task_to_remove"
      @hide="hideTaskModal"
      @remove="removeTask"
    />
    <VTaskCreate v-bind:task="selected_task" type="detection" />
    <b-button v-on:click="createTask()" variant="outline-success" class="mt-3"
      >Create New Task</b-button
    >
    <b-table
      class="mt-3"
      :outlined="outlined"
      :hover="hover"
      :items="detection_tasks"
      :fields="fields_task"
      :busy="loading"
      caption-top
    >
      <template v-slot:table-caption
        >List of detection tasks</template
      >
      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>

      <template v-slot:cell(name)="data">
        {{ data.item.name }}
        <VClipCopy :from="'detection'" :name="'Task'" :uuid="data.item.id" />
      </template>

      <template v-slot:cell(map)="data">
        <span v-show="data.item.map">
          {{ data.item.map }}
          <span
            v-b-popover.hover="
              'mean Average Precision for Intersection over Union of 0.75'
            "
          >
            <i class="fal fa-info-square"></i>
          </span>
        </span>
      </template>

      <template v-slot:cell(action)="data">
        <b-button
          v-on:click="updateTask(data.item)"
          class="mr-1"
          variant="outline-success"
          v-b-tooltip.hover
          title="Edit Task"
        >
          <i class="fal fa-edit"></i>
        </b-button>

        <b-button
          v-on:click="trainTask(data.item.id)"
          class="mr-1"
          variant="outline-success"
          v-b-tooltip.hover
          title="Train/Optimize Task"
        >
          <i class="fal fa-dumbbell"></i>
        </b-button>

        <b-button
          v-if="canDownloadModel"
          v-on:click="downloadModel(data.item.id)"
          class="mr-1"
          variant="outline-primary"
          v-b-tooltip.hover
          title="Download Model"
        >
          <span v-show="downloading && data.item.id == download_id">
            <i class="fas fa-circle-notch fa-spin"></i>
          </span>
          <span v-show="!downloading && data.item.id == download_id">
            <i class="fal fa-download"></i>
          </span>
          <span v-show="!downloading && data.item.id != download_id">
            <i class="fal fa-download"></i>
          </span>
          <span v-show="downloading && data.item.id != download_id">
            <i class="fal fa-download"></i>
          </span>
        </b-button>

        <b-button
          v-on:click="showRemoveTaskModal(data.item)"
          class="mr-1"
          variant="outline-danger"
          v-b-tooltip.hover
          title="Remove Task"
        >
          <i class="fal fa-trash-alt"></i>
        </b-button>
      </template>
      <template slot="map" slot-scope="data">
        {{ data.item.map | parseScore }}
      </template>
    </b-table>

    <!-- Labels -->
    <VDelete
      v-bind:title="'label'"
      v-bind:item_to_remove="label_to_remove"
      @hide="hideLabelModal"
      @remove="removeLabel"
    />
    <VLabelCreate v-bind:label="selected_label" type="detection" />
    <b-button
      v-b-modal.detection-label-create
      variant="outline-success"
      class="mt-3"
      v-on:click="createLabel()"
      >Create New Label</b-button
    >

    <b-table
      class="mt-3"
      :outlined="outlined"
      :hover="hover"
      :items="detection_labels"
      :fields="fields_label"
      :busy="loading"
      caption-top
    >
      <template v-slot:table-caption
        >List of detection labels</template
      >

      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>

      <template v-slot:cell(name)="data">
        {{ data.item.name }}
        <VClipCopy :from="'detection'" :name="'Label'" :uuid="data.item.id" />
      </template>

      <template v-slot:cell(color)="data">
        <span :style="'color:' + data.item.color">
          <i
            :style="'--fa-primary-color: gray;'"
            class="fad fa-square-full fa-2x"
          ></i>
        </span>
      </template>

      <template v-slot:cell(objects_count)="data">
        {{ data.item.detections_count }}
      </template>

      <template v-slot:cell(images_count)="data">
        {{ data.item.images_count }}
      </template>

      <template v-slot:cell(tasks_count)="data">
        {{ data.item.tasks_count }}
      </template>

      <template v-slot:cell(action)="data">
        <b-button
          v-on:click="updateLabel(data.item)"
          class="mr-2"
          variant="outline-success"
          v-b-tooltip.hover
          title="Edit Label"
        >
          <i class="fal fa-edit"></i>
        </b-button>

        <b-button
          v-on:click="showRemoveLabelModal(data.item)"
          class="mr-2"
          variant="outline-danger"
          v-b-tooltip.hover
          title="Remove Label"
        >
          <i class="fal fa-trash-alt"></i>
        </b-button>
      </template>
    </b-table>
    <div class="mt-4">
      <h4>Manage</h4>
      <b-row>
        <b-col>
          <VDetectionTask />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import VLabelCreate from "@/components/create/VLabelCreate.vue";
import VDetectionTask from "@/components/manage/VDetectionTask.vue";
import VDelete from "@/components/create/VDelete.vue";
import VTaskCreate from "@/components/create/VTaskCreate.vue";
import VClipCopy from "@/components/utils/VClipCopy.vue";

import { mapState, mapGetters } from "vuex";
import { DataLoadedEnum } from "../constants";
import axios from "axios";

let API_URL = process.env.VUE_APP_API_URL;

let config = {
  baseURL: "https://tmpdownload-ximilar-models.s3-eu-west-1.amazonaws.com/",
  timeout: 1000000,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
};
let axios_download = axios.create(config);

export default {
  name: "Detection",
  components: {
    VLabelCreate,
    VTaskCreate,
    VDetectionTask,
    VDelete,
    VClipCopy
  },
  data() {
    return {
      label_to_remove: null,
      task_to_remove: null,
      outlined: true,
      hover: true,
      loading: true,
      downloading: false,
      download_id: "",
      fields_label: [
        "name",
        "color",
        "objects_count",
        "images_count",
        "tasks_count",
        "action"
      ],
      selected_label: null,
      fields_task: [
        "name",
        "detectionLabelsCount",
        "last_train_status",
        "map",
        "action"
      ],
      selected_task: null
    };
  },
  computed: {
    ...mapState([
      "detection_labels",
      "data_loaded",
      "detection_tasks",
      "workspace"
    ]),
    ...mapGetters(["checkResource", "canDownloadModel"])
  },
  methods: {
    copyToClipBoard: function(id) {
      console.log("copyying to", id);
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    getCheckResource(resource) {
      return this.checkResource(resource);
    },
    updateLabel(label) {
      this.selected_label = label;
      this.$bvModal.show("type-label-create");
    },
    createLabel() {
      this.selected_label = null;
      this.$bvModal.show("type-label-create");
    },
    showRemoveLabelModal(label) {
      this.label_to_remove = label;
    },
    hideLabelModal() {
      this.label_to_remove = null;
    },
    removeLabel(e) {
      this.$store.dispatch("delete_detection_label", { id: e.id });
    },
    updateTask(task) {
      this.selected_task = task;
      this.$bvModal.show("type-task-create");
    },
    createTask() {
      this.selected_task = null;
      this.$bvModal.show("type-task-create");
    },
    showRemoveTaskModal(task) {
      this.task_to_remove = task;
    },
    hideTaskModal() {
      this.task_to_remove = null;
    },
    removeTask(task) {
      this.$store.dispatch("delete_detection_task", { id: task.id });
    },
    trainTask(task_id) {
      axios
        .post(API_URL + "/detection/v2/task/" + task_id + "/train", {
          workspace: this.workspace
        })
        .then(
          response => {
            this.makeToast("success", "OK", "Model was add to train queue!");
          },
          error => {
            this.makeToast(
              "danger",
              "Unable to train model!",
              error.response.data["detail"]
            );
          }
        );
    },
    async downloadModel(task_id) {
      /** Downloads model from S3 */
      this.downloading = true;
      this.download_id = task_id;

      console.log("attempt to download model");

      let result = await axios.get(
        API_URL + "/detection/v2/model/?detection_task=" + task_id,
        {
          workspace: this.workspace
        }
      );

      if ("data" in result && "results" in result.data) {
        let model = result.data["results"].find(function(element) {
          return element.train_status == "TRAINED";
        });

        if (model) {
          let dresult = null;
          try {
            dresult = await axios.post(
              API_URL + "/detection/v2/model/" + model.id + "/request-download",
              {
                workspace: this.workspace
              }
            );
          } catch (error) {
            this.showWarning("You don't have permission for this feature!");
            return;
          }

          if (
            dresult &&
            "model_path_download" in dresult.data &&
            dresult.data["model_path_download"]
          ) {
            let url = dresult.data["model_path_download"];
            let file_name = url.split("/")[url.split("/").length - 1];

            // ! empty authorization is required
            axios_download
              .get(url, {
                crossDomain: true,
                responseType: "blob",
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  Authorization: ""
                }
              })
              .then(({ data }) => {
                const blob = new Blob([data]);
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.setAttribute("download", file_name);
                link.click();
                this.downloading = false;
                this.download_id = "";
                this.makeToast(
                  "success",
                  "Model is downloading!",
                  "Please wait!"
                );
              });
          } else {
            this.showWarning("You don't have permission for this feature!");
          }
        } else {
          this.showWarning("No trained model exists!");
        }
      }
    },
    showWarning(message = "Unable to download model!") {
      this.makeToast("danger", "Unable to download model!", message);
      this.downloading = false;
      this.download_id = "";
    },
    makeToast(variant, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        toaster: "b-toaster-bottom-right",
        solid: true
      });
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loading = false;
      }
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loading = false;
    }
  },
  filters: {
    parseScore: function(value) {
      if (value) {
        return value.toFixed(3);
      }
      return value;
    }
  }
};
</script>

<style></style>
