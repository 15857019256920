<template>
  <div class="card mt-3">
    <div class="card-header bg-transparent border-light">
      Adding Label/Tag to Recognition Task
    </div>

    <!-- Manage Task and its Labels -->
    <div class="card-body">
      <div class="input-group mb-3">
        <select
          class="custom-select"
          id="inputGroupSelect02"
          v-model="selected_task"
          @change="onTaskChange($event)"
        >
          <option
            v-for="task in tasks"
            :key="`${task.id}`"
            v-bind:value="task"
            >{{ task.name }}</option
          >.
        </select>
        <div class="input-group-append">
          <label class="input-group-text" for="inputGroupSelect02">Task</label>
        </div>
      </div>

      <div v-if="selected_task" class="mt-3">
        <div>
          <p>
            <b>Labels/Tags</b>
          </p>
          <p>Added</p>
          <button
            v-for="label in present_labels"
            :key="`${label.id}`"
            type="button"
            v-on:click="removeLabel(label)"
            class="btn mr-1 btn-outline-danger btn-sm"
          >
            {{ label.name }}
          </button>
          <p class="mt-3">To Add:</p>
          <button
            v-for="label in missing_labels"
            :key="`${label.id}`"
            type="button"
            class="btn mr-1 btn-outline-success btn-sm"
            v-on:click="addLabel(label)"
          >
            {{ label.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VTask",
  components: {},
  data() {
    return {
      selected_task: null,
      present_labels: [],
      missing_labels: []
    };
  },
  computed: {
    ...mapState(["tasks", "labels", "workspace"])
  },
  methods: {
    onTaskChange(event) {
      this.missing_labels = [];
      this.present_labels = [];

      for (var k in this.labels) {
        let present = false;
        for (var i in this.selected_task.labels) {
          if (this.selected_task.labels[i].id == this.labels[k].id) {
            present = true;
            this.present_labels.push(this.labels[k]);
          }
        }
        if (!present && !this.labels[k].negative_for_task) {
          this.missing_labels.push(this.labels[k]);
        }
      }
    },
    addLabel(label) {
      let i = this.missing_labels.indexOf(label);
      this.missing_labels.splice(i, 1);
      this.present_labels.push(label);

      this.$store.dispatch("crudPost", {
        url:
          API_URL +
          "/recognition/v2/task/" +
          this.selected_task.id +
          "/add-label",
        mutation: "ADD_LABEL_TO_TASK",
        task: this.selected_task,
        label: label,
        data: {
          label_id: label.id,
          workspace: this.workspace
        }
      });
    },
    removeLabel(label) {
      if (label.negative_for_task) {
        return;
      }

      let i = this.present_labels.indexOf(label);
      this.missing_labels.push(label);
      this.present_labels.splice(i, 1);

      this.$store.dispatch("crudPost", {
        url:
          API_URL +
          "/recognition/v2/task/" +
          this.selected_task.id +
          "/remove-label",
        mutation: "REMOVE_LABEL_FROM_TASK",
        task: this.selected_task,
        label: label,
        data: {
          label_id: label.id,
          workspace: this.workspace
        }
      });
    }
  },
  mounted: function() {}
};
</script>

<style></style>
