<template>
  <b-modal
    id="type-label-create"
    ref="modal"
    v-bind:title="'Create new ' + title"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
        label="Id"
        :state="nameState"
        label-for="name-input"
        v-if="id"
      >
        <b-form-input
          disabled
          id="input-live"
          v-model="id"
          aria-describedby="input-live-help input-live-feedback"
          placeholder=""
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Name" :state="nameState" label-for="name-input">
        <b-form-input
          id="input-live"
          v-model="name"
          :state="nameState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Enter name of label"
          trim
        ></b-form-input>

        <b-form-invalid-feedback id="input-live-feedback"
          >Enter at least 2 letters</b-form-invalid-feedback
        >

        <b-form-text v-if="error_message" id="input-live-help">
          <p class="text-danger">{{ error_message }}</p>
        </b-form-text>
      </b-form-group>

      <b-form-group class="mx-auto" label="Color" v-if="type != 'similarity'">
        <b-row class="justify-content-md-center">
          <slider-picker :value="color" @input="updateValue" />
        </b-row>
        <b-form-text v-if="valid_message" id="input-live-help">
          <p class="text-success">{{ valid_message }}</p>
        </b-form-text>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { Compact } from "vue-color";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VLabelCreate",
  components: {
    "slider-picker": Compact
  },
  props: {
    label: Object,
    type: String,
    title: {
      type: String,
      default: "Label"
    }
  },
  data() {
    return {
      id: "",
      name: "",
      error_message: "",
      valid_message: "",
      endpoint: {
        recognition: "recognition/v2/label/",
        detection: "detection/v2/label/",
        segmentation: "segmentation/v2/label/",
        similarity: "similarity/training/v2/type/"
      },
      color: { hex: "#ffffff" }
    };
  },
  computed: {
    ...mapState(["workspace"]),
    nameState() {
      if (this.name) {
        return this.name.length > 1 ? true : false;
      }
      return false;
    }
  },
  methods: {
    checkFormValidity() {
      return [this.nameState].every(item => item);
    },
    resetModal() {
      this.error_message = "";
      this.valid_message = "";
    },
    handleOk(bvModalEvt) {
      // prevent modal from closing
      bvModalEvt.preventDefault();

      // trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.valid_message = "";
      this.error_message = "";

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      if (this.label) {
        this.updateLabel();
        return;
      }
      this.createLabel();
    },
    updateValue(e) {
      console.log("updating value", e);
      this.color = { hex: e.hex };
    },
    createLabel() {
      this.$store
        .dispatch("create_" + this.type + "_label", {
          name: this.name,
          workspace: this.workspace,
          color: this.color["hex"]
        })
        .then(() => {
          this.valid_message = "Label was successfuly created!";
          this.error_message = "";
          this.$bvModal.hide("type-label-create");
        })
        .catch(err => {
          this.error_message = "Unable to create label!";
          this.valid_message = "";
        });
    },
    updateLabel() {
      console.log("updating color", this.color["hex"]);
      axios
        .put(
          API_URL +
            "/" +
            this.endpoint[this.type] +
            this.label.id +
            "?workspace=" +
            this.workspace,
          { name: this.name, color: this.color["hex"] }
        )
        .then(
          response => {
            this.$store.dispatch("update_" + this.type + "_label", {
              name: this.name,
              id: this.label.id,
              color: this.color["hex"]
            });
            this.valid_message = "Updated!";
          },
          error => {
            this.error_message = "Unable to update label!";
          }
        );
    }
  },
  watch: {
    label() {
      this.error_message = "";
      this.valid_message = "";

      if (this.label) {
        console.log("label", this.label);
        this.title = "Update " + this.type + " label";
        this.name = this.label.name;
        this.id = this.label.id;
        this.color = { hex: this.label.color };
      } else {
        this.title = "Create new " + this.type + " label";
        this.name = "";
        this.id = "";
        this.color = { hex: "#ffffff" };
      }
    }
  }
};
</script>

<style></style>
