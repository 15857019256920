<template>
  <b-card
    style="background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 62%, rgba(244,244,244,1) 100%);"
  >
    <b-card-title>
      <b-row>
        <b-col>API Token</b-col>
        <b-col style="color: black;">
          <span class="float-right">
            <i class="fal fa-shield-alt fa-2x"></i>
          </span>
        </b-col>
      </b-row>
    </b-card-title>
    <b-card-sub-title
      >Use this token in your request for authentication</b-card-sub-title
    >

    <b-card-text></b-card-text>
    <div>
      <b-row>
        <b-col cols="10">
          <b-input-group class>
            <b-form-input
              id="api_token"
              v-model="api_token"
              :type="token_input_type"
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-dark" v-on:click="revealToken()">
                <i class="fal fa-eye"></i>
              </b-button>
              <b-button
                variant="outline-dark"
                v-on:click="copyToClipBoard('api_token')"
              >
                <i class="fal fa-copy"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col> </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          Active workspace:
          <b-input-group class>
            <b-form-input
              v-model="activeWorkspace.id"
              id="workspace_id"
              type="text"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                variant="outline-dark"
                v-on:click="copyToClipBoard('workspace_id')"
              >
                <i class="fal fa-copy"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { mapState } from "vuex";
import { DataLoadedEnum } from "../../constants";

export default {
  name: "VApiTokenCard",
  components: {},
  props: {},
  data() {
    return {
      token_input_type: "password",
      activeWorkspace: ""
    };
  },
  computed: {
    ...mapState(["data_loaded", "api_token"])
  },
  methods: {
    revealToken: function() {
      if (this.token_input_type == "password") {
        this.token_input_type = "text";
      } else {
        this.token_input_type = "password";
      }
    },
    copyToClipBoard: function(id) {
      this.token_input_type = "text";
      var copyText = document.getElementById(id);
      copyText.setAttribute("type", "text");

      // select the text field
      copyText.select();

      // copy the text inside the text field
      let successful = document.execCommand("copy");

      // change input type
      //this.token_input_type = "password";
      //copyText.setAttribute("type", "password");
    }
  },
  mounted: function() {
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.activeWorkspace = this.$store.getters.getActiveWorkspace;
    }
  },
  watch: {
    data_loaded() {
      this.activeWorkspace = this.$store.getters.getActiveWorkspace;
    }
  }
};
</script>

<style></style>
