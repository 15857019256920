<template>
  <div style="height: 90%; overflow-y: auto;">
    <div v-if="nojob" class="container-fluid mt-3">
      <b-card>
        <b-card-title
          >There is no job for this task. Everything is complete!</b-card-title
        >
        <b-card-text>
          <i class="fal fa-umbrella-beach fa-2x"></i>
        </b-card-text>
      </b-card>
    </div>
    <span v-if="!nojob && group_id">
      <VGroup class="m-3" v-bind:group_id="group_id" v-bind:job="job" />
    </span>
  </div>
</template>

<script>
import VGroup from "@/components/similarity/VGroup.vue";
import { mapState } from "vuex";
import axios from "axios";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VGroupJob",
  components: {
    VGroup
  },
  props: {},
  data() {
    return {
      group_id: null,
      job: null,
      loading: true,
      nojob: false
    };
  },
  computed: {
    ...mapState(["workspace"])
  },
  methods: {
    async loadJob() {
      axios
        .get(
          API_URL +
            "/annotate/v2/similarityjob/" +
            this.$route.params.id +
            "?workspace=" +
            this.workspace
        )
        .then(
          response => {
            this.job = response.data;
            this.loadGroup();
          },
          error => {}
        );
    },
    async loadGroup() {
      // if we already have image_id from query param
      if (this.group_id) {
        return;
      }

      // otherwise we neet to get next similarityjob/group
      axios
        .get(
          API_URL +
            "/annotate/v2/similarityjob/" +
            this.$route.params.id +
            "/get-batch/?workspace=" +
            this.workspace
        )
        .then(
          response => {
            if (response.data["results"].length) {
              this.group_id = response.data["results"][0];
              this.$router.push(
                "/similarityjob/" +
                  this.$route.params.id +
                  "?group=" +
                  this.group_id
              );
            } else {
              this.nojob = true;
            }
          },
          error => {}
        );
    }
  },
  mounted: function() {
    // image can be optional param
    if (this.$route.query["group"]) {
      this.group_id = this.$route.query["group"];
    }
    this.loadJob();
  }
};
</script>

<style></style>
