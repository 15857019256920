import { render, staticRenderFns } from "./VSegmentationCard.vue?vue&type=template&id=464b9930&"
import script from "./VSegmentationCard.vue?vue&type=script&lang=js&"
export * from "./VSegmentationCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports