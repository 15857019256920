<template>
  <div class="mt-3">
    <b-card no-body>
      <b-card-header header-bg-variant="white">
        <b-row>
          <b-dropdown
            variant="link"
            class="secondary dropd-detection-change"
            toggle-class="text-decoration-none"
          >
            <template slot="button-content" v-if="selected_flow">
              <i class="fal fa-random fa-lg"></i>
              <b class="ml-2">{{ selected_flow.name }}</b>
            </template>
            <template slot="button-content" v-if="!selected_flow">
              <i class="fal fa-random fa-lg"></i>
              Please select flow ...
            </template>

            <span style="height:200px;">
              <b-dropdown-item v-if="flows.length == 0">
                <i class="fal fa-random"></i>
                You don't have any flows yet ...
              </b-dropdown-item>
              <b-dropdown-item v-if="selected_flow">
                <i class="fal fa-random"></i>
                {{ selected_flow.name }}
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item
                v-for="flow in flows"
                v-bind:key="flow.id"
                v-on:click="changeFlow(flow)"
                >{{ flow.name }}</b-dropdown-item
              >
            </span>
          </b-dropdown>
          <b-col v-if="!detection"
            ><span class="float-right"
              ><b-badge variant="warning">IMAGE</b-badge></span
            ></b-col
          >
        </b-row>
      </b-card-header>

      <b-card-body v-if="!loading">
        <VPresentLabels
          v-bind:key="'present-labels-' + entity_id"
          v-bind:detection="detection"
          v-bind:image="image"
        />
        <b-card-text v-if="selected_flow">
          <span v-if="this.json_data">
            <VFlowAction
              v-bind:image="image"
              v-bind:action="this.json_data"
              v-bind:detection="detection"
            />
          </span>
          <span v-else>
            This flow is empty!
          </span>
        </b-card-text>
      </b-card-body>
      <b-card-body v-if="loading">
        <b-card-text>
          <VLoading v-bind:loading="loading" />
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import VFlowAction from "@/components/image/flows/VFlowAction.vue";
import VLoading from "@/components/VLoading.vue";
import VPresentLabels from "@/components/image/VPresentLabels.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VFlows",
  components: {
    VPresentLabels,
    VFlowAction,
    VLoading
  },
  props: {
    image: Object,
    detection: Object,
    job: Object
  },
  data() {
    return {
      options: {
        mode: "tree",
        modes: ["text", "tree"],
        colorPicker: true,
        history: true
      },
      loading: false,
      selected_flow: null,
      json_data: null
    };
  },
  computed: {
    ...mapState(["user_id", "flows"]),
    ...mapGetters([
      "getActiveWorkspaceFlow",
      "checkResource",
      "getLastImageFlow",
      "getLastDetectionFlow"
    ]),
    entity_id: function() {
      if (this.detection) {
        return this.detection.id;
      }
      return this.image.id;
    }
  },
  filters: {},
  methods: {
    removeVerify(verify) {},
    async changeFlow(flow) {
      if (!flow) {
        return;
      }
      if (!flow["json_data"]) {
        console.log("Load json data for flow", flow.id);
        this.loading = true;
        let result = await axios.get(
          API_URL + "/flows/v2/flow/" + flow.id + "/json"
        );
        // todo store this in store, right now we just cache the workspace flow
        flow["json_data"] = result.data;
        this.$store.dispatch("set_flow_json", { flow: flow });
        this.loading = false;
      }

      this.json_data = flow["json_data"];
      this.selected_flow = flow;

      if (!this.job) {
        if (this.detection) {
          this.$store.dispatch("update_last_detection_flow", { flow: flow.id });
        } else {
          this.$store.dispatch("update_last_image_flow", { flow: flow.id });
        }
      }
    },
    predict() {
      this.$eventBus.$emit("call-predict-action-" + this.json_data.id);
    }
  },
  mounted: function() {
    console.log("create vflows", this.detection);
    if (this.job && this.job.flow) {
      let flow_id = this.job.flow;
      let flow = this.flows.find(element => element.id == flow_id);
      this.changeFlow(flow);
    } else {
      if (this.detection && this.getLastDetectionFlow) {
        let flow = this.flows.find(
          element => element.id == this.getLastDetectionFlow
        );
        this.changeFlow(flow);
      } else if (this.image && this.getLastImageFlow) {
        let flow = this.flows.find(
          element => element.id == this.getLastImageFlow
        );
        this.changeFlow(flow);
      } else {
        this.changeFlow(this.flows[0]);
      }
    }
  }
};
</script>

<style></style>
