<template>
  <div style="height: 90%; overflow-y: auto;">
    <VManage />
    <VGroups class="m-3" />
  </div>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import { mapState } from "vuex";
import { DataLoadedEnum } from "../constants";
import VGroups from "@/components/similarity/VGroups.vue";
import VManage from "@/components/similarity/VManage.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "groups",
  components: {
    VManage,
    VGroups
  },
  computed: {
    ...mapState([
      "tasks",
      "labels",
      "detection_labels",
      "workspace",
      "data_loaded"
    ])
  },
  data() {
    return {};
  },
  methods: {},
  mounted: function() {
    this.$store.dispatch("init_load_data");
  },
  watch: {}
};
</script>

<style scoped>
.fa-check-square {
  color: green;
}
</style>
