<template>
  <div class="mt-3">
    <b-card title header="Image Info" header-bg-variant="transparent">
      <b-card-text>
        <b-row>
          <b-col md="6">
            <p>Height: {{ image.img_height }}</p>
            <p>Width: {{ image.img_width }}</p>
            <p>Created: {{ image.created | parseDate }}</p>
            <p>
              <a v-bind:href="image.img_path" target="_blank">IMAGE LINK</a>
            </p>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-card
      title
      header="Image Meta"
      class="mt-3"
      header-bg-variant="transparent"
    >
      <b-card-text>
        <JSONEditor
          style="height: 400px;"
          :options="options"
          ref="editor"
          :onChange="onChange"
          :json="image.meta_data"
        />
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import JSONEditor from "vue2-jsoneditor";
import axios from "axios";
let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VImageInfo",
  components: {
    JSONEditor
  },
  props: {
    image: Object
  },
  data() {
    return {
      options: {
        mode: "tree",
        modes: ["text", "tree"],
        colorPicker: true,
        history: true
      }
    };
  },
  computed: {
    ...mapState(["user_id"])
  },
  filters: {
    parseDate: function(value) {
      if (!value) return "";
      return value.substring(0, 10) + " " + value.substring(11, 16);
    }
  },
  methods: {
    removeVerify(verify) {},
    onChange(newJson) {
      this.$emit("updateMeta", { meta_data: newJson });
    }
  },
  mounted: function() {
    console.log(this.image);
  }
};
</script>

<style></style>
