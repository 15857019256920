<template>
  <span>
    <span class="mr-2" v-if="thumb">
      <b-img
        :id="id"
        v-bind="mainProps"
        rounded
        :src="thumb"
        alt="Image 1"
      ></b-img>

      <b-popover :target="id" triggers="hover" placement="top">
        <template #title
          >Zoomed {{ name }}</template
        >
        <span v-if="thumb">
          <b-img
            :id="id"
            style="max-width:250px; max-height:250px;"
            rounded
            :src="image"
            alt="Image 1"
          ></b-img>
        </span>
      </b-popover>
    </span>
  </span>
</template>

<script>
export default {
  name: "VZoomImage",
  computed: {},
  methods: {},
  data() {
    return {
      mainProps: {
        height: 30,
        width: 30
      }
    };
  },
  mounted: function() {},
  props: {
    name: String,
    id: String,
    image: String,
    thumb: String,
    size: Number
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
