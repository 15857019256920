<template>
  <div class="m-3">
    <b-card no-body title>
      <b-card-header header-tag="header" header-bg-variant="transparent">
        <b>List of items present on image</b>
      </b-card-header>
      <b-card-body v-if="loading">
        <VLoading v-bind:loading="loading" />
      </b-card-body>
      <b-card-body v-else>
        <b-card-text>
          <p></p>
          <VPresentLabels
            v-bind:key="'present-labels-' + image.id"
            v-bind:detection="null"
            v-bind:image="image"
          />
          <p></p>

          <p>
            <b><i class="fal fa-draw-square"></i> Objects:</b>
          </p>
          <p>
            <span v-if="detections.length">
              <b-list-group>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  v-bind:key="detection.id"
                  v-for="(detection, index) in detections"
                  href="#"
                  v-on:click="selectItem(detection)"
                >
                  <span>
                    <span :style="'color:' + detection.detection_label.color">
                      <i
                        :style="'--fa-primary-color: gray;'"
                        class="fas fa-square"
                      ></i> </span
                    >&nbsp; {{ detection.detection_label.name }} ({{ index }})
                  </span>
                  <span>
                    <b-badge
                      class="mr-2"
                      v-if="detection.meta_data != null"
                      variant="success"
                      pill
                    >
                      meta-data
                    </b-badge>
                    <b-badge
                      class="mr-2"
                      variant="success"
                      pill
                      v-if="detection.recognition_labels.length > 2"
                    >
                      {{ detection.recognition_labels.length }} labels</b-badge
                    >
                    <span v-else-if="detection.recognition_labels.length > 0">
                      <span
                        v-bind:key="label.id"
                        v-for="(label, index) in detection.recognition_labels"
                      >
                        <b-badge
                          :variant="label.type == 'value' ? 'info' : 'success'"
                          class="mr-2"
                        >
                          {{ label.name }}
                          <span v-if="label.type == 'value'"
                            >({{ label.value }})</span
                          >
                        </b-badge>
                      </span>
                    </span>
                    <b-badge v-else variant="secondary" pill class="mr-2">
                      {{ detection.recognition_labels.length }} labels</b-badge
                    >
                    <VZoomImage
                      :id="detection.id"
                      :image="detection.thumb"
                      :thumb="detection.thumb"
                      :name="'Object'"
                    />
                  </span>
                </b-list-group-item>
              </b-list-group>
            </span>
            <span v-else>
              <p>No objects on this image.</p>
            </span>
          </p>
          <p>
            <b><i class="fal fa-draw-polygon"></i> Polygons:</b>
          </p>
          <p>
            <span v-if="polygons.length">
              <b-list-group>
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  v-bind:key="polygon.id"
                  v-for="(polygon, index) in polygons"
                  href="#"
                  v-on:click="selectItem(polygon)"
                >
                  <span>
                    <span :style="'color:' + polygon.segmentation_label.color">
                      <i
                        :style="'--fa-primary-color: gray;'"
                        class="fas fa-square"
                      ></i> </span
                    >&nbsp; {{ polygon.segmentation_label.name }} ({{ index }})
                  </span></b-list-group-item
                >
              </b-list-group>
            </span>
            <span v-else>
              <p>No polygons on this image.</p>
            </span>
          </p>

          <p>
            <b><i class="fal fa-photo-video"></i> Similarity Groups:</b>
          </p>
          <p>
            <span v-if="groups.length">
              <b-list-group>
                <b-list-group-item
                  v-for="group in groups"
                  v-bind:key="group.id"
                  class="d-flex justify-content-between align-items-center"
                  target="_blank"
                  :href="'/group/' + group.id"
                >
                  <span>
                    {{ group.name }}
                  </span>
                  <span>
                    <span
                      v-if="!group.test_group"
                      class="badge badge-warning badge-pill mr-2"
                      >TRAIN</span
                    >
                    <span
                      v-if="group.test_group"
                      class="badge badge-info badge-pill mr-2"
                      >TEST</span
                    >
                    <b-badge variant="primary" pill>
                      {{ getSimilarityType(group.type).name }}
                    </b-badge>
                  </span>
                </b-list-group-item>
              </b-list-group>
            </span>
            <span v-else>
              <p>No groups contain this image.</p>
            </span>
          </p>

          <p>
            <b><i class="far fa-scrubber"></i> Points:</b>
          </p>
          <p>
            <span v-if="points.length > 0">
              <b-list-group>
                <b-list-group-item
                  v-for="point in points"
                  v-bind:key="point.id"
                  class="d-flex justify-content-between align-items-center"
                  target="_blank"
                  v-on:click="selectItem(point)"
                >
                  <span>
                    {{ point.name }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </span>
            <span v-else>
              <p>No points on this image.</p>
            </span>
          </p>

          <p>
            <b v-if="image.meta_data == null"
              >This image has
              <span class="text-danger">empty</span> metadata!</b
            >
            <b v-else
              >This image has
              <span class="text-success">some</span> metadata!</b
            >
          </p>

          <p>
            <b v-if="image.real == false && image.foreground_mask == null"
              >This image is product but
              <span class="text-danger">has no yet</span> foreground mask
              computed!</b
            >
            <b v-if="image.foreground_mask != null"
              >This image <span class="text-success">has</span> a foreground
              mask!</b
            >
          </p>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";
import { DataLoadedEnum, ResourceEnum } from "../../constants";
import VLoading from "@/components/VLoading.vue";
import VPresentLabels from "@/components/image/VPresentLabels.vue";
import VZoomImage from "@/components/utils/VZoomImage.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VListCard",
  components: {
    VLoading,
    VPresentLabels,
    VZoomImage
  },
  props: {
    image: Object
  },
  data() {
    return {
      detections: [],
      polygons: [],
      groups: [],
      points: [],
      options: {},
      loading: true
    };
  },
  computed: {
    ...mapState(["user_id", "workspace", "similarity_types"]),
    ...mapGetters(["checkResource"])
  },
  filters: {},
  methods: {
    getSimilarityType(type_id) {
      for (let m = 0; m < this.similarity_types.length; m++) {
        if (type_id == this.similarity_types[m]["id"]) {
          return this.similarity_types[m];
        }
      }
    },
    getCheckResource(resource) {
      return this.checkResource(resource);
    },
    sortArrays(arrays) {
      return _.orderBy(arrays, [item => item.name.toLowerCase()], "asc");
    },
    selectItem(item) {
      console.log("CAlling select-item", item);
      this.$eventBus.$emit("select-item-" + this.image.id, item.id);
    },
    async loadData() {
      console.log("LOAD LIST DATA");
      let result = null;
      if (this.checkResource(ResourceEnum.DETECTION)) {
        let result = await axios.get(
          API_URL +
            "/detection/v2/object/?image=" +
            this.image.id +
            "&workspace=" +
            this.workspace +
            "&page_size=300"
        );
        this.detections = result["data"]["results"];

        // load details of every object
        for (let j = 0; j < this.detections.length; j++) {
          let result = await axios.get(
            API_URL + "/detection/v2/object/" + this.detections[j]["id"]
          );
          this.detections[j]["recognition_labels"] =
            result.data["recognition_labels"];
        }
      }

      if (this.checkResource(ResourceEnum.SEGMENTATION)) {
        result = await axios.get(
          API_URL +
            "/segmentation/v2/polygon/?image=" +
            this.image.id +
            "&workspace=" +
            this.workspace +
            "&page_size=300"
        );
        this.polygons = result["data"]["results"];
      }

      if (this.checkResource(ResourceEnum.SIMILARITY)) {
        result = await axios.get(
          API_URL +
            "/similarity/training/v2/group/?workspace=" +
            this.workspace +
            "&image=" +
            this.image.id
        );
        this.groups = result["data"]["results"];
      }

      result = await axios.get(
        API_URL +
          "/annotate/v2/point/?workspace=" +
          this.workspace +
          "&image=" +
          this.image.id
      );
      this.points = result["data"]["results"];

      this.loading = false;
    }
  },
  created() {
    console.log("CREATED", "reload-list-" + this.image.id);
    this.$eventBus.$on("reload-list-" + this.image.id, this.loadData);
  },
  beforeDestroy() {
    console.log("DESTROYED", "reload-list-" + this.image.id);
    this.$eventBus.$off("reload-list-" + this.image.id);
  },
  mounted: function() {
    this.loadData();
  }
};
</script>

<style></style>
