export const DataLoadedEnum = Object.freeze({
  NOT_LOADED: 1,
  LOADING: 2,
  LOADED: 3,
  NO_RESOURCE: 4
});

export const ResourceEnum = Object.freeze({
  RECOGNITION: "custom-recognition",
  DETECTION: "custom-object-detection",
  SEGMENTATION: "custom-segmentation",
  ANNOTATE: "annotate",
  FLOWS: "flows",
  SIMILARITY: "custom-similarity",
  PRODUCT: "products-management"
});
