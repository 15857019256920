<template>
  <div style="height: 90%; overflow-y: auto;">
    <VImages />
  </div>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import { mapState } from "vuex";
import { DataLoadedEnum } from "../constants";
import VImages from "@/components/images/VImages.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "images",
  components: {
    VImages
  },
  computed: {
    ...mapState([
      "tasks",
      "labels",
      "detection_labels",
      "workspace",
      "data_loaded"
    ])
  },
  data() {
    return {};
  },
  methods: {},
  mounted: function() {
    this.$store.dispatch("init_load_data");
  },
  watch: {}
};
</script>

<style scoped>
.fa-check-square {
  color: green;
}
</style>
