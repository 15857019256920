<template>
  <span>
    <b-button
      v-on:click="openUpload"
      variant="outline-success"
      v-b-tooltip.hover
      title="Upload images"
      ><i class="fal fa-upload"></i
    ></b-button>
    <span style="visibility: hidden;">
      <b-form-file
        @input="onFileChange"
        multiple
        accept="image/*"
        ref="pickImageX"
        v-model="files"
      ></b-form-file>
    </span>
  </span>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VUpload",
  props: {
    product_id: String
  },
  data() {
    return {
      files: null
    };
  },
  computed: {
    ...mapState(["user_id", "workspace"])
  },
  methods: {
    async openUpload() {
      console.log("openUpload...");
      this.files = null;
      this.$refs["pickImageX"].reset();

      await this.$nextTick();
      console.log(this.$refs["pickImageX"].$el.childNodes[0]);
      this.$refs["pickImageX"].$el.childNodes[0].click();
    },
    async onFileChange() {
      console.log("onFileChange", this.files.length);
      for (let index = 0; index < this.files.length; index++) {
        await this.uploadBase64(this.files[index]);
      }

      if (this.files.length) {
        this.$bvToast.toast("Uploaded " + this.files.length + " files.", {
          title: `Message`,
          variant: "success",
          toaster: "b-toaster-bottom-right",
          solid: true
        });
      }

      if (this.product_id) {
        this.$eventBus.$emit("product-id-refresh-" + this.product_id);
      }
    },
    async uploadBase64(file) {
      let reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.readAsDataURL(file);
        reader.onload = async function() {
          let response = await axios.post(API_URL + "/product/v2/image/", {
            workspace: this.workspace,
            product: this.product_id,
            base64: reader.result,
            noresize: true
          });
          console.log("Response: ", response.data);
          console.log("This product", this.product_id);

          resolve(reader.result);
        }.bind(this);
        reader.onerror = function(error) {
          console.log("Error: ", error);
          reject(new DOMException("Problem parsing input file."));
        };
      });
    }
  },
  mounted: function() {}
};
</script>

<style></style>
