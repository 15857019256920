<template>
  <div>
    <b-card>
      <b-card-text>
        <b-card-title v-if="text">{{ text }}</b-card-title>
        <b-card-title v-else>Adding Image to the Group</b-card-title>
        <b-row>
          <b-col>
            <b-input-group>
              <b-form-input
                :placeholder="'Enter ' + this.placeholder + ' uuid'"
                v-model="entity_id"
              ></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
            <b-button variant="outline-success" v-on:click="addToGroup()"
              >Add To Group</b-button
            >
          </b-col>
        </b-row>
      </b-card-text>
      <b-alert variant="success" class="mt-2" v-if="msg_add" show
        >Successfuly added!</b-alert
      >
    </b-card>
    <b-card v-if="image" class="mt-3">
      <b-card-text>
        <b v-if="groups.length == 0"
          >No groups for this <span v-if="detection">object</span
          ><span v-else>image</span>!</b
        >
        <span v-else>
          <h4 v-if="groups.length != 0">
            Groups that contain this <span v-if="detection">object</span
            ><span v-else>image</span>
          </h4>

          <b-table :items="groups" :fields="fields" class="mt-3" outlined>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template v-slot:cell(name)="data">
              {{ data.item.name }}

              <VClipCopy
                :from="'ImageGroup'"
                :name="'Group'"
                :uuid="data.item.id"
              />
            </template>

            <template v-slot:cell(type)="data">
              {{ getTypeName(data.item.type) }}
            </template>

            <template v-slot:cell(test_group)="data">
              <span
                v-if="!data.item.test_group"
                class="badge badge-warning badge-pill"
                >TRAIN</span
              >
              <span
                v-if="data.item.test_group"
                class="badge badge-info badge-pill"
                >TEST</span
              >
            </template>

            <template v-slot:cell(image)="data">
              <span v-if="data.item.thumb_img_path">
                <b-img
                  :id="data.item.id"
                  v-bind="mainProps"
                  rounded
                  :src="data.item.thumb_img_path"
                  alt="Image 1"
                ></b-img>

                <b-popover
                  :target="data.item.id"
                  triggers="hover"
                  placement="top"
                >
                  <template #title
                    >Zoomed image</template
                  >
                  <span v-if="data.item.thumb_img_path">
                    <b-img
                      :id="data.item.id"
                      style="max-width:250px; max-height:250px;"
                      rounded
                      :src="data.item.thumb_img_path"
                      alt="Image 1"
                    ></b-img>
                  </span>
                </b-popover>
              </span>
            </template>

            <template v-slot:cell(action)="data">
              <router-link v-bind:to="/group/ + data.item.id" target="_blank">
                <b-button
                  class="mr-1"
                  variant="outline-primary"
                  v-b-tooltip.hover
                  title="Detail"
                >
                  <i class="fal fa-search"></i>
                </b-button>
              </router-link>
              <b-button
                v-on:click="removeGroup(data.item)"
                variant="outline-danger"
                v-b-tooltip.hover
                title="Remove Group"
              >
                <i class="fal fa-trash-alt"></i>
              </b-button>
            </template>
          </b-table>
        </span>
        <b-alert variant="success" class="mt-3" v-if="msg_remove" show
          >Successfuly removed!</b-alert
        >
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import VClipCopy from "../utils/VClipCopy.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VImageGroup",
  components: {
    VClipCopy
  },
  props: {
    text: String,
    image: Object,
    detection: Object,
    placeholder: String,
    field: String,
    operation: String,
    group: Object
  },
  data() {
    return {
      entity_id: null,
      groups: [],
      fields: ["name", "type", "test_group", "image", "action"],
      msg_add: false,
      msg_remove: false,
      mainProps: {
        height: 40,
        width: 40
      }
    };
  },
  computed: {
    ...mapState(["user_id", "workspace", "similarity_types"])
  },
  filters: {
    parseDate: function(value) {
      if (!value) return "";
      return value.substring(0, 10) + " " + value.substring(11, 16);
    }
  },
  methods: {
    async addToGroup() {
      let data = {};
      let group = null;

      if (this.detection) {
        data[this.field] = [this.detection.id];
        group = this.entity_id;
      } else if (this.image) {
        data[this.field] = [this.image.id];
        group = this.entity_id;
      } else {
        data[this.field] = [this.entity_id];
        group = this.group.id;
      }

      axios
        .post(
          API_URL +
            "/similarity/training/v2/group/" +
            group +
            "/" +
            this.operation +
            "-" +
            this.field +
            "?workspace=" +
            this.workspace,
          data
        )
        .then(
          response => {
            this.msg_add = true;
            this.loadGroups();
            this.makeToast("success", "Added to the group.");
            this.$eventBus.$emit("group-id-refresh-" + group, null);
          },
          error => {
            this.makeToast("danger", error.response["data"]);
          }
        );
    },
    makeToast(variant = null, content) {
      this.$bvToast.toast(content, {
        title: `Message`,
        variant: variant,
        solid: true
      });
    },
    getTypeName(type) {
      let item = this.similarity_types.find(function(element) {
        return element.id == type;
      });
      if (item) {
        return item.name;
      } else {
        return "Unknown";
      }
    },
    copyToClipBoard: function(id) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    async loadGroups() {
      this.jobs = [];
      if (this.group) {
        return;
      }
      return new Promise((resolve, reject) => {
        let prefix = "&image=" + this.image.id;
        if (this.detection) {
          prefix = "&object=" + this.detection.id;
        }

        axios
          .get(
            API_URL +
              "/similarity/training/v2/group/?workspace=" +
              this.workspace +
              prefix
          )
          .then(
            response => {
              this.groups = response.data["results"];
              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    },
    async removeGroup(group) {
      let data = {};

      if (this.detection) {
        data[this.field] = [this.detection.id];
      } else {
        data[this.field] = [this.image.id];
      }

      this.msg_remove = false;
      axios
        .post(
          API_URL +
            "/similarity/training/v2/group/" +
            group.id +
            "/remove-" +
            this.field +
            "?workspace=" +
            this.workspace,
          data
        )
        .then(
          response => {
            this.msg_remove = true;
            this.loadGroups();
          },
          error => {}
        );
    }
  },
  created() {
    // if (this.image.id) {
    //   console.log("on ",  "image-group-id-refresh-" + this.image.id);
    //   this.$eventBus.$on("image-group-id-refresh-" + this.image.id, this.loadGroups);
    // }
  },
  beforeDestroy() {
    if (this.image) {
      console.log("off ", "image-group-id-refresh-" + this.image.id);
      this.$eventBus.$off("image-group-id-refresh-" + this.image.id);
    } else if (this.detection) {
      console.log("off ", "image-group-id-refresh-" + this.detection.id);
      this.$eventBus.$off("image-group-id-refresh-" + this.detection.id);
    }
  },
  mounted: function() {
    this.loadGroups();
    if (this.image) {
      console.log("on ", "image-group-id-refresh-" + this.image.id);
      this.$eventBus.$on(
        "image-group-id-refresh-" + this.image.id,
        this.loadGroups
      );
    }
    if (this.detection) {
      console.log("on ", "image-group-id-refresh-" + this.detection.id);
      this.$eventBus.$on(
        "image-group-id-refresh-" + this.detection.id,
        this.loadGroups
      );
    }
  }
};
</script>

<style></style>
