<template>
  <b-modal
    id="image-job-create"
    ref="image-job-create"
    v-bind:title="title"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <div v-if="loading && job"></div>
    <form ref="form" @submit.stop.prevent="handleSubmit" v-else>
      <b-form-group v-if="id" label="Identification" label-for="id-input">
        <b-form-input
          id="input-live"
          v-model="id"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Enter name of job"
          trim
        ></b-form-input>

        <b-form-text id="input-live-help">Id of the job.</b-form-text>
      </b-form-group>

      <b-form-group label="Type of Job" label-for="type-input">
        <b-form-select
          :disabled="id != null"
          v-model="job_type"
          :options="options"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Name" :state="nameState" label-for="name-input">
        <b-form-input
          id="input-live"
          v-model="name"
          :state="nameState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Enter name of job"
          trim
        ></b-form-input>

        <b-form-invalid-feedback id="input-live-feedback"
          >Enter at least 3 letters</b-form-invalid-feedback
        >

        <b-form-text id="input-live-help"
          >Please keep it short and simple.</b-form-text
        >
      </b-form-group>
      <b-form-group
        label="Description"
        :state="descriptionState"
        label-for="description-input"
      >
        <b-form-input
          id="input-live"
          v-model="description"
          :state="descriptionState"
          aria-describedby="input-live-help input-live-feedback"
          placeholder="Enter description of job"
          trim
        ></b-form-input>

        <b-form-invalid-feedback id="input-live-feedback"
          >Enter at least 10 letters</b-form-invalid-feedback
        >

        <b-form-text id="input-live-help">
          Please explain properly what should every Annotation worker do on
          Image.
        </b-form-text>
      </b-form-group>
      <b-form-group
        :label="'Todo Number: ' + todo_number"
        label-for="name-input"
      >
        <b-form-input
          id="range-1"
          v-model="todo_number"
          type="range"
          min="1"
          max="4"
        ></b-form-input>
      </b-form-group>
      <b-form-group label="Recognition Tasks" v-if="job_type == 'image'">
        <v-select
          class="mr-1"
          :options="tasks"
          v-model="selected_tasks"
          :reduce="task => task.id"
          multiple
          @input="setSelected"
          placeholder="Recognition tasks"
          label="name"
        >
          <template
            v-b-tooltip.hover
            :title="option.description"
            slot="option"
            slot-scope="option"
          >
            <span
              style="width: 100%"
              v-b-tooltip.hover
              :id="'option-task-' + option.id"
              :title="option.description"
              >{{ option.name }}</span
            >
          </template>
        </v-select>
        <b-form-text id="input-live-help"
          >If selected, you will see only selected tasks during
          verification.</b-form-text
        >
      </b-form-group>

      <b-form-group label="Flows" v-if="job_type == 'image'">
        <b-form-select
          v-model="selected_flow"
          value-field="id"
          text-field="name"
          :options="flows"
          id="table-style-variant"
        >
          <template #first>
            <option v-bind:value="null_value_flow">-- None --</option>
          </template>
        </b-form-select>
        <b-form-text id="input-live-help"
          >If selected, you will see only selected tasks during
          verification.</b-form-text
        >
      </b-form-group>

      <b-form-group label="Mark Done Label" v-if="job_type == 'image'">
        <b-form-select
          v-model="selected_label"
          value-field="id"
          text-field="name"
          :options="labels"
          id="table-labels-job"
        >
          <template #first>
            <option v-bind:value="null_value_label">-- None --</option>
          </template>
        </b-form-select>
        <b-form-text id="input-live-help"
          >Each verified image will be marked with this label.</b-form-text
        >
      </b-form-group>

      <b-form-group>
        <b-form-checkbox
          id="checkbox-random"
          v-model="randomize"
          name="checkbox-random"
        >
          Randomize order of images when working on job.
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-ac"
          v-model="auto_complete"
          name="checkbox-ac"
        >
          Auto load objects from previous verified item.
        </b-form-checkbox>
      </b-form-group>
    </form>
    <div v-if="valid_message" class="text-success" id="input-live-help">
      <p class="text-success">{{ valid_message }}</p>
    </div>
    <div v-if="error_message" class="text-danger" id="input-live-help">
      <p class="text-danger">{{ error_message }}</p>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VJobCreate",
  components: {},
  props: {
    job: Object
  },
  data() {
    return {
      id: null,
      name: "",
      randomize: true,
      auto_complete: false,
      null_value_flow: null,
      null_value_label: null,
      selected: null,
      selected_label: null,
      selected_flow: null,
      selected_tasks: [],
      present_tasks: [],
      title: "Create New Job",
      todo_number: 1,
      description: "",
      error_message: "",
      valid_message: "",
      actual_tasks: [],
      loading: true,
      job_type: "image",
      options: [
        { value: "image", text: "image" },
        { value: "similarity", text: "similarity" }
      ]
    };
  },
  computed: {
    ...mapState(["workspace", "labels", "tasks", "flows"]),
    nameState() {
      if (this.name) {
        return this.name.length > 2 ? true : false;
      }
      return false;
    },
    descriptionState() {
      return true;
    }
  },
  methods: {
    setSelected(value) {
      console.log("setting selected", value);
    },
    async editTask() {
      if (this.job) {
        console.log("changing task");
        this.changeTask(this.job.id);
      }
    },
    async changeTask(id) {
      // remove old tasks
      for (let id_task of this.present_tasks) {
        console.log("removing task from job", id_task);
        await axios
          .post(
            API_URL +
              "/annotate/v2/" +
              this.job_type +
              "job/" +
              id +
              "/remove-task",
            {
              task_id: id_task
            }
          )
          .then(response => {}, error => {});
      }

      // add new tasks
      for (let id_task of this.selected_tasks) {
        console.log("adding task to job", id_task);
        await axios
          .post(
            API_URL +
              "/annotate/v2/" +
              this.job_type +
              "job/" +
              id +
              "/add-task",
            {
              task_id: id_task
            }
          )
          .then(response => {}, error => {});
      }
      this.present_tasks = this.selected_tasks;

      // add flow
      let result = await axios.post(
        API_URL + "/annotate/v2/" + this.job_type + "job/" + id + "/set-flow",
        {
          flow_id: this.selected_flow
        }
      );

      // add label
      result = await axios.post(
        API_URL + "/annotate/v2/" + this.job_type + "job/" + id + "/set-label",
        {
          label_id: this.selected_label
        }
      );

      console.log(result);
    },
    createJob() {
      if (this.job) {
        this.updateJob();
      } else {
        axios
          .post(API_URL + "/annotate/v2/" + this.job_type + "job/", {
            name: this.name,
            description: this.description,
            todo_number: this.todo_number,
            workspace: this.workspace,
            randomize: this.randomize,
            auto_complete: this.auto_complete,
            done: false
          })
          .then(
            response => {
              this.changeTask(response["data"]["id"]);
              this.valid_message = "Job was successfuly created!";
              this.$emit("refresh");
              this.$bvModal.hide("image-job-create");
            },
            error => {
              this.error_message = "Unable to create job!";
            }
          );
      }
    },
    updateJob() {
      axios
        .put(API_URL + "/annotate/v2/" + this.job_type + "job/" + this.job.id, {
          name: this.name,
          description: this.description,
          todo_number: this.todo_number,
          workspace: this.workspace,
          randomize: this.randomize,
          auto_complete: this.auto_complete,
          done: false
        })
        .then(
          response => {
            this.valid_message = "Job was successfuly updated!";
            this.changeTask(response["data"]["id"]);
            this.$emit("refresh", {});
          },
          error => {
            this.error_message = "Unable to update job!";
          }
        );
    },
    loadJob() {
      axios
        .get(
          API_URL +
            "/annotate/v2/" +
            this.job_type +
            "job/" +
            this.job.id +
            "?workspace=" +
            this.workspace
        )
        .then(
          response => {
            this.id = response.data["id"];
            this.name = response.data["name"];
            this.description = response.data["description"];
            this.todo_number = response.data["todo_number"];
            this.randomize = response.data["randomize"];
            this.auto_complete = response.data["auto_complete"];
            this.loading = false;
            this.actual_tasks = response.data["recognition_tasks"];
            if (response.data["recognition_tasks"].length) {
              this.selected_tasks = response.data["recognition_tasks"];
              this.present_tasks = response.data["recognition_tasks"];
            }
            console.log(response);
            this.selected_flow = response.data["flow"];
            this.selected_label = response.data["mark_done_label"];
          },
          error => {}
        );
    },
    checkFormValidity() {
      return [this.nameState, this.descriptionState].every(item => item);
    },
    resetModal() {},
    handleOk(bvModalEvt) {
      // prevent modal from closing
      bvModalEvt.preventDefault();

      // trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      this.error_message = "";
      this.valid_message = "";

      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.createJob();
    }
  },
  watch: {
    job() {
      this.error_message = "";
      this.valid_message = "";

      if (this.job) {
        this.title = "Update ImageJob";
        this.job_type = this.job.type;
        console.log("loading job", this.job, this.job_type);
        this.loadJob();
      } else {
        this.id = null;
        this.title = "Create New ImageJob";
        this.name = "";
        this.job_type = "image";
        this.description = "";
        this.todo_number = 1;
      }
    }
  },
  mounted() {
    console.log("mounted create job", this.recognition_labels);
  }
};
</script>

<style></style>
