<template>
  <div class="mt-3">
    <b-card no-body title>
      <b-card-header header-bg-variant="white">
        <b-dropdown
          variant="link"
          class="secondary dropd-detection-change"
          toggle-class="text-decoration-none"
        >
          <template slot="button-content">
            <i class="fal fa-draw-polygon fa-lg"></i>
            <b class="ml-2">{{ segmentation.label }}</b>
          </template>
          <span style="height:200px;">
            <b-dropdown-item>
              <i class="fal fa-draw-polygon"></i>
              {{ segmentation.label }}
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-item
              v-for="label in segmentation_labels"
              v-bind:key="label.id"
              v-on:click="changeSegmentationLabel(segmentation, label)"
              >{{ label.name }}</b-dropdown-item
            >
          </span>
        </b-dropdown>

        <span class="float-right">
          <span v-if="segmentation && segmentation.label_data">
            <b-badge
              class="mr-2"
              v-if="'#ffffff' != segmentation.label_data.color"
              v-bind:style="
                'background-color: ' + segmentation.label_data.color
              "
              >POLYGON</b-badge
            >
          </span>
          <b-badge class="mr-2" v-else variant="secondary">POLYGON</b-badge>
          <b-button
            size="md"
            variant="outline-danger"
            v-on:click="removeSegmentation()"
          >
            <i class="fal fa-trash-alt"></i>
          </b-button>
        </span>
      </b-card-header>
      <b-card-body>
        <b-card-text v-if="segmentation.label_data">
          <p v-if="segmentation.label_data.data">
            This polygon consist of
            {{ segmentation.label_data.data.length / 2 }} points.
          </p>
          <p v-if="segmentation.label_data.bbox">
            Coordinates BBOX: {{ segmentation.label_data.bbox }}
          </p>
        </b-card-text>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VSegmentationCard",
  components: {},
  props: {
    segmentation: Object
  },
  data() {
    return {
      options: {}
    };
  },
  computed: {
    ...mapState(["user_id", "segmentation_labels"])
  },
  filters: {},
  methods: {
    async changeSegmentationLabel(segmentation, label) {
      if (segmentation.id_label == label.id) {
        return;
      }

      this.$emit("changeSegmentationLabelEvent", {
        label: JSON.parse(JSON.stringify(label))
      });
      this.$forceUpdate();
    },
    removeVerify(verify) {},
    onChange(newJson) {
      this.$emit("updateMeta", { meta_data: newJson });
    },
    removeSegmentation: function() {
      // this will send signal to parent component
      this.$emit("deleteEvent");
    }
  },
  mounted: function() {
    console.log("SEGMENTATION", this.segmentation);
  }
};
</script>

<style></style>
