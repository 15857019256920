<template>
  <div class="container-fluid mt-3">
    <!-- Tasks -->
    <VDelete
      v-bind:title="'task'"
      v-bind:item_to_remove="task_to_remove"
      v-bind:type="'similarity'"
      @hide="hideTaskModal"
      @remove="removeTask"
    />
    <VTaskCreate v-bind:task="selected_task" type="similarity" />
    <b-button v-on:click="createTask()" variant="outline-success" class="mt-3"
      >Create New Task</b-button
    >
    <b-table
      class="mt-3"
      :outlined="outlined"
      :hover="hover"
      :items="similarity_tasks"
      :fields="fields_task"
      :busy="loading"
      caption-top
    >
      <template v-slot:table-caption
        >List of similarity tasks</template
      >
      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>

      <template v-slot:cell(name)="data">
        {{ data.item.name }}
        <VClipCopy :from="'manage'" :name="'Task'" :uuid="data.item.id" />
      </template>

      <template v-slot:cell(action)="data">
        <b-button
          v-on:click="updateTask(data.item)"
          class="mr-1"
          variant="outline-success"
          v-b-tooltip.hover
          title="Edit Task"
        >
          <i class="fal fa-edit"></i>
        </b-button>

        <!-- <b-button
          v-on:click="showRemoveTaskModal(data.item)"
          class="mr-1"
          variant="outline-danger"
          v-b-tooltip.hover
          title="Remove Task"
        >
          <i class="fal fa-trash-alt"></i>
        </b-button> -->
      </template>
    </b-table>

    <!-- Labels -->
    <VDelete
      v-bind:title="'label'"
      v-bind:item_to_remove="label_to_remove"
      v-bind:type="'similarity'"
      @hide="hideLabelModal"
      @remove="removeLabel"
    />
    <VLabelCreate
      v-bind:label="selected_label"
      title="Type"
      type="similarity"
    />
    <b-button
      v-b-modal.detection-label-create
      variant="outline-success"
      class="mt-3"
      v-on:click="createLabel()"
      >Create New Type</b-button
    >

    <b-table
      class="mt-3"
      :outlined="outlined"
      :hover="hover"
      :items="similarity_types"
      :fields="fields_label"
      :busy="loading"
      caption-top
    >
      <template v-slot:table-caption
        >List of similarity types</template
      >

      <div slot="table-busy" class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
        <strong>Loading...</strong>
      </div>

      <template v-slot:cell(name)="data">
        {{ data.item.name }}
        <VClipCopy
          :from="'vmanage'"
          v-bind:uuid="data.item.id"
          v-bind:name="'Label'"
        />
      </template>

      <template v-slot:cell(action)="data">
        <b-button
          v-on:click="updateLabel(data.item)"
          class="mr-2"
          variant="outline-success"
          v-b-tooltip.hover
          title="Edit Label"
        >
          <i class="fal fa-edit"></i>
        </b-button>
      </template>
    </b-table>

    <b-card
      class="mt-3"
      header="Pick Similarity Task to Manage"
      header-border-variant="light"
      header-bg-variant="transparent"
    >
      <b-card-text>
        <div class="input-group">
          <select
            class="custom-select"
            id="inputGroupSelect02"
            v-model="selected_task"
            @change="onDetectionTaskChange($event)"
          >
            <option
              v-for="task in similarity_tasks"
              v-bind:key="task.id"
              v-bind:value="task"
              >{{ task.name }}</option
            >.
          </select>
          <div class="input-group-append">
            <label class="input-group-text" for="inputGroupSelect02"
              >Task</label
            >
          </div>
        </div>
        <div v-if="selected_task" class="mt-3">
          <div>
            <p>
              <b>Similarity Types</b>
            </p>
            <p>Added</p>
            <button
              v-for="label in present_labels"
              v-bind:key="label.id"
              type="button"
              class="btn mr-1 btn-outline-danger btn-sm"
              v-on:click="detachLabel(label)"
            >
              {{ label.name }}
            </button>
            <p class="mt-3">To Add:</p>
            <button
              v-for="label in missing_labels"
              v-bind:key="label.id"
              type="button"
              class="btn mr-1 btn-outline-success btn-sm"
              v-on:click="addLabel(label)"
            >
              {{ label.name }}
            </button>
          </div>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import VLabelCreate from "@/components/create/VLabelCreate.vue";
import VDetectionTask from "@/components/manage/VDetectionTask.vue";
import VDelete from "@/components/create/VDelete.vue";
import VTaskCreate from "@/components/create/VTaskCreate.vue";
import VClipCopy from "@/components/utils/VClipCopy.vue";

import { mapState, mapGetters } from "vuex";
import { DataLoadedEnum } from "../../constants";
import axios from "axios";

let API_URL = process.env.VUE_APP_API_URL;

let config = {
  baseURL: "https://tmpdownload-ximilar-models.s3-eu-west-1.amazonaws.com/",
  timeout: 1000000,
  headers: {
    "Access-Control-Allow-Origin": "*"
  }
};
let axios_download = axios.create(config);

export default {
  name: "Manage",
  components: {
    VLabelCreate,
    VTaskCreate,
    VDelete,
    VClipCopy
  },
  data() {
    return {
      label_to_remove: null,
      task_to_remove: null,
      outlined: true,
      hover: true,
      loading: true,
      downloading: false,
      download_id: "",
      fields_label: ["name", "action"],
      selected_label: null,
      fields_task: ["name", "action"],
      selected_task: null,
      present_labels: [],
      missing_labels: []
    };
  },
  computed: {
    ...mapState([
      "similarity_tasks",
      "similarity_types",
      "data_loaded",
      "workspace"
    ]),
    ...mapGetters(["checkResource", "canDownloadModel"])
  },
  methods: {
    copyToClipBoard: function(id) {
      console.log("copyying to", id);
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    getCheckResource(resource) {
      return this.checkResource(resource);
    },
    onDetectionTaskChange(event) {
      this.missing_labels = [];
      this.present_labels = [];

      console.log("selected task", this.selected_task);
      console.log(this.similarity_types);

      for (var k in this.similarity_types) {
        let present = false;
        for (var i in this.selected_task.types) {
          if (this.selected_task.types[i].id == this.similarity_types[k].id) {
            present = true;
            this.present_labels.push(this.similarity_types[k]);
          }
        }
        if (!present) {
          this.missing_labels.push(this.similarity_types[k]);
        }
      }
    },
    addLabel(label) {
      this.present_labels.push(label);
      let i = this.missing_labels.indexOf(label);
      this.missing_labels.splice(i, 1);

      this.$store.dispatch("crudPost", {
        url:
          API_URL +
          "/similarity/training/v2/task/" +
          this.selected_task.id +
          "/add-type",
        mutation: "ADD_LABEL_TO_SIMILARITY_TASK",
        task: this.selected_task,
        label: label,
        data: {
          type_id: label.id,
          workspace: this.workspace
        }
      });
    },
    detachLabel(label) {
      this.missing_labels.push(label);
      let i = this.present_labels.indexOf(label);
      this.present_labels.splice(i, 1);

      this.$store.dispatch("crudPost", {
        url:
          API_URL +
          "/similarity/training/v2/task/" +
          this.selected_task.id +
          "/remove-type",
        mutation: "REMOVE_LABEL_FROM_SIMILARITY_TASK",
        task: this.selected_task,
        label: label,
        data: {
          type_id: label.id,
          workspace: this.workspace
        }
      });
    },
    updateLabel(label) {
      this.selected_label = label;
      this.$bvModal.show("type-label-create");
    },
    createLabel() {
      this.selected_label = null;
      this.$bvModal.show("type-label-create");
    },
    showRemoveLabelModal(label) {
      this.label_to_remove = label;
    },
    hideLabelModal() {
      this.label_to_remove = null;
    },
    removeLabel(e) {
      this.$store.dispatch("delete_similarity_label", { id: e.id });
    },
    updateTask(task) {
      this.selected_task = task;
      this.$bvModal.show("type-task-create");
    },
    createTask() {
      this.selected_task = null;
      this.$bvModal.show("type-task-create");
    },
    showRemoveTaskModal(task) {
      this.task_to_remove = task;
    },
    hideTaskModal() {
      this.task_to_remove = null;
    },
    removeTask(task) {
      this.$store.dispatch("delete_similarity_task", { id: task.id });
    },
    makeToast(variant, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        toaster: "b-toaster-bottom-right",
        solid: true
      });
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loading = false;
      }
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loading = false;
    }
  },
  filters: {
    parseScore: function(value) {
      if (value) {
        return value.toFixed(3);
      }
      return value;
    }
  }
};
</script>

<style></style>
