<template>
  <b-card no-body>
    <b-card-header header-bg-variant="white">
      <b-row>
        <b-col>
          <b-dropdown
            variant="link"
            class="secondary dropd-detection-change"
            toggle-class="text-decoration-none"
          >
            <template slot="button-content">
              <i class="fal fa-vector-square fa-lg"></i>
              <b class="ml-2">{{ detection.label }}</b>
            </template>
            <span style="height:200px;">
              <b-dropdown-item>
                <i class="fal fa-vector-square"></i>
                {{ detection.label }}
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>

              <b-dropdown-item
                v-for="label in detection_labels"
                v-bind:key="label.id"
                v-on:click="changeDetectionLabel(detection, label)"
                >{{ label.name }}</b-dropdown-item
              >
            </span>
          </b-dropdown>
          <VClipCopy
            :from="'detectioncard'"
            :name="'Object'"
            :uuid="detection.id"
          />
          <VZoomImage
            :id="detection.label_data.id"
            :name="'Object'"
            :image="detection.label_data.thumb"
            :thumb="detection.label_data.thumb"
          />
        </b-col>

        <b-col>
          <div class="mt-1">
            <span
              v-for="(item, key, index) in buttons"
              v-bind:key="buttons[key].type + index"
            >
              <span
                v-on:click="changeWorkflow(buttons[key])"
                v-b-tooltip.hover
                :title="buttons[key].caption"
              >
                <span
                  v-if="key == 'JOBS' && !buttons[key].state && job"
                  style="color:#FF7B00"
                  class="fa-layers fa-lg mr-2"
                >
                  <i :class="buttons[key].icon"></i>
                </span>
                <span
                  v-else-if="buttons[key].state"
                  style="color:#007bff"
                  class="fa-layers fa-2x mr-2"
                >
                  <i :class="buttons[key].icon"></i>
                </span>
                <span
                  v-else-if="!buttons[key].state"
                  class="fa-layers fa-lg mr-2"
                >
                  <i :class="buttons[key].icon"></i>
                </span>
              </span>
            </span>
          </div>
        </b-col>

        <b-col>
          <div class="float-right">
            <b-badge
              class="mr-2"
              v-if="detection && '#ffffff' != detection.label_data.color"
              v-bind:style="'background-color: ' + detection.label_data.color"
              >OBJECT</b-badge
            >
            <b-badge class="mr-2" v-else variant="secondary">OBJECT</b-badge>
            <span class></span>
            <b-button
              size="md"
              variant="outline-danger"
              v-on:click="removeDetection()"
            >
              <i class="fal fa-trash-alt"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>
      <span v-if="buttons['META'].state">
        <p>Coordinates: {{ detection.label_data.data }}</p>
        <p>ID: {{ detection.id }}</p>
        <p class="card-text">
          <JSONEditor
            style="height: 400px;"
            :options="options"
            ref="editor"
            :onChange="onChangeMeta"
            :json="detection.label_data.meta_data"
          />
        </p>
      </span>
      <span v-if="buttons['JOBS'].state">
        <VRecognitionCard
          v-if="job && job.recognition_tasks.length"
          v-bind:image="image"
          v-bind:job="job"
          v-bind:key="'recognition-card-detection-' + detection.id"
          v-bind:detection="detection"
        />
        <VFlows
          v-if="job && job.flow"
          v-bind:image="image"
          v-bind:job="job"
          v-bind:key="'vflows-jobs-detection-' + detection.id"
          v-bind:detection="detection"
        />
      </span>
      <span v-if="buttons['TAGS'].state">
        <VRecognitionCard
          v-bind:image="image"
          v-bind:key="'recognition-card-detection2-' + detection.id"
          v-bind:detection="detection"
        />
      </span>
      <span v-if="buttons['FLOWS'].state">
        <VFlows
          v-bind:image="image"
          v-bind:key="'vflows-jobs2-detection-' + detection.id"
          v-bind:detection="detection"
        />
      </span>
      <span v-if="buttons['SIMILARITY'].state">
        <VImageGroup
          class="m-3"
          v-bind:text="'Add object to the group'"
          v-bind:operation="'add'"
          v-bind:placeholder="'group'"
          v-bind:field="'objects'"
          v-bind:image="image"
          v-bind:detection="detection"
        />
        <VGroups v-bind:detection="detection" class="m-3" />
      </span>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import JSONEditor from "vue2-jsoneditor";
import _ from "lodash";
import VRecognitionCard from "@/components/image/VRecognitionCard.vue";
import VFlows from "@/components/image/VFlows.vue";
import { DataLoadedEnum, ResourceEnum } from "../../constants";
import VImageGroup from "@/components/image/VImageGroup.vue";
import VGroups from "@/components/similarity/VGroups.vue";
import VZoomImage from "@/components/utils/VZoomImage.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  props: {
    image: Object,
    job: Object,
    detection: Object
  },
  data() {
    return {
      meta: false,
      options: {
        mode: "tree",
        modes: ["text", "tree", "view"],
        search: true,
        colorPicker: true,
        history: true
      },
      buttons: {
        TAGS: {
          caption: "Recognition",
          type: "TAGS",
          state: false,
          icon: "fal fa-tag",
          variant: "outline-primary",
          resource: ResourceEnum.RECOGNITION
        },
        FLOWS: {
          caption: "Flows",
          type: "FLOWS",
          state: false,
          icon: "fal fa-random",
          variant: "outline-primary",
          resource: ResourceEnum.FLOWS
        },
        JOBS: {
          caption: "Jobs",
          type: "JOBS",
          state: false,
          icon: "fal fa-shovel-snow",
          variant: "outline-primary",
          resource: ResourceEnum.ANNOTATE
        },
        META: {
          caption: "Meta",
          type: "META",
          state: false,
          icon: "fal fa-file-code",
          variant: "outline-primary",
          resource: ResourceEnum.RECOGNITION
        },
        SIMILARITY: {
          caption: "Similarity Groups",
          type: "SIMILARITY",
          state: false,
          icon: "fal fa-photo-video",
          variant: "outline-primary",
          resource: ResourceEnum.SIMILARITY
        }
      }
    };
  },
  components: {
    JSONEditor,
    VRecognitionCard,
    VFlows,
    VImageGroup,
    VGroups,
    VZoomImage
  },
  computed: {
    ...mapState(["workspace", "detection_labels"]),
    ...mapGetters(["getLastDetectionAction"])
  },
  methods: {
    copyToClipBoard: function(id) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    async changeDetectionLabel(detection, label) {
      console.log(detection);
      if (detection.id_label == label.id) {
        return;
      }

      this.$emit("changeDetectionLabelEvent", {
        label: JSON.parse(JSON.stringify(label))
      });
      this.$forceUpdate();
    },
    sortArrays(arrays) {
      return _.orderBy(arrays, [item => item.name.toLowerCase()], "asc");
    },
    onChangeMeta: function(newJson) {
      this.$emit("updateMeta", {
        meta_data: newJson
      });
    },
    removeDetection: function() {
      // this will send signal to parent component
      this.$emit("deleteEvent");
    },
    changeWorkflow(button) {
      for (let i in this.buttons) {
        if (this.buttons[i].type != button.type) {
          this.buttons[i].state = false;
        }
      }

      button.state = true;
      this.$store.dispatch("update_last_detection_action", {
        action: button.type
      });
    },
    showWorkflow() {
      let button1 = null;
      if (!this.getLastDetectionAction && this.job) {
        button1 = this.buttons["JOBS"];
      } else {
        button1 = this.buttons[this.getLastDetectionAction];
      }
      this.changeWorkflow(button1);
    }
  },
  watch: {
    detection() {
      this.showWorkflow();
    }
  },
  mounted: function() {
    console.log("DETECTION VOLE:", this.detection);
    if (this.detection) {
      this.showWorkflow();
    }
  }
};
</script>

<style>
.dropd-detection-change .dropdown-menu.show {
  max-height: 200px !important;
  overflow-y: auto !important;
  display: block !important;
}
</style>
