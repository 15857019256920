<template>
  <div>
    <div v-if="nojob" class="container-fluid mt-3">
      <b-card>
        <b-card-title
          >There is no job for this task. Everything is complete!</b-card-title
        >
        <b-card-text>
          <i class="fal fa-umbrella-beach fa-2x"></i>
        </b-card-text>
      </b-card>
    </div>
    <span v-if="!nojob && image_id">
      <VImage v-bind:image_id="image_id" v-bind:job="job" />
    </span>
  </div>
</template>

<script>
import VImage from "@/components/image/VImage.vue";
import { mapState } from "vuex";
import axios from "axios";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VImageJob",
  components: {
    VImage
  },
  props: {},
  data() {
    return {
      image_id: null,
      job: null,
      loading: true,
      nojob: false
    };
  },
  computed: {
    ...mapState(["workspace"])
  },
  methods: {
    async loadJob() {
      axios
        .get(
          API_URL +
            "/annotate/v2/imagejob/" +
            this.$route.params.id +
            "?workspace=" +
            this.workspace
        )
        .then(
          response => {
            this.job = response.data;
            this.loadImage();
          },
          error => {}
        );
    },
    async loadImage() {
      // if we already have image_id from query param
      if (this.image_id) {
        return;
      }

      // otherwise we neet to get next imagejob/image
      axios
        .get(
          API_URL +
            "/annotate/v2/imagejob/" +
            this.$route.params.id +
            "/get-batch/?workspace=" +
            this.workspace
        )
        .then(
          response => {
            if (response.data["results"].length) {
              this.image_id = response.data["results"][0];
              this.$router.push(
                `/imagejob/` + this.$route.params.id + "?image=" + this.image_id
              );
            } else {
              this.nojob = true;
            }
          },
          error => {}
        );
    }
  },
  mounted: function() {
    // image can be optional param
    if (this.$route.query["image"]) {
      this.image_id = this.$route.query["image"];
    }

    this.loadJob();
  }
};
</script>

<style></style>
