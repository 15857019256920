<template>
  <div class="mt-3">
    <b-card>
      <b-card-text>
        <b-card-title>Adding Image to the Job</b-card-title>
        <b-row>
          <b-col>
            <v-select
              style="width: 300px;"
              v-model="job"
              :options="jobs"
              placeholder="Select job"
              label="name"
            ></v-select>
          </b-col>
          <b-col>
            <b-button
              size="sm"
              variant="outline-success"
              v-on:click="addToJob()"
              >Add To Job</b-button
            >
          </b-col>
        </b-row>
      </b-card-text>
      <b-alert variant="success" class="mt-2" v-if="msg_add" show
        >Successfuly added!</b-alert
      >
    </b-card>
    <b-card class="mt-3">
      <b-card-text>
        <b v-if="jobs.length == 0">No jobs for this image!</b>
        <span v-else>
          <h4 v-if="jobs.length != 0">Jobs</h4>

          <b-list-group>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center"
              v-for="job in jobs"
              :key="job.id"
            >
              <span>
                <i class="fal fa-shovel-snow"></i>
                {{ job.name }}
              </span>
              <span class="badge badge-success badge-pill">IMAGE</span>
              <span class="badge badge-primary badge-pill">
                {{ job.created | parseDate }}
              </span>
              <span
                class="badge badge-danger badge-pill"
                v-on:click="removeJob(job)"
                >Remove</span
              >
            </b-list-group-item>
          </b-list-group>
        </span>
        <b-alert variant="success" class="mt-3" v-if="msg_remove" show
          >Successfuly removed!</b-alert
        >
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VJobInfo",
  props: {
    image: Object
  },
  data() {
    return {
      jobs: [],
      job: null,
      msg_add: false,
      msg_remove: false
    };
  },
  computed: {
    ...mapState(["user_id", "workspace"])
  },
  filters: {
    parseDate: function(value) {
      if (!value) return "";
      return value.substring(0, 10) + " " + value.substring(11, 16);
    }
  },
  methods: {
    async addToJob() {
      this.msg_add = false;
      axios
        .post(
          API_URL +
            "/annotate/v2/imagejob/" +
            this.job.id +
            "/add-images?workspace=" +
            this.workspace,
          { images: [this.image.id] }
        )
        .then(
          response => {
            this.msg_add = true;
          },
          error => {}
        );
    },
    async loadJobs() {
      this.jobs = [];
      return new Promise((resolve, reject) => {
        axios
          .get(API_URL + "/annotate/v2/imagejob/?workspace=" + this.workspace)
          .then(
            response => {
              this.jobs = response.data["results"];
              resolve(response);
            },
            error => {
              reject(error);
            }
          );
      });
    },
    async removeJob(job) {
      this.msg_remove = false;
      axios
        .post(
          API_URL +
            "/annotate/v2/imagejob/" +
            job.id +
            "/remove-images?workspace=" +
            this.workspace,
          { images: [this.image.id] }
        )
        .then(
          response => {
            this.msg_remove = true;
          },
          error => {}
        );
    }
  },
  mounted: function() {
    this.loadJobs();
  }
};
</script>

<style></style>
