<template>
  <span>
    <span v-bind:key="index" v-for="(list_action, index) in action.children">
      <VFlowAction
        v-bind:image="image"
        v-bind:action="list_action"
        v-bind:detection="detection"
      />
    </span>
  </span>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "VListAction",
  components: {
    VFlowAction: () => import("@/components/image/flows/VFlowAction.vue")
  },
  props: {
    image: Object,
    detection: Object,
    action: Object
  },
  data() {
    return {
      predict_list: false
    };
  },
  computed: {},
  filters: {},
  updated() {},
  methods: {
    remove() {
      for (let child of this.action.children) {
        this.$eventBus.$emit("call-remove-action-" + child.id);
      }
    }
  },
  created: function() {
    this.$eventBus.$on("call-remove-action-" + this.action.id, this.remove);
  },
  beforeDestroy() {
    this.$eventBus.$off("call-remove-action-" + this.action.id);
  }
};
</script>

<style></style>
