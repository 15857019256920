<template>
  <section class="section">
    <div class="container-fluid">
      <div class="row no-gutter">
        <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
        <div class="col-md-8 col-lg-6">
          <div class="login d-flex align-items-center py-5">
            <div class="container">
              <div class="row">
                <div class="col-md-9 col-lg-8 mx-auto">
                  <h2 class="login-heading mb-4">
                    Ximilar Annotate
                    <i v-if="dev != 'production'" class="fab fa-dev"></i>
                  </h2>
                  <h3 class="login-heading mb-4">Welcome!</h3>
                  <form @submit.prevent="login">
                    <div class="form-label-group">
                      <input
                        type="email"
                        id="inputEmail"
                        class="form-control"
                        v-model="email"
                        placeholder="Email address"
                        required
                        autofocus
                      />
                    </div>

                    <div class="form-label-group mt-3">
                      <input
                        type="password"
                        id="inputPassword"
                        class="form-control"
                        v-model="password"
                        placeholder="Password"
                        required
                      />
                    </div>

                    <div class="custom-control custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customCheck1"
                      />
                    </div>
                    <button
                      class="btn btn-lg btn-primary btn-block btn-login text-uppercase font-weight-bold mb-2"
                      type="submit"
                    >
                      Login
                    </button>
                    <p class="mt-5 mb-3 text-muted">
                      Annotate is tool that enhances the functionalities of
                      <a href="https://app.ximilar.com/"
                        ><b>Ximilar platform</b></a
                      >
                      with features for advanced image annotation and team
                      colaboration. Annotate is available to
                      <a href="https://www.ximilar.com/pricing/"
                        ><b>Business and Professional</b></a
                      >
                      pricing plan users. Annotate is great for developing
                      machine learning models for computer vision.
                    </p>
                    <p class="mt-5 mb-3 text-muted">{{ message }}</p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script
  async
  src="https://www.googletagmanager.com/gtag/js?id=UA-15930127-1"
></script>
<script>
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "UA-15930127-1");
</script>
<script>
export default {
  name: "login",
  data() {
    return {
      dev: process.env.NODE_ENV,
      email: "",
      password: "",
      message: ""
    };
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    }
  },
  methods: {
    login: function() {
      this.message = "";
      let email = this.email;
      let password = this.password;
      this.$store
        .dispatch("login", { email, password })
        .then(() => {
          this.$router.push("/");
        })
        .catch(err => (this.message = "Unable to login. Invalid credentials"));
    }
  }
};
</script>

<style scoped>
.login,
.image {
  min-height: 100vh;
}

.bg-image {
  background-image: url("https://images.unsplash.com/photo-1519677100203-a0e668c92439?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80");
  background-size: cover;
  background-position: center;
}

.login-heading {
  font-weight: 300;
}
</style>
