<template>
  <div>
    <b-card no-body>
      <b-card-header header-bg-variant="white">
        <b-row>
          <b-col
            ><i class="far fa-scrubber"></i> <b>Point</b>
            <span
              class="ml-2"
              v-b-popover.hover="'click to copy point ID to clipboard'"
              v-on:click="copyToClipBoard(point.id)"
            >
              <i class="fal fa-copy"></i>
            </span>
          </b-col>
          <b-col>
            <div class="float-right">
              <b-badge class="mr-2" variant="secondary">POINT</b-badge>
              <span class></span>
              <b-button
                size="md"
                variant="outline-danger"
                v-on:click="removePoint()"
              >
                <i class="fal fa-trash-alt"></i>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body>
        <span>
          <p>
            <b-input-group prepend="Name" class="mt-3">
              <b-form-input
                v-model="point.name"
                placeholder="Enter point name"
              ></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-success" @click="saveName()"
                  >save</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </p>
          <p>Position: ({{ point.left }} {{ point.top }})</p>
        </span>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { DataLoadedEnum } from "../../constants";
import VLoading from "@/components/VLoading.vue";
import VRecognitionAction from "@/components/image/flows/VRecognitionAction.vue";
import VPresentLabels from "@/components/image/VPresentLabels.vue";

import axios from "axios";
import _ from "lodash";

let API_URL = process.env.VUE_APP_API_URL;
export default {
  props: {
    image: Object,
    point: Object
  },
  data() {
    return {
      loading: true,
      meta: false
    };
  },
  components: {},
  computed: {
    ...mapState(["workspace", "tasks", "labels", "data_loaded"]),
    ...mapGetters(["getActiveWorkspace"])
  },
  methods: {
    copyToClipBoard: function(id) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = id;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    async saveName() {
      console.log("Change ...", this.point.name);

      let result = await axios
        .patch(API_URL + "/annotate/v2/point/" + this.point.id, {
          name: this.point.name
        })
        .catch(error => error);

      console.log(result);
    },
    removePoint: function() {
      // this will send signal to parent component
      console.log("removing");
      this.$emit("deleteEvent");
    }
  },
  mounted: function() {},
  watch: {
    data_loaded() {}
  }
};
</script>

<style></style>
