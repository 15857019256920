<template>
  <div style="height: 100%;">
    <VImageToolbar
      v-if="!loading && !error"
      @changeWorkflow="changeWorkflow"
      @loadVerify="loadVerify"
      v-bind:verifyCount="verifyCount"
      v-bind:image="image"
      v-bind:messageRefreshVerify="messageRefreshVerify"
      v-bind:job="job"
    />
    <VLoading v-bind:loading="loading" />

    <b-container v-if="error" class="bv-example-row mt-3">
      <b-row>
        <b-col>
          <b-card class="container">
            <b-card-body>
              <b>Image not found!</b>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <div v-if="!loading && !error" style="height: 100%;">
      <div style="height: 100%;" v-if="!loading">
        <div class="image-detection" style="height: 100%;">
          <b-row style="height: 100%;" no-gutters="true">
            <b-col class="bg-light border-right">
              <div v-bind:style="style_canvas">
                <VDetection
                  @selectPointEvent="selectPointObject"
                  @selectDetectionEvent="selectDetectionObject"
                  @selectSegmentationEvent="selectSegmentationObject"
                  v-bind:messageRemoveSegmentation="messageRemoveSegmentation"
                  v-bind:messageChangeSegmentationLabel="
                    messageChangeSegmentationLabel
                  "
                  v-bind:messageRemovePoint="messageRemovePoint"
                  v-bind:messageRemoveDetection="messageRemoveDetection"
                  v-bind:messageAddTag="messageAddTag"
                  v-bind:messageRemoveTag="messageRemoveTag"
                  v-bind:messageUpdateMeta="messageUpdateMeta"
                  v-bind:messageChangeDetectionLabel="
                    messageChangeDetectionLabel
                  "
                  v-bind:job="job"
                  v-bind:image="image"
                  v-bind:key="'image-' + image.id"
                />
              </div>
            </b-col>
            <b-col v-bind:style="style_card">
              <div>
                <VJobToolbar v-bind:job="job" />
                <span
                  v-if="job && job.recognition_tasks.length == 0 && !job.flow"
                >
                  <b-card class="m-3" title="Job Info">
                    <b-card-text>
                      There is no recognition tasks or flow in this job.
                    </b-card-text>
                  </b-card>
                </span>

                <div class="mt-3" v-if="point">
                  <VPointCard
                    class="m-3"
                    v-bind:point="point"
                    @deleteEvent="removePoint(point)"
                  />
                </div>
                <div
                  class="mt-3"
                  v-if="detection_object && detection_object.id != null"
                >
                  <VDetectionCard
                    class="m-3"
                    v-bind:job="job"
                    v-bind:image="image"
                    v-bind:detection="detection_object"
                    v-bind:messageRemoveDetection="messageRemoveDetection"
                    v-bind:key="'detection-card-' + detection_object.id"
                    @updateMeta="updateMetaDetection"
                    @deleteEvent="removeDetection(detection_object)"
                    @changeDetectionLabelEvent="changeDetectionLabel"
                  />
                </div>
                <div class="mt-3" v-if="segmentation_object">
                  <VSegmentationCard
                    class="m-3"
                    v-bind:key="'segmentation-card' + segmentation_object.id"
                    v-bind:segmentation="segmentation_object"
                    @deleteEvent="removeSegmentation(segmentation_object)"
                    @changeSegmentationLabelEvent="changeSegmentationLabel"
                  />
                </div>
                <span v-if="!detection_object && type == 'FLOWS'">
                  <VFlows
                    class="m-3"
                    v-bind:image="image"
                    v-bind:detection="null_data"
                  />
                </span>
                <span v-if="job && type == 'JOBS' && !detection_object">
                  <VRecognitionCard
                    v-if="job.recognition_tasks.length"
                    class="m-3"
                    v-bind:image="image"
                    v-bind:job="job"
                    v-bind:detection="null_data"
                  />
                  <VFlows
                    v-if="job.flow"
                    class="m-3"
                    v-bind:key="image.id"
                    v-bind:image="image"
                    v-bind:job="job"
                    v-bind:detection="null_data"
                  />
                </span>
                <span
                  v-if="!detection_object && !segmentation_object && !point"
                >
                  <VListCard
                    v-if="
                      type == 'ITEMS' ||
                        type == 'SELECTION' ||
                        type == '' ||
                        type == 'DETECTION' ||
                        type == 'SEGMENTATION' ||
                        type == 'POINT'
                    "
                    v-bind:image="image"
                  />
                  <VRecognitionCard
                    class="m-3"
                    v-if="type == 'TAGS'"
                    v-bind:image="image"
                    v-bind:key="image.id"
                    v-bind:detection="null_data"
                  />
                  <VImageInfo
                    class="m-3"
                    @updateMeta="updateMetaImage"
                    v-if="type == 'META'"
                    v-bind:image="image"
                  />
                  <VVerify
                    class="m-3"
                    v-if="image && type == 'VERIFICATIONS'"
                    v-bind:verified="verified"
                    v-bind:image_workspace="image.workspace"
                    @refreshVerify="refreshVerify()"
                  />
                  <VJobInfo
                    class="m-3"
                    v-if="type == 'JOBS' && job == null"
                    v-bind:image="image"
                  />
                  <VImageGroup
                    class="m-3"
                    v-bind:text="'Add image to the group'"
                    v-bind:operation="'add'"
                    v-bind:placeholder="'group'"
                    v-bind:field="'images'"
                    v-if="type == 'SIMILARITY'"
                    v-bind:image="image"
                  />
                  <VGroups
                    class="m-3"
                    v-if="type == 'SIMILARITY'"
                    v-bind:image_id="image_id"
                  />
                </span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-console */

import axios from "axios";
import { mapState, mapGetters } from "vuex";
import VDetection from "@/components/image/VDetection.vue";
import VVerify from "@/components/image/VVerify.vue";
import VImageInfo from "@/components/image/VImageInfo.vue";
import VLoading from "@/components/VLoading.vue";
import VDetectionCard from "@/components/image/VDetectionCard.vue";
import VSegmentationCard from "@/components/image/VSegmentationCard.vue";
import VJobInfo from "@/components/image/VJobInfo.vue";
import VImageToolbar from "@/components/image/VImageToolbar.vue";
import VRecognitionCard from "@/components/image/VRecognitionCard.vue";
import { DataLoadedEnum } from "../../constants";
import VJobToolbar from "@/components/image/VJobToolbar.vue";
import VFlows from "@/components/image/VFlows.vue";
import VImageGroup from "@/components/image/VImageGroup.vue";
import VGroups from "@/components/similarity/VGroups.vue";
import VListCard from "@/components/image/VListCard.vue";
import VPointCard from "@/components/image/VPointCard.vue";

let API_URL = process.env.VUE_APP_API_URL;

export default {
  name: "image",
  components: {
    VPointCard,
    VImageToolbar,
    VDetectionCard,
    VDetection,
    VSegmentationCard,
    // VSegmentation,
    VVerify,
    VImageInfo,
    VLoading,
    VJobInfo,
    VRecognitionCard,
    VJobToolbar,
    VFlows,
    VImageGroup,
    VGroups,
    VListCard
  },
  props: {
    image_id: String,
    job: Object
  },
  data() {
    return {
      image: null,
      loading: true,
      error: null,
      null_data: null,
      verified: [],
      type: "SELECTION",
      point: null,
      detection_object: null,
      segmentation_object: null,
      messageChangeDetectionLabel: null,
      messageChangeSegmentationLabel: null,
      messageRemoveDetection: null,
      messageRemovePoint: null,
      messageRemoveSegmentation: null,
      messageUpdateMeta: null,
      messageRemoveTag: null,
      messageAddTag: null,
      messageRefreshVerify: 0,
      verifyCount: 0
    };
  },
  computed: {
    ...mapState([
      "data_loaded",
      "user_id",
      "workspace",
      "recognition_tasks",
      "recognition_labels"
    ]),
    ...mapGetters([
      "checkResource",
      "getLabelById",
      "getLastFlow",
      "getLastAction"
    ]),
    style_canvas: function() {
      return {
        "overflow-y": "auto",
        height: "calc(100vh - 110px)",
        "max-width": "calc((100vw - 240px)/2)"
      };
    },
    style_card: function() {
      return {
        "overflow-y": "auto",
        height: "calc(100vh - 110px)",
        "max-width": "calc((100vw - 240px)/2)"
      };
    }
  },
  methods: {
    getCheckResource(resource) {
      return this.checkResource(resource);
    },
    async changeFlag(flag) {
      let data = {};
      data["images"] = [this.image.id];
      data[flag] = true;

      let response = await axios
        .post(
          API_URL +
            "/recognition/v2/training-image/update?workspace=" +
            this.workspace,
          data
        )
        .catch(error => {
          this.loading = false;
          this.error = true;
        });

      this.loadImage();
    },
    async loadImage() {
      let response = await axios
        .get(API_URL + "/recognition/v2/training-image/" + this.image_id)
        .catch(error => {
          this.loading = false;
          this.error = true;
        });

      if (this.workspace != response.data["workspace"]) {
        await this.$store.dispatch("changeWorkspace", {
          workspace: response.data["workspace"]
        });
      }
      console.log("Image:", response.data);
      this.image = response.data;
      this.loading = false;
    },
    /**
     * Methods for communication with VImageToolbar.
     */
    changeWorkflow(e) {
      //this.type = e.type;
    },
    loadVerify(e) {
      this.verified = e.verified;
    },
    refreshVerify(e) {
      this.messageRefreshVerify += 1;
    },
    /**
     * Here goes all methods for communication between VDetectionCard and VDetection.
     * These are two components interconnected with props and emits events.
     */
    removeDetection(e) {
      this.messageRemoveDetection = e;
    },
    removePoint(e) {
      console.log("removing point message");
      this.messageRemovePoint = e;
    },
    removeSegmentation(e) {
      this.messageRemoveSegmentation = e;
    },
    selectPointObject(e) {
      this.point = e.object;
      this.segmentation_object = null;
      this.detection_object = null;
    },
    selectDetectionObject(e) {
      console.log("SELECTED", e.object);
      this.detection_object = e.object;
      this.segmentation_object = null;
      this.point = null;
    },
    selectSegmentationObject(e) {
      this.segmentation_object = e.object;
      this.detection_object = null;
      this.point = null;
    },
    changeDetectionLabel(e) {
      this.messageChangeDetectionLabel = e;
    },
    changeSegmentationLabel(e) {
      this.messageChangeSegmentationLabel = e;
    },
    updateMetaDetection(e) {
      this.messageUpdateMeta = e;
    },
    /**
     * Communication between this and VImage
     */
    updateMetaImage(e) {
      axios
        .put(API_URL + "/recognition/v2/training-image/" + this.image.id, {
          meta_data: e.meta_data
        })
        .then(function(response) {}, function(error) {});
      this.image.meta_data = e.meta_data;
    },
    changeLabel(label) {
      this.removeLabel(label.id);
      this.addLabel(label);
    },
    addLabel(label) {
      let label_obj = Object.assign({}, this.getLabelById(label.id));
      console.log(label_obj);
      if (label_obj.type == "value") {
        label_obj.value = label.value;
      }
      this.image.labels.push(label_obj);
      this.$forceUpdate();
    },
    removeLabel(label) {
      let k = this.image.labels.findIndex(img_label => img_label.id == label);
      this.image.labels.splice(k, 1);
      this.$forceUpdate();
      // console.log("REMOVE LABEL");
    }
  },
  watch: {
    data_loaded() {
      if (this.data_loaded == DataLoadedEnum.LOADED) {
        this.loadImage();
      }
    }
  },
  mounted: function() {
    this.$store.dispatch("init_load_data");
    if (this.data_loaded == DataLoadedEnum.LOADED) {
      this.loadImage();
    }
  },
  created() {
    console.log("CREATING", "del-label-image-" + this.image_id);
    this.$eventBus.$on("Image-workflow-change", data => {
      this.type = data.type;
    });

    this.$eventBus.$on(
      "change-labelvalue-image-" + this.image_id,
      this.changeLabel
    );
    this.$eventBus.$on("image-change-flag-" + this.image_id, this.changeFlag);
    this.$eventBus.$on("add-label-image-" + this.image_id, this.addLabel);
    this.$eventBus.$on("del-label-image-" + this.image_id, this.removeLabel);
  },
  beforeDestroy() {
    console.log("BEFORE DESTROY IMAGE WWTF");
    this.$eventBus.$off("change-labelvalue-image-" + this.image_id);
    this.$eventBus.$off("image-change-flag-" + this.image_id);
    this.$eventBus.$off("add-label-image-" + this.image_id);
    this.$eventBus.$off("del-label-image-" + this.image_id);
  }
};
</script>

<style></style>
