<template>
  <b-navbar
    id="toolbar-bar"
    toggleable="md"
    type="light"
    class="border-bottom py-md-0"
    variant="gray"
  >
    <b-navbar-toggle target="toolbar-collapse"></b-navbar-toggle>

    <b-collapse
      id="toolbar-collapse"
      is-nav
      class="d-flex justify-content-between"
    >
      <b-navbar-nav>
        <span
          v-for="(item, key, index) in draw_buttons"
          v-bind:key="draw_buttons[key].type + index"
        >
          <b-nav-item
            v-on:click="changeWorkflow(draw_buttons[key])"
            v-if="getCheckResource(draw_buttons[key].resource)"
          >
            <span v-b-tooltip.hover :title="draw_buttons[key].caption">
              <span
                v-if="draw_buttons[key].state"
                style="color:#007bff"
                class="fa-layers fa-lg"
              >
                <i :class="draw_buttons[key].icon"></i>
              </span>
              <span v-if="!draw_buttons[key].state" class="fa-layers fa-lg">
                <i :class="draw_buttons[key].icon"></i>
              </span>
            </span>
          </b-nav-item>
        </span>
      </b-navbar-nav>

      <!-- middle menu -->
      <b-navbar-nav>
        <span
          v-for="(item, key, index) in buttons"
          v-bind:key="buttons[key].type + index"
        >
          <b-nav-item
            v-on:click="changeWorkflow(buttons[key])"
            v-if="getCheckResource(buttons[key].resource)"
          >
            <span v-b-tooltip.hover :title="buttons[key].caption">
              <span
                v-if="key == 'JOBS' && !buttons[key].state && job"
                style="color:#FF7B00"
                class="fa-layers fa-lg mt-1"
              >
                <i :class="buttons[key].icon"></i>
              </span>
              <span
                v-else-if="buttons[key].state"
                style="color:#007bff"
                class="fa-layers fa-2x"
              >
                <i :class="buttons[key].icon"></i>
              </span>
              <span
                v-else-if="!buttons[key].state"
                class="fa-layers fa-lg mt-1"
              >
                <i :class="buttons[key].icon"></i>
              </span>
            </span>
          </b-nav-item>
        </span>

        <b-nav-item
          v-on:click="changeWorkflow(special_buttons['VERIFICATIONS'])"
          v-if="getCheckResource(ResourceEnum.ANNOTATE)"
        >
          <span
            v-b-tooltip.hover
            :title="special_buttons['VERIFICATIONS'].caption"
          >
            <span
              v-if="special_buttons['VERIFICATIONS'].state"
              class="fa-layers fa-2x"
              style="color:#007bff"
            >
              <i class="fal fa-clipboard-check"></i>
              <span
                v-if="special_buttons['VERIFICATIONS'].count == 0"
                class="fa-layers-counter fa-2x"
                style="background:Tomato"
                >0</span
              >
              <span
                v-if="special_buttons['VERIFICATIONS'].count != 0"
                class="fa-layers-counter fa-2x"
                style="background:Green"
                >{{ special_buttons["VERIFICATIONS"].count }}</span
              >
            </span>
            <span v-else class="fa-layers fa-lg mt-1">
              <i class="fal fa-clipboard-check"></i>
              <span
                v-if="special_buttons['VERIFICATIONS'].count == 0"
                class="fa-layers-counter fa-2x mt-q"
                style="background:Tomato"
                >0</span
              >
              <span
                v-if="special_buttons['VERIFICATIONS'].count != 0"
                class="fa-layers-counter fa-2x"
                style="background:Green"
                >{{ special_buttons["VERIFICATIONS"].count }}</span
              >
            </span>
          </span>
        </b-nav-item>
      </b-navbar-nav>
      <!-- Right aligned nav items -->

      <b-navbar-nav>
        <b-nav-item right>
          <VDelete
            v-bind:title="'image'"
            v-bind:item_to_remove="item_to_remove"
            @hide="hideImageModal"
            @remove="removeImage"
          />
        </b-nav-item>
        <b-nav-item right>
          <b-button
            v-if="!image.real"
            v-on:click="changeFlag('mark-real')"
            variant="info"
            v-b-tooltip.hover
            title="image has product background"
          >
            PRODUCT
          </b-button>
          <b-button
            v-if="image.real"
            v-on:click="changeFlag('unmark-real')"
            variant="warning"
            v-b-tooltip.hover
            title="image has real background"
          >
            REAL
          </b-button>
        </b-nav-item>

        <b-nav-item right>
          <b-button
            v-if="!image.test_image"
            v-on:click="changeFlag('mark-test')"
            variant="warning"
            v-b-tooltip.hover
            title="image is used for training"
          >
            TRAIN
          </b-button>
          <b-button
            v-if="image.test_image"
            v-on:click="changeFlag('unmark-test')"
            variant="info"
            v-b-tooltip.hover
            title="image is used for evaluation"
          >
            TEST
          </b-button>
        </b-nav-item>
        <b-nav-item right>
          <b-button
            v-on:click="copyToClipBoard(image.id)"
            variant="outline-secondary"
            disabled
            v-b-tooltip.hover
            title="copy Image ID to clipboard"
          >
            <VClipCopy
              :from="'ImageToolbar'"
              v-bind:uuid="image.id"
              v-bind:name="'Image'"
            />
          </b-button>
        </b-nav-item>

        <b-nav-item right>
          <b-button
            v-on:click="showRemoveImageModal()"
            variant="outline-danger"
            v-b-tooltip.hover
            title="Remove Image"
          >
            <i class="fal fa-trash-alt"></i>
          </b-button>
        </b-nav-item>

        <b-nav-item
          v-if="!job && getCheckResource(ResourceEnum.ANNOTATE)"
          right
        >
          <b-button variant="outline-success" v-on:click="verifyImage()">
            <i class="fal fa-check"></i> Verify
          </b-button>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import VDelete from "@/components/create/VDelete.vue";
import VClipCopy from "../utils/VClipCopy.vue";

import { DataLoadedEnum, ResourceEnum } from "../../constants";
import axios from "axios";
import { mapState, mapGetters } from "vuex";
let API_URL = process.env.VUE_APP_API_URL;

export default {
  props: {
    image: Object,
    job: Object,
    messageRefreshVerify: Number
  },
  components: {
    VDelete,
    VClipCopy
  },
  computed: {
    ...mapState(["data_loaded", "user_id", "workspace"]),
    ...mapGetters([
      "getActiveWorkspaceFlow",
      "checkResource",
      "getLastAction",
      "getPreviousJobItem",
      "getNextJobItem",
      "isItemInJob"
    ])
  },
  data() {
    return {
      ResourceEnum: ResourceEnum,
      item_to_remove: null,
      verified: [],
      verifyCount: 0,
      showModal: 0,
      buttons: {
        TAGS: {
          caption: "Recognition",
          type: "TAGS",
          state: false,
          icon: "fal fa-tag",
          variant: "outline-primary",
          resource: ResourceEnum.RECOGNITION
        },
        ITEMS: {
          caption: "List of items",
          type: "ITEMS",
          state: false,
          icon: "fal fa-list",
          variant: "outline-primary",
          resource: ResourceEnum.RECOGNITION
        },
        FLOWS: {
          caption: "Flows",
          type: "FLOWS",
          state: false,
          icon: "fal fa-random",
          variant: "outline-primary",
          resource: ResourceEnum.FLOWS
        },
        JOBS: {
          caption: "Jobs",
          type: "JOBS",
          state: false,
          icon: "fal fa-shovel-snow",
          variant: "outline-primary",
          resource: ResourceEnum.ANNOTATE
        },
        SIMILARITY: {
          caption: "Similarity Groups",
          type: "SIMILARITY",
          state: false,
          icon: "fal fa-photo-video",
          variant: "outline-primary",
          resource: ResourceEnum.SIMILARITY
        },
        META: {
          caption: "Meta",
          type: "META",
          state: false,
          icon: "fal fa-file-code",
          variant: "outline-primary",
          resource: ResourceEnum.RECOGNITION
        }
      },
      special_buttons: {
        VERIFICATIONS: {
          caption: "Verifications",
          type: "VERIFICATIONS",
          state: false,
          count: 0,
          icon: "fal fa-clipboard-check",
          variant: "outline-success",
          resource: ResourceEnum.ANNOTATE
        }
      },
      draw_buttons: {
        SELECTION: {
          caption: "Selection tool",
          type: "SELECTION",
          state: true,
          icon: "fal fa-mouse-pointer",
          variant: "outline-primary",
          resource: ResourceEnum.RECOGNITION
        },
        DETECTION: {
          caption: "Draw objects (Detection)",
          type: "DETECTION",
          state: false,
          icon: "fal fa-draw-square",
          variant: "outline-primary",
          resource: ResourceEnum.DETECTION
        },
        POINT: {
          caption: "Draw points",
          type: "POINT",
          state: false,
          icon: "far fa-scrubber",
          variant: "outline-primary",
          resource: ResourceEnum.ANNOTATE
        },
        SEGMENTATION: {
          caption: "Draw polygons (Segmentation)",
          type: "SEGMENTATION",
          state: false,
          icon: "fal fa-draw-polygon",
          variant: "outline-primary",
          resource: ResourceEnum.SEGMENTATION
        }
      }
    };
  },
  methods: {
    getCheckResource(resource) {
      return this.checkResource(resource);
    },
    changeFlag(flag) {
      this.$eventBus.$emit("image-change-flag-" + this.image.id, flag);
    },
    showRemoveImageModal() {
      this.item_to_remove = this.image;
    },
    hideImageModal() {
      this.item_to_remove = null;
    },
    removeImage(e) {
      axios
        .delete(
          API_URL +
            "/recognition/v2/training-image/" +
            this.image.id +
            "/?workspace=" +
            this.workspace
        )
        .then(
          response => {
            if (this.job) {
              this.$router.push({
                name: "ImageJob",
                params: { id: this.job.id }
              });
            } else {
              this.$router.push({
                name: "Images"
              });
            }
          },
          error => {}
        );
    },
    verifyImage() {
      axios
        .post(API_URL + "/annotate/v2/verification/", {
          user: this.user_id,
          image_id: this.image.id
        })
        .then(
          response => {
            if (!this.job) {
              this.loadVerify();
            }
          },
          error => {}
        );

      this.$store.dispatch("update_last_image", { image: this.image.id });
    },
    loadVerify() {
      axios
        .get(
          API_URL +
            "/annotate/v2/verification/?image=" +
            this.image.id +
            "&workspace=" +
            this.image.workspace
        )
        .then(
          response => {
            this.verified = response.data.results;
            this.verifyCount = this.verified.length;
            this.$emit("loadVerify", { verified: this.verified });
          },
          error => {}
        );
    },
    changeWorkflow(button) {
      for (let i in this.buttons) {
        if (this.buttons[i].type != button.type) {
          this.buttons[i].state = false;
        }
      }
      for (let i in this.draw_buttons) {
        if (this.draw_buttons[i].type != button.type) {
          this.draw_buttons[i].state = false;
        }
      }
      for (let i in this.special_buttons) {
        if (this.special_buttons[i].type != button.type) {
          this.special_buttons[i].state = false;
        }
      }

      console.log("changing button", button.type);
      button.state = true;
      this.$emit("changeWorkflow", { type: button.type });
      this.$eventBus.$emit("Image-workflow-change", { type: button.type });
      this.$store.dispatch("update_last_action", { action: button.type });
    },
    showWorkflow() {
      let button1 = null;
      if (!this.getLastAction && this.job) {
        button1 = this.buttons["JOBS"];
      } else {
        button1 = this.buttons[this.getLastAction];
        if (!button1) {
          button1 = this.draw_buttons[this.getLastAction];
          if (!button1) {
            button1 = this.special_buttons[this.getLastAction];
          }
        }
      }
      console.log("CHANGING WORKFLOW", button1);
      this.changeWorkflow(button1);
    }
  },
  watch: {
    messageRefreshVerify() {
      if (this.image) {
        this.loadVerify();
      }
    },
    verifyCount() {
      this.special_buttons["VERIFICATIONS"].count = this.verifyCount;
    },
    image() {
      if (this.image) {
        this.loadVerify();
        this.showWorkflow();
      }
    },
    job() {
      this.showWorkflow();
    }
  },
  mounted: function() {
    if (this.image) {
      this.loadVerify();
      this.showWorkflow();
    }
  }
};
</script>

<style>
#magic-line {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 4px;
  background: #fe4902;
}
</style>
