<template>
  <b-navbar
    v-if="job"
    toggleable="lg"
    type="light"
    variant="white"
    class="border-bottom shadow-sm"
  >
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item v-b-tooltip.hover :title="job.description">
          <i style="color:#f0ad4e" class="fal fa-exclamation mr-2 fa-lg"></i>
          <b style="color:#f0ad4e">{{ job.name }}</b>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav>
        <b-nav-item v-if="job.auto_complete">
          <span
            v-b-tooltip.hover
            title="Autoload the objects from previous item."
          >
            <i class="fal fa-download fa-lg"></i>
          </span>
        </b-nav-item>
        <b-nav-item v-if="job.randomize">
          <span v-b-tooltip.hover title="Random order of items.">
            <i class="fal fa-random fa-lg"></i>
          </span>
        </b-nav-item>
        <b-nav-item style="width: auto" class="mt-05">
          <b-progress
            style="width: 70px"
            v-b-tooltip.hover
            :title="job.item_done_count + '/' + job.item_count"
            :value="job.item_done_count"
            :max="job.item_count"
            animated
          ></b-progress>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item
          id="verify-job"
          v-if="job && getCheckResource(ResourceEnum.ANNOTATE)"
          right
        >
          <b-button
            class="mr-3"
            id="previous-job-button"
            variant="outline-warning"
            v-on:click="previousJob()"
            v-b-tooltip.hover
            title="Previous"
          >
            <i id="previous-job-icon" class="fal fa-caret-left"></i> Previous
          </b-button>

          <b-button
            id="verify-job-button"
            variant="outline-success"
            v-on:click="checkJob()"
          >
            <i class="fal fa-check"></i> Verify and Next
          </b-button>
        </b-nav-item>

        <b-nav-item v-else-if="getCheckResource(ResourceEnum.ANNOTATE)" right>
          <b-button variant="outline-success" v-on:click="verifyImage()">
            <i class="fal fa-check"></i> Verify
          </b-button>
        </b-nav-item>

        <b-nav-item
          v-if="
            job &&
              getCheckResource(ResourceEnum.ANNOTATE) &&
              (job.randomize || isItemInJob(getItemId()))
          "
          right
        >
          <b-button
            variant="outline-success"
            v-on:click="nextJob()"
            v-b-tooltip.hover
            title="Next"
          >
            <i class="fal fa-caret-right"></i>
          </b-button>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from "vuex";
let API_URL = process.env.VUE_APP_API_URL;
import { DataLoadedEnum, ResourceEnum } from "../../constants";

export default {
  name: "VJobToolbar",
  props: {
    job: Object
  },
  computed: {
    ...mapState(["data_loaded", "user_id", "workspace"]),
    ...mapGetters([
      "getPreviousJobItem",
      "getNextJobItem",
      "checkResource",
      "isItemInJob"
    ])
  },
  data() {
    return {
      ResourceEnum: ResourceEnum,
      group_id: null,
      image_id: null,
      verified: []
    };
  },
  methods: {
    getCheckResource(resource) {
      return this.checkResource(resource);
    },
    getItemId() {
      if (this.image_id) {
        return this.image_id;
      }
      return this.group_id;
    },
    getTypeId() {
      if (this.image_id) {
        return "Image";
      }
      return "Similarity";
    },
    previousJob() {
      let item = this.getPreviousJobItem(this.getItemId());

      if (item) {
        let router_data = { name: this.getTypeId() + "Job", id: this.job.id };
        let query = {};

        query[this.getTypeId().toLowerCase()] = item;
        router_data["query"] = query;
        this.$router.push(router_data);
      } else {
        this.$bvToast.toast("No previous item in job....", {
          title: `Message`,
          variant: "warning",
          toaster: "b-toaster-bottom-right",
          solid: true
        });
      }
    },
    nextJob() {
      if (this.image_id) {
        this.$store.dispatch("update_last_image", {
          image: this.image_id,
          job: this.job.id
        });
      }

      this.$store.dispatch("add_job_item", { item: this.getItemId() });

      if (this.isItemInJob(this.getItemId()) && !this.job.randomize) {
        let router_data = { name: this.getTypeId() + "Job", id: this.job.id };
        let query = {};

        query[this.getTypeId().toLowerCase()] = this.getNextJobItem(
          this.getItemId()
        );
        router_data["query"] = query;
        this.$router.push(router_data);
      } else {
        this.$router.push({
          name: this.getTypeId() + "Job",
          params: { id: this.job.id }
        });
      }
    },
    checkJob() {
      if (this.image_id) {
        this.$store.dispatch("update_last_image", {
          image: this.image_id,
          job: this.job.id
        });
      }

      this.$store.dispatch("add_job_item", { item: this.getItemId() });

      axios
        .post(
          API_URL +
            "/annotate/v2/" +
            this.getTypeId().toLowerCase() +
            "job/" +
            this.$route.params.id +
            "/mark-done?workspace=" +
            this.workspace,
          {
            items: [this.getItemId()]
          }
        )
        .then(
          response => {
            this.$router.push({
              name: this.getTypeId() + "Job",
              params: { id: this.job.id }
            });
          },
          error => {}
        );
    }
  },
  watch: {},
  mounted: function() {
    if (this.$route.query["group"]) {
      this.group_id = this.$route.query["group"];
    }
    if (this.$route.query["image"]) {
      this.image_id = this.$route.query["image"];
    }
  }
};
</script>

<style>
.mt-05 {
  margin-top: 0.125rem !important;
}
</style>
