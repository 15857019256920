<template>
  <div class="card mt-3">
    <div class="card-header bg-transparent border-light">{{ title }}</div>
    <div class="card-body">
      <div>
        <button
          v-for="entity in entities"
          type="button"
          v-bind:key="entity.id"
          class="btn mr-1 mt-1 btn-outline-primary btn-sm"
        >
          {{ entity.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "VShowEntities",
  components: {},
  props: {
    title: String,
    field: String
  },
  data() {
    return {};
  },
  computed: {
    entities: function() {
      let data = {
        tasks: this.$store.getters.getTasks,
        labels: this.$store.getters.getLabels,
        detection_labels: this.$store.getters.getDetectionLabels,
        detection_tasks: this.$store.getters.getDetectionTasks
      };
      return data[this.field];
    }
  },
  mounted: function() {}
};
</script>

<style></style>
