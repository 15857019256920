<template>
  <div>
    <span>
      <p>
        <b><i class="fal fa-tag"></i> Labels Present:</b>
      </p>
      <span v-if="labels_present.length == 0">
        <p>No labels are present.</p>
      </span>
      <span v-if="labels_present.length > 0">
        <span v-for="label in sortArrays(labels_present)" v-bind:key="label.id">
          <b-button
            :id="'tooltip-label-' + label.id"
            :variant="label.type == 'value' ? 'info' : 'success'"
            size="sm"
            class="mr-1 mt-1"
            v-on:click="removeTag(label)"
          >
            <i v-if="label.type == 'category'" class="fal fa-folder-tree"></i>
            <i v-else-if="label.type == 'value'" class="fal  fa-signal-alt"></i>
            <i v-else class="fal fa-tag"></i>
            {{ label.name
            }}<span v-if="label.type == 'value'"> ({{ label.value }})</span>
          </b-button>
          <b-tooltip
            v-if="label.description"
            delay="1000"
            :target="'tooltip-label-' + label.id"
            triggers="hover"
          >
            {{ label.description }}
          </b-tooltip>
        </span>
      </span>
      <p></p>
      <hr />
    </span>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { DataLoadedEnum } from "../../constants";
import VLoading from "@/components/VLoading.vue";

import axios from "axios";
import _ from "lodash";

let API_URL = process.env.VUE_APP_API_URL;
export default {
  name: "VPresentLabels",
  props: {
    image: Object,
    detection: Object
  },
  data() {
    return {
      loading: true,
      meta: false
    };
  },
  components: {},
  computed: {
    ...mapState(["workspace", "tasks", "labels", "data_loaded"]),
    ...mapGetters(["getActiveWorkspace"]),
    labels_present() {
      if (this.detection) {
        return this.detection.label_data.recognition_labels;
      } else if (this.image) {
        return this.image.labels;
      }
      return [];
    },
    endpoint: function() {
      if (this.detection) {
        return "/detection/v2/object/";
      }
      return "/recognition/v2/training-image/";
    },
    entity_id: function() {
      if (this.detection) {
        return this.detection.id;
      }
      return this.image.id;
    }
  },
  methods: {
    async removeTag(label) {
      let result = await axios
        .post(API_URL + this.endpoint + this.entity_id + "/remove-label", {
          label_id: label.id
        })
        .catch(error => error);

      if (this.detection) {
        this.$eventBus.$emit("del-label-detection-" + this.image.id, label.id);
        this.$eventBus.$emit(
          "del-label-detection-present-" + this.image.id,
          label.id
        );
      } else {
        this.$eventBus.$emit("del-label-image-" + this.image.id, label.id);
        this.$eventBus.$emit(
          "del-label-image-present-" + this.image.id,
          label.id
        );
      }
      await this.$nextTick();
      this.$forceUpdate();
    },
    getLabel(label_id) {
      for (let m = 0; m < this.labels.length; m++) {
        if (label_id == this.labels[m]["id"]) {
          return this.labels[m];
        }
      }
    },
    sortArrays(arrays) {
      return _.orderBy(arrays, [item => item.name.toLowerCase()], "asc");
    },
    forceRerender() {
      // ! this is the only way how to refresh this shit
      // ! after we create new object and add some label
      this.$forceCompute("labels_present");
    }
  },
  created() {
    // !we should wait a bit before activating eventBus, because element of beforeDestroyCanBeCalled
    this.$nextTick(() => {
      this.$eventBus.$on("change-present-" + this.image_id, this.forceRerender);
    });
  },
  beforeDestroy() {
    this.$eventBus.$off("change-present-" + this.image_id);
  },
  mounted: function() {},
  watch: {
    data_loaded() {}
  }
};
</script>

<style></style>
