<template>
  <div v-if="image">
    <router-link v-bind:to="/image/ + image.id">
      <img
        class="card-img-top rounded"
        style="height: 150px;"
        v-bind:src="image.thumb_img_path"
        alt="Card image cap"
      />
    </router-link>

    <div
      class="card-img-overlay rounded-top"
      style="height: 35%; background: rgba(0,0,0,0.3);"
      v-on:click="selectImage()"
    >
      <span v-if="image.verificationsCount > 0">
        <i class="fal fa-clipboard-check fa-lg icon-white"></i>
      </span>
      <span v-if="image.verificationsCount == 0">
        <i class="fal fa-clipboard fa-lg icon-white"></i>
      </span>
      <span v-if="checkable" class="float-right mt-0">
        <span v-show="image.selected" class="icon-green">
          <i class="fal fa-check-square fa-lg"></i>
        </span>
        <span v-show="!image.selected" class="icon-green">
          <i class="fal fa-square fa-lg"></i>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //selected: false
    };
  },
  props: {
    image: Object,
    checkable: Boolean
  },
  computed: {},
  methods: {
    selectImage: function() {
      if (this.checkable) {
        if (!this.image.selected) {
          console.log("selectImage");
          this.$emit("selectImage", { id: this.image.id });
        } else {
          console.log("unselectImage");
          this.$emit("unselectImage", { id: this.image.id });
        }
      }
    }
  }
};
</script>

<style scoped>
.icon-green {
  color: #5cb85c;
}
.icon-white {
  color: white;
}
</style>
