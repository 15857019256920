<template>
  <div v-if="data_loaded == 4">
    <span v-for="(value, key) in resources" v-bind:key="key">
      <b-toast
        v-if="!value"
        id="my-toast"
        title="No acccess for service"
        variant="warning"
        visible
        no-auto-hide
        solid
      >
        <div slot="toast-title" class="d-flex flex-grow-1 align-items-baseline">
          <strong class="mr-auto">
            <i class="fal fa-exclamation"></i>
            No acccess for service!
          </strong>
          <small class="text-muted mr-2">...</small>
        </div>
        <span
          >No access for service {{ key }}. Please contact tech@ximilar.com
          ...</span
        >
      </b-toast>
    </span>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["data_loaded", "resources"])
  },
  watch: {
    data_loaded() {}
  }
};
</script>

<style></style>
